/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import "./contactus.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import ScrollToTop from "../../customHooks/scrollToTop";
import Lottie from "react-lottie";
import animationData from "../../assets/image/Abstract Waves Circles.json";
import img from "../../assets/image/home/logo4.png";
import ContactusImaage from "./../../assets/image/Abstract Waves Circles (1).gif";
function Contactus() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [website, setWebsite] = useState("");
  //validation
  function checkValidation() {
    let error = false;
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (
      email.trim() === "" ||
      email.trim() === null ||
      email.trim() === undefined ||
      !emailRegex.test(email.trim())
    ) {
      error = true;
      toast.error("Invalid Email", { toastId: "invalidEmail" });
    }
    if (
      name.trim() === "" ||
      name.trim() === null ||
      name.trim() === undefined
    ) {
      error = true;
      toast.error("Invalid Name", { toastId: "invalidName" });
    }
    if (
      phone.trim() === "" ||
      phone.trim() === null ||
      phone.trim() === undefined
    ) {
      error = true;
      toast.error("Invalid Phone", { toastId: "invalidPhone" });
    }
    if (
      website.trim() === "" ||
      website.trim() === null ||
      website.trim() === undefined
    ) {
      error = true;
      toast.error("Please fill all fields", { toastId: "invalidWebsite" });
    }
    return error;
  }
  //Save to hubspot
  const saveToHubspot = async () => {
    try {
      if (checkValidation()) {
        //Error message
      } else {
        axios
          .post(`${process.env.REACT_APP_SERVER_URL}/email/addToHubspot`, {
            email,
            name,
            phone,
            website,
          })
          .then((response) => {
            console.log(response);
            toast.success("Email Sent Successfully!", {
              toastId: "contactSuccess",
            });
          })
          .catch((error) => {
            console.log(error);
            if (error?.response?.data?.message) {
              toast.error(error.response.data.message, {
                toastId: "contactError",
              });
            } else {
              toast.error("Network Error!", { toastId: "contactError" });
            }
          });
      }
    } catch (error) {
      console.log(error);
    }
  };
  ScrollToTop();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <section className="contactus-sec">
      <div className="main" style={{ overflow: "hidden" }}>
        <div className="cardUpdatedresponsiveness">
          <div className="">
            <div className="">
              <div
                className=""
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  maxWidth: "40rem",
                }}
              >
                <iframe
                  className="iframe-large"
                  src="https://form.jotform.com/240784446983470"
                  scrolling="no"
                ></iframe>
                <div className="px-4">
                  <div
                    style={{
                      color: "#000000",
                      fontWeight: "500",
                      fontSize: "2em",
                    }}
                  >
                    
                  </div>
                </div>
              </div>

              <div className="info d-none">
                <div className="ist">
                  <img
                    src="/assests/images/contactus/icons/mobile.svg"
                    alt="..."
                  />
                  <div className="detail">
                    <h6>PHONE</h6>
                    <span>03 5432 1234</span>
                  </div>
                </div>
                <div className="ist">
                  <img
                    src="/assests/images/contactus/icons/fax.svg"
                    alt="..."
                  />
                  <div className="detail">
                    <h6>FAX</h6>
                    <span>03 5432 1234</span>
                  </div>
                </div>
                <div className="ist">
                  <img
                    src="/assests/images/contactus/icons/email.svg"
                    alt="..."
                  />
                  <div className="detail">
                    <h6>EMAIL</h6>
                    <span>03 5432 1234</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="image-side">
          <img src={img} alt="logo"></img>
 
        </div>
      </div>
    </section>
  );
}

export default Contactus;
