import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./resetpassword.scss";
import axios from "axios";

import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import getItemFromLocalStorage from "../../utils/getItemFromLocalStorage";
function Resetpassword() {
  const user = getItemFromLocalStorage("user");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const location = useLocation();
  // console.log(location.pathname.split("/"))
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle the state to show/hide password
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword); // Toggle the state to show/hide password
  };
  function checkValidation() {
    let error = false;
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{8,20}$/;
    if (
      newPassword === "" ||
      newPassword === null ||
      newPassword === undefined ||
      !passwordRegex.test(newPassword)
    ) {
      toast.error(
        "Invalid Password! Password should be 8-20 length characters and must contain 1 Uppercase letter, 1 number",
        { toastId: "invalidPassword" }
      );
      error = true;
    }
    if (newPassword !== confirmPassword) {
      error = true;
      toast.error("Passwords do not match", {
        toastId: "invalidConfirmPassword",
      });
    }
    return error;
  }
  const updatePasswordHandler = () => {
    if (isLoading) {
      toast.warning("Please Wait!", { toastId: "pleaseWait" });
    } else {
      if (checkValidation() == false) {
        const resetToken = location.pathname.split("/");
        console.log(resetToken[2]);
        console.log(user);
        setIsLoading(true);
        // const headers = {
        //   Authorization: `${user.token}`,
        //   "Content-Type": "application/json",
        // };
        axios
          .post(`${process.env.REACT_APP_SERVER_URL}/users/resetPassword/`, {
            resetToken: resetToken[2],
            newPassword: newPassword,
          })
          .then((res) => {
            console.log(res);
            setIsLoading(false);
            toast.success("Password Updated Successfully!", {
              toastId: "passwordSuccess",
            });
            navigate("/login");
          })
          .catch((err) => {
            setIsLoading(false);
            toast.error(err.response.data.message, {
              toastId: "passwordError",
            });
            console.log(err);
          });
      }
    }
  };
  return (
    <section className="change-password">
      <div className="content">
        <h4> Reset Password </h4>

        <div className="inner">
          {/* <div>
            <label> Old Password</label>
            <input type="password" value={oldPassword} onChange={e => {
              setOldPassword(e.target.value)
            }} />
          </div> */}
          <div className="outer-wrapper">
            <label> New Password</label>

            <div className="myinputwrapper">
              <input
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                type={showPassword ? "text" : "password"}
                //  type="password"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
              />
              <span className="eyeicon" onClick={togglePasswordVisibility}>
                <Icon
                  icon={showPassword ? "mdi:eye-outline" : "mdi:hide-outline"}
                />
              </span>
            </div>
          </div>
          <div className="outer-wrapper">
            <label> Confirm Password</label>
            <div className="myinputwrappers">
              <input
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                type={showConfirmPassword ? "text" : "password"}
                //  type="password"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
              />
              <span
                className="eyeicon"
                onClick={toggleConfirmPasswordVisibility}
              >
                <Icon
                  icon={
                    showConfirmPassword ? "mdi:eye-outline" : "mdi:hide-outline"
                  }
                />
              </span>
            </div>
          </div>
          <div className="buttons">
            <button
              className="cancel"
              onClick={(e) => {
                navigate("/login");
              }}
            >
              Cancel
            </button>
            <button
              className="change"
              onClick={(e) => {
                updatePasswordHandler();
              }}
            >
              Change
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Resetpassword;
