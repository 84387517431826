import React from "react";

const UsaTenders = () => {
  return (
    <div>
      <div className="usa-tenders">
        <div className="usa-banner">
          <div className="content">
            <div className="usa-banner-title">
              <h1>
                Find more US <span className="text-cyan">tenders</span> with
                Simpli RFP
              </h1>
            </div>
            <div className="usa-banner-subtitle">
              <p>Unlock Limitless US Tender Opportunities through Simpli RFP</p>
            </div>
            <div className="usa-banner-btn">
              <button className="btn-gradient text-white d-flex justify-content-center align-items-center">
                See US Tenders
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsaTenders;
