import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Home from "./page/home";
import Navbar from "./components/shared/navbar";
import Footer from "./components/shared/footer";
import Register from "./components/accounts/register";
import Login from "./components/accounts/login";
import Forgot from "./components/accounts/forgot";
import Registration from "./page/registration";
import Features from "./page/features";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import UsaTenders from "./page/usaTenders";
import AdminDashboard from "./components/adminDashboard/adminDashboard";
import axios from "axios";
import Changepassword from "./components/changepassword/changepassword";
import Resetpassword from "./components/resetpassword/resetpassword";
import BlogDetail1 from "./page/blogDetail1";
import BlogDetail2 from "./page/BlogDetail2";
import BlogDetail3 from "./page/BlogDetail3";
import Survey from "./components/survey/survey";
import Faq from "./components/faq/faq";
import Contactus from "./components/contactus/contactus";
import Blogs from "./page/blog";
import About from "./page/about";
import LearningCenter from "./components/learn/leaningCenter";
import Pusher from "pusher-js";
import PusherContext from "./pusherContext";
import { STRIPE_SUBSCRIPTION_UPDATED_ID } from "./constants/constant";
import getItemFromLocalStorage from "./utils/getItemFromLocalStorage";
import BlogDetail4 from "./page/BlogDetail4";
import BlogDetail5 from "./page/BlogDetail5";
import BlogDetail6 from "./page/BlogDetail6";
import BlogDetail7 from "./page/BlogDetail7";
import BlogDetail8 from "./page/BlogDetail8";
import BlogDetail9 from "./page/BlogDetail9";
import BlogDetail10 from "./page/BlogDetail.10";
import BlogDetail11 from "./page/BlogDetail11";
import BlogDetail12 from "./page/BlogDetail12";
import BlogDetail13 from "./page/BlogDetail13";
import BlogDetail14 from "./page/BlogDetail14";
import BlogDetail15 from "./page/BlogDetail15";
import SetItemInLocalStorage from "./utils/SetItemInLocalStorage";
import BlogDetail16 from "./page/blogDetail16";
import BlogDetail17 from "./page/blogDetail17";
import SEO from "./components/SEO/Seo";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET_KEY);
const ADMIN_EMAIL_LIST = JSON.parse(process.env.REACT_APP_ADMIN_EMAIL);

function App() {
  const [user, setUser] = useState(getItemFromLocalStorage("user"));
  const [pusherChannel, setPusherChannel] = useState(null);
  const [pusherObj, setPusherObj] = useState(null);

  const [prvPusherChannel, setPrvPusherChannel] = useState(null);

  const [notiData, setNotiData] = useState(null);

  const handleSetUser = () => {
    setUser(getItemFromLocalStorage("user"));
  };

  useEffect(() => {
    setUser(getItemFromLocalStorage("user"));
    return () => {
      unSubscribeChannel();
    };
  }, []);

  useEffect(() => {
    if (user !== null) {
      pusherUpdate();
    }
  }, [user]);

  const unSubscribeChannel = () => {
    if (pusherObj) {
      pusherObj.unsubscribe("private-message");
      pusherObj.unsubscribe(`private-user-${user?.userID}`);
    }
    setPusherChannel(null);
    setPusherObj(null);
    setPrvPusherChannel(null);
  };

  const updateUserSubscription = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/users/subscription/${user.userID}`
      );
      if (response && response?.data) {
        setUser((prev) => ({
          ...prev,
          subscription: response.data,
        }));
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const pusherUpdate = () => {
    if (!user || !user?.userID || pusherObj !== null) {
      return;
    }
    const pusher = new Pusher("f59c46b0ee2718a77b45", {
      cluster: "ap2",
      authEndpoint: `${process.env.REACT_APP_SERVER_URL}/authenticate-channel`,
      auth: {
        headers: {
          Authorization: `${user.token}` || "A",
        },
      },
    });
    setPusherObj(pusher);
    const prvChannelName = `private-user-${user.userID}`;
    const channel = pusher.subscribe(prvChannelName);
    setPrvPusherChannel(channel);
    channel.bind("message", async (obj) => {
      console.log("obj on private Channel", obj);
      if (obj && obj.type === STRIPE_SUBSCRIPTION_UPDATED_ID) {
        await updateUserSubscription();
      }
    });

    if (user && ADMIN_EMAIL_LIST.includes(user.email.toLowerCase())) {
      const channelName = "public-message";
      const channelMessage = pusher.subscribe(channelName);
      setPusherChannel(channelMessage);
      channelMessage.bind("message", (obj) => {
        console.log("obj on public Channel", obj);
        // console.log('channelMessage.bind("message", (obj)', obj);
        setNotiData(obj?.notiData || null);
      });
    }
  };

  return (
    <div className="App">
      <PusherContext.Provider
        value={{
          notiData,
          setNotiData,
          user,
          unSubscribeChannel,
          handleSetUser,
        }}
      >
        <Elements stripe={stripePromise}>
          <ToastContainer />
          <Navbar userProp={user} />
          <Routes>
            <Route path="/" element={<Features />} />
            <Route path="/home" element={<Home />} />
            <Route path="/blog" element={<Blogs />} />
            <Route
              path="/Tips-and-Strategies-for-Government-RFPs"
              element={<BlogDetail1 />}
            />
            <Route
              path="/Mistakes-Small-Businesses-Make-When-Applying-for-Government-RFPs"
              element={<BlogDetail2 />}
            />

            <Route
              path="/Understanding-the-Language-of-Government-RFPs"
              element={<BlogDetail3 />}
            />
            <Route
              path="/How-AI-is-Revolutionizing-the-Process-of-Government-Contract-Bidding"
              element={<BlogDetail4 />}
            />
            <Route
              path="/The-Lifecycle-of-a-Government-RFP"
              element={<BlogDetail5 />}
            />
            <Route
              path="/Role-of-Small-Businesses-in-Government-Contracting"
              element={<BlogDetail6 />}
            />
            <Route
              path="/Navigating-the-Complexities-of-Government-Contracts"
              element={<BlogDetail7 />}
            />
            <Route
              path="/Government-Contracting-for-Beginners"
              element={<BlogDetail8 />}
            />
            <Route
              path="/7-Innovative-Ways-to-Streamline-Your-RFP-Response-Process"
              element={<BlogDetail9 />}
            />
            <Route
              path="/Navigating-Success-in-Government-Contract-Regulation"
              element={<BlogDetail10 />}
            />
            <Route
              path="/Impact-of-Government-Contracts-on-Small-Business-Growth"
              element={<BlogDetail11 />}
            />
            <Route
              path="/How-to-Stand-Out-in-Government-Contract-Bidding"
              element={<BlogDetail12 />}
            />
            <Route
              path="/Trends-to-Watch-in-2024-for-Government-Contracting"
              element={<BlogDetail13 />}
            />
            <Route
              path="/Decoding-Government-RFPs-Evaluation-Criteria"
              element={<BlogDetail14 />}
            />
            <Route
              path="/Building-Strong-Relationships-with-Government-Agencies-for-Contracting-Success"
              element={<BlogDetail15 />}
            />

            <Route
              path="/Advantages-of-Simplifying-Government-RFPs"
              element={<BlogDetail16 />}
            />

            <Route
              path="/Streamlining-Government-Contract-Management-with-Technology"
              element={<BlogDetail17 />}
            />
            <Route path="/faq" element={<Faq />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/learning-center" element={<LearningCenter />} />
            <Route path="/usa-tenders" element={<UsaTenders />} />
            <Route
              path="/pricing"
              element={<Registration userPropHandler={handleSetUser} />}
            />
            <Route path="/contact-us" element={<Contactus />} />

            {user === null && (
              <>
                <Route
                  path="/login"
                  element={<Login userPropHandler={handleSetUser} />}
                />
                <Route
                  path="/register"
                  element={<Register userPropHandler={handleSetUser} />}
                />

                <Route path="/resetPassword/:id" element={<Resetpassword />} />
                <Route path="/forgot" element={<Forgot />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </>
            )}
            <Route
              path="/dashboard"
              element={
                <AdminDashboard user={user} userPropHandler={handleSetUser} />
              }
            />
            {/* <Route
              path="/survey"
              element={<Survey userPropHandler={handleSetUser} />}
            /> */}
            {user && (
              <>
                <Route path="/changePassword" element={<Changepassword />} />

                <Route
                  path="*"
                  element={<Navigate to="/dashboard" replace />}
                />
              </>
            )}
            <Route path="*" element={<Navigate to="/dashboard" replace />} />
          </Routes>
          <Footer />
          
        </Elements>
      </PusherContext.Provider>
      <SEO />
    </div>
  );
}

export default App;
