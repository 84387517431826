import React from 'react';
import axios from 'axios';

function Upload({ userPropHandler }) {
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('File uploaded successfully', response.data);
      // Handle success (e.g., show a success message)
    } catch (error) {
      console.error('Error uploading file', error);
      // Handle error (e.g., show an error message)
    }
  };

  return (
    <div className="upload-component">
      <h2>Upload File</h2>
      <input type="file" onChange={handleFileUpload} />
    </div>
  );
}

export default Upload;