import React, { useEffect } from "react";
import canada from "../../assets/icons/canada.svg";
import usa from "../../assets/icons/usa.svg";
import calender from "../../assets/icons/calender.svg";
import { useState } from "react";
import moment from "moment/moment";
import * as chrono from "chrono-node";

const ContractBox = (props) => {
  function extractAndParseDates(text) {
    const results = chrono.parse(text);
    const extractedDates = results
      .filter((result) => result.start)
      .map((result) => result.start.date());
    if (extractedDates != [] && extractedDates.length > 0) {
      return moment(extractedDates[0]).format("DD/MM/YYYY");
    } else {
      return "N/A";
    }
    // return extractedDates;
  }
  function dateFormat(dateTemp) {
    const dateCustom = dateTemp.split(/,|@/);
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const dateString = `${dateCustom[0]}, ${dateCustom[1]}`;
    const cleanedText = dateString.replace(/\s+/g, " ");
    const cleanedDate = cleanedText.replace(
      /\b(\d{1,2})(st|nd|rd|th)\b/g,
      "$1"
    );
    return new Date(cleanedDate).toLocaleDateString(undefined, options);
  }
  return (
    <div>
      <div className={`summary-contract-card ${props.active ? "active" : ""}`}>
        <label className="body-medium">
          {props.title.toUpperCase()}
          {/* Add Mechanical Cooling & Dehumidification at 80 Garland Ave,
          Dartmouth, NS */}
        </label>
        <p>{props.RPF}</p>
        <p>NAICS Code: {props.naicsCode}</p>

        <div className="card-footer">
          <p className="issue-date">
            {/* <span>
              {props.state}
              <img
                src={props.country == "Canada" ? canada : usa}
                alt=""
                className="ms-2"
              />
            </span> */}
            <span className="v-center gap-2">
              <span className="issue">Issue Date</span>
              <span className="due">
                Due Date
                {/* {dateFormat(props.closingDate)} */}
              </span>
            </span>
          </p>
          <p className="v-center h-between">
            <span>
              {props.state}
              <img
                src={props.country == "Canada" ? canada : usa}
                alt=""
                className="ms-2"
              />
            </span>
            <span className="v-center gap-2">
              <span>
                <img src={calender} alt="" className="me-2" />
                {props.issueDate && props.issueDate != "NA"
                  ? extractAndParseDates(props.issueDate)
                  : "NA"}
              </span>
              <span>
                <img src={calender} alt="" className="me-2" />
                {props.closingDate && props.closingDate != "NA"
                  ? extractAndParseDates(props.closingDate)
                  : "NA"}
                {/* {dateFormat(props.closingDate)} */}
              </span>
            </span>
          </p>
        </div>
      </div>

      {/* <div className="summary-contract-card">
        <label className="body-medium">
          Add Mechanical Cooling & Dehumidification at 80 Garland Ave,
          Dartmouth, NS
        </label>
        <p>BGIS- PWGSC RP1</p>
        <div className="card-footer">
          <p className="v-center h-between">
            <span>
              Canada <img src={canada} alt="" className="ms-2" />
            </span>
            <span className="v-center gap-2">
              <span>
                <img src={calender} alt="" className="me-2" />
                10/03/2023
              </span>
              <span>
                <img src={calender} alt="" className="me-2" />
                10/03/2023
              </span>
            </span>
          </p>
        </div>
      </div> */}
    </div>
  );
};

export default ContractBox;
