import React from "react";
import ScrollToTop from "../customHooks/scrollToTop";

import carpanterCradImg from "../assets/image/home/carpanterCardImg.png";
import aicard from "../assets/image/home/aicard.png";
import infographic from "../assets/image/home/infographic.png";
import writer1 from "../assets/image/home/writer1.svg";
import writer2 from "../assets/image/home/writer2.svg";
import NewsCard from "../components/shared/newsCard.js";
import thubmnai1 from "../assets/image/home/news1.png";
import thubmnai2 from "../assets/image/home/news4.jpg";
import thubmnai3 from "../assets/image/home/news3.jpg";

import Latestnews from "../components/home/latestnews";

const Blogs = () => {
  ScrollToTop();

  return (
    <div>
      <div className="features-sec">
        <div className="blog-hero">
          <div className="herobg">

            </div>
            <div className="features-banner">
              <div className="hero-text">
                <h2 className="hero-text">Resources</h2>
              </div>              
            </div>  
        </div>
        {/* aa  */}
        <div className="blog-second-sec d-none">
          <div className="right-side">
            <div className="carpanter-card">
              <img
                src={carpanterCradImg}
                alt="carpanter"
                className="carpanterImg img-fluid"
              />
            </div>
            <div className="carpanterBtn">
              <div className="carp-btn">
                <p>Construction</p>
              </div>
              <div className="carp-btn">
                <p>Buildings</p>
              </div>
              <div className="carp-btn">
                <p>Men Power</p>
              </div>
            </div>

            <div className="carp-blog-content">
              <h3 className="blog-head-text">
                The trick to getting more done is to have the freedom to roam
                around
              </h3>
              <p className="blog-text">
                Lorem ipsum dolor sit amet consectetur. Ultrices pharetra fauci
                bus faucibus ultricies neque velit elementum. Hac eu at ornare
                diam tempor phasellus amet. In posuere felis id aliquet ut eu di
                gnissim tincidunt. Sed maecenas est et nunc viverra in pellente
                sque. Semper nulla tempor nulla in consequat varius elementum
                sed hac. Adipiscing diam pretium dui cras malesuada. Neque eg
                estas lorem tincidunt in.In posuere felis id aliquet ut eu di
                gnissim tincidunt.
              </p>
            </div>

            <div className="writers">
              <div className="writer-img">
                <img src={writer1} alt="writer1" className="writer1" />
                <img src={writer2} alt="writer2" className="writer2" />
              </div>
              <div className="writer-detail">
                <p>
                  by <span>Breana Flatley</span> & <span>Damian Erdman</span>{" "}
                  <br />
                  October 7, 2022 ∙ 4 min read
                </p>
              </div>
            </div>
          </div>

          <div className="left-side">
            <div className="blog-newsletter">
              <p className="news-text text-white">Newsletter</p>
              <h4 className="blog-news-head text-white">
                Get all the latest posts deliverd straight to your inbox.
              </h4>
              <div className="news-form">
                <input
                  className="email-input"
                  type="text"
                  name="email"
                  placeholder="Your email address"
                />
                <button className="news-sub-btn">Subscribe</button>
              </div>
            </div>

            <div className="ai-card">
              <img src={aicard} alt="ai card" className="img-fluid" />
              <div className="ai-card-btn">
                <div className="ai-btn ai-sm" style={{ width: "84px" }}>
                  <p>Idea</p>
                </div>
                <div className="ai-btn">
                  <p>Artificial Intelligence</p>
                </div>
              </div>
              <div className="ai-head">
                <h4>Ai can solve new problems very easily</h4>
                <p>by Daryl Whener</p>
              </div>
            </div>

            <div className="info-card">
              <img src={infographic} alt="info card" className="img-fluid" />
              <div className="info-card-btn">
                <div className="info-btn">
                  <p>Business</p>
                </div>
                <div className="info-btn">
                  <p>Tender Values</p>
                </div>
              </div>
              <div className="info-head">
                <h4>Now day biding on tender is more easy. is it ?</h4>
                <p>by Damian Erdman</p>
              </div>
            </div>
          </div>
        </div>

        {/* Feature post */}
        <div className="featured-sec d-none">
          <h3 className="mb-4 text-center">Featured Posts</h3>
          <div className="featured-cards-sec">
            <h3 className="text-center text-white pt-5 pb-5">
              Get started with our <br /> best stories
            </h3>

            <div className="featured-cards d-flex justify-content-center gap-4">
              <NewsCard
                id="1"
                category="Technology"
                heading="The Impact of Technology on the Workplace: How Technology is Changing"
                path="/blog-detail-1"
                img={thubmnai1}
              />
              <NewsCard
                id="1"
                category="Technology"
                heading="The Impact of Technology on the Workplace: How Technology is Changing"
                path="/blog-detail-1"
                img={thubmnai2}
              />
              <NewsCard
                id="1"
                category="Technology"
                heading="The Impact of Technology on the Workplace: How Technology is Changing"
                path="/blog-detail-1"
                img={thubmnai3}
              />
            </div>
          </div>
        </div>

        <div className="latest-blogs-sec d-none">
          {/* <h3 className="mb-5 text-center latest-heading">Latest Blogs</h3> */}
          <h3 className="mb-5 text-center latest-heading">Blogs coming soon</h3>

          {/* <div className="latest-blogs-cards"> */}
          {/* <NewsCard
              id="1"
              category="Technology"
              heading="The Impact of Technology on the Workplace: How Technology is Changing"
              path="/blog-detail-1"
              img={thubmnai1}
            />
            <NewsCard
              id="1"
              category="Technology"
              heading="The Impact of Technology on the Workplace: How Technology is Changing"
              path="/blog-detail-2"
              img={thubmnai2}
            />
            <NewsCard
              id="1"
              category="Technology"
              heading="The Impact of Technology on the Workplace: How Technology is Changing"
              path="/blog-detail-3"
              img={thubmnai3}
            /> */}
          {/* <NewsCard
              id="1"
              category="Technology"
              heading="The Impact of Technology on the Workplace: How Technology is Changing"
              path="/blog-detail-1"
              img={thubmnai1}
            />
            <NewsCard
              id="1"
              category="Technology"
              heading="The Impact of Technology on the Workplace: How Technology is Changing"
              path="/blog-detail-1"
              img={thubmnai2}
            />
            <NewsCard
              id="1"
              category="Technology"
              heading="The Impact of Technology on the Workplace: How Technology is Changing"
              path="/blog-detail-1"
              img={thubmnai3}
            /> */}
          {/* </div> */}
          {/* <div className="latest-blogs-btn">
            <button className="load-more-btn btn-gradient ">Load More</button>
          </div> */}
        </div>
        {/* <h3 className="py-4 text-center latest-heading">
          Latest Blogs
        </h3> */}

        <Latestnews hide="d-none" />
        {/* <div className="might-like-sec d-none">
          <h2 className="might-heading">You Might Also Like</h2>
          <div className="might-cards">
            <div className="might-card">
              <div className="might-card-img">
                <img src={mightImg1} alt="mightImg1" />
              </div>
              <div className="might-btns">
                <div className="idea-text">
                  <p>Idea</p>
                </div>
                <div className="ai-text">
                  <p>Artificial Intelligence</p>
                </div>
              </div>
              <div className="might-content">
                <h3>Carry fine goods with sam oranaldo service</h3>
                <p>by Daryl Whener</p>
              </div>
            </div>

            <div className="might-card">
              <div className="might-card-img">
                <img src={mightImg2} alt="mightImg1" />
              </div>
              <div className="might-btns">
                <div className="idea-text">
                  <p>Idea</p>
                </div>
                <div className="ai-text">
                  <p>Artificial Intelligence</p>
                </div>
              </div>
              <div className="might-content">
                <h3>Constructions is the most fine business in world</h3>
                <p>by Daryl Whener</p>
              </div>
            </div>

            <div className="might-card">
              <div className="might-card-img">
                <img src={mightImg3} alt="mightImg1" />
              </div>
              <div className="might-btns">
                <div className="idea-text">
                  <p>Idea</p>
                </div>
                <div className="ai-text">
                  <p>Artificial Intelligence</p>
                </div>
              </div>
              <div className="might-content">
                <h3>How fertilizers company provide tenders</h3>
                <p>by Daryl Whener</p>
              </div>
            </div>

            <div className="might-card">
              <div className="might-card-img">
                <img src={mightImg4} alt="mightImg1" />
              </div>
              <div className="might-btns">
                <div className="idea-text">
                  <p>Idea</p>
                </div>
                <div className="ai-text">
                  <p>Artificial Intelligence</p>
                </div>
              </div>
              <div className="might-content">
                <h3>Heavy machinery biding process is difficult why?</h3>
                <p>by Daryl Whener</p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Blogs;
