import React, { useState } from "react";
import dropdown from "../../assets/icons/chevron-drop.svg";

const Dropdown = ({ options, onSelect }) => {
  const [selectedCategories, setSelectedCategories] = useState(options[0]); // Initialize with the first option
  const [searchInput, setSearchInput] = useState("");

  const handleCategorySelect = (category) => {
    console.log(category);
    // let updatedSelection;
    // if (selectedCategories.includes(category)) {
    //   // Remove category if it's already selected
    //   updatedSelection = selectedCategories.filter((c) => c !== category);
    // } else {
    //   // Add category if it's not selected
    //   updatedSelection = [...selectedCategories, category];
    // }

    // setSelectedCategories(updatedSelection);
    onSelect(category);
  };

  const filteredOptions = options.slice(1).filter((option) => {
    if (typeof option === "string" || typeof option === "number") {
      const optionString = String(option).toLowerCase();
      const searchInputLower = searchInput.toLowerCase();
      return optionString.includes(searchInputLower);
    }
    return false;
  });

  return (
    <div className="  dropdown-wrapper">
      <div className="dropdown simpliee-dropdown">
        <button
          className="btn btn-secondary dropdown-toggle  no-after"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {/* {selectedCategories.length > 0
            ? selectedCategories
              .map((category) =>
                typeof category == "string"
                  ? category.slice(0, 12)
                  : category
              )
              .join(", ")
            : "Select Categories"} */}
          {typeof selectedCategories == "string"
            ? selectedCategories.slice(0, 12)
            : selectedCategories}
          <img src={dropdown} alt="" />
        </button>
        <ul className="dropdown-menu scrollable-menu">
          <li className="drop-search-box">
            <input
              type="search"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Search"
            />
          </li>
          {filteredOptions.map((option, index) => (
            <li key={index}>
              <a
                className={`dropdown-item pointer ${
                  selectedCategories.includes(option) ? "selected" : ""
                }`}
                onClick={() => handleCategorySelect(option)}
              >
                {option}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Dropdown;
