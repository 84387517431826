import React, { useEffect } from "react";
import ScrollToTop from "../customHooks/scrollToTop";
import bannerVec from "../assets/image/home/whybannervec.png";
import about from "../assets/image/about/about.png";
import what from "../assets/image/about/what.jpg";
import mission from "../assets/image/about/mission.jpg";
import approach from "../assets/image/about/approach.jpg";
import axios from "axios";
import { Icon } from "@iconify/react";
import { useState } from "react";
import { toast } from "react-toastify";
import Button from "../components/shared/button";
import Countdown from "../customHooks/countdown";

const About = () => {
  ScrollToTop();
  const targetDate = new Date("2023-11-13T23:59:59");
  const [activeTab, setActiveTab] = useState("Module 1");

  const [email, setEmail] = useState("");
  // ScrollToTop();
  const handleEmailChange = (event) => {
    // Update the email state when the input value changes
    setEmail(event.target.value);
  };

  const handleSubscribeClick = async () => {
    console.log(email);

    try {
      if (email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(email)) {
          toast.error("Please enter a valid email address");
          return; // Don't proceed if the email is not valid
        }

        const url = `${process.env.REACT_APP_SERVER_URL}/email/sendemail`;
        const res = await axios.post(url, {
          email: email,
        });

        setEmail("");

        toast.success("Successfully Subscribed");
        console.log("email sent", res);
      } else {
        toast.warn("Please Enter Email to Subscribe");
      }
    } catch (error) {
      setEmail("");
      console.log(error);
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message, { toastId: "emailError" });
      } else {
        toast.error(error.message, { toastId: "emailError" });
      }
    }
  };

  return (
    <div>
      <div className="whyUs-sec">
        <div className="intro-sec">
          <div className="left">
            <img src={about} alt="intro vector" />
          </div>
          <div className="right">
            <h3>About Us</h3>

            <p>
              At Simpli RFP, we specialize in demystifying government contracts.
              Our innovative software is meticulously engineered to untangle the
              complexities of these contracts, making them accessible and
              manageable. We understand that navigating through the intricate
              details of government contracts can be daunting. That's why our
              solution is designed to strip away the confusion, presenting the
              information in a clear, concise manner.
            </p>
          </div>
        </div>

        <div className="intro-sec  ">
          <div className="right">
            <h3> Our Mission</h3>

            <p>
              To empower businesses with the knowledge they need to approach
              government contracts with confidence. We are dedicated to
              transforming the way businesses perceive and engage with these
              contracts, opening doors to new opportunities and enabling success
              in the competitive world of government bidding.
            </p>
          </div>
          <div className="left">
            <img src={mission} alt="intro vector" />
          </div>
        </div>

        <div className="intro-sec   ">
          <div className="left">
            <img src={approach} alt="intro vector" />
          </div>

          <div className="right">
            <h3>Our Approach</h3>
            <p>
              Through cutting-edge technology and a deep understanding of the
              nuances within government contracts, we simplify the complex,
              making it accessible to all. Our user-friendly solutions redefine
              how businesses interact with and understand crucial agreements.
            </p>

            {/* <button className="btn-prime btn-gradient br-35   text-white text-capitalize">
              Read more
            </button> */}
          </div>
        </div>

        <div className="intro-sec-roadmap ">
          <h3>Roadmap </h3>
          <div className="roadmap-cards-top">
            <div className="card1">
              <div className="main-card1">
                <div className="r-card">
                  <p>AI bid proposal writing</p>
                </div>
                <div className="triangle-div"></div>
              </div>
              <div className="circle1">
                <p>01</p>
              </div>
            </div>
            <div className="card2">
              <div className="main-card2">
                <div className="r-card">
                  <p>Competitor research tools</p>
                </div>
                <div className="triangle-div"></div>
              </div>
              <div className="circle3">
                <p>03</p>
              </div>
            </div>
            <div className="card1">
              <div className="main-card1">
                <div className="r-card">
                  <p>Profile Matching and recommendations</p>
                </div>
                <div className="triangle-div"></div>
              </div>
              <div className="circle1">
                <p>05</p>
              </div>
            </div>
          </div>
          <hr className="partition-line" />
          <div className="roadmap-cards-bottom">
            <div className="card4">
              <div className="circle-">
                <div className="circle2">
                  <p>02</p>
                </div>
              </div>

              <div className="main-card4">
                <div className="r-card">
                  <p>State, local and Federal solicitations</p>
                </div>
                <div className="triangle-div"></div>
              </div>
            </div>
            <div className="card5">
              <div className="circle-">
                <div className="circle4">
                  <p>04</p>
                </div>
              </div>
              <div className="main-card5">
                <div className="r-card">
                  <p>
                    Interactive Data - forecasts, awards, grants, opportunities
                  </p>
                </div>
                <div className="triangle-div"></div>
              </div>
            </div>
            <div className="card4">
              <div className="circle-">
                <div className="circle2">
                  <p>06</p>
                </div>
              </div>

              <div className="main-card4">
                <div className="r-card">
                  <p>Predictive Analytics</p>
                </div>
                <div className="triangle-div"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="newsletter-section  ">
          <div className="left news-left">
            <div className="content">
              {/* <div className="whatspecial">What Speciality</div> */}
              <h3>
                Stay Informed <span className="text-cyan ">& Secure Your</span>{" "}
                Spot for Updates!
              </h3>
              <p className="  text-center">
                Stay in the loop as exciting updates are on the horizon.
              </p>

              <div className="newsletter-module">
                <iframe
                  src="https://api.leadconnectorhq.com/widget/form/exze9bLEriqP564VO1hA"
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                    borderRadius: "35px",
                    boxShadow: "none",
                  }}
                  id="inline-exze9bLEriqP564VO1hA"
                  data-layout="{'id':'INLINE'}"
                  data-trigger-type="alwaysShow"
                  data-trigger-value=""
                  data-activation-type="alwaysActivated"
                  data-activation-value=""
                  data-deactivation-type="neverDeactivate"
                  data-deactivation-value=""
                  data-form-name="Join Now"
                  data-height="422"
                  data-layout-iframe-id="inline-exze9bLEriqP564VO1hA"
                  data-form-id="exze9bLEriqP564VO1hA"
                  title="Join Now"
                >
                  {" "}
                </iframe>
              </div>
            </div>
          </div>
          {/* <div className="right">
            <img src="../../assests/images/rightsidenews.png" alt="" />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default About;
