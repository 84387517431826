import React, { useEffect, useRef } from "react";

function GradientCanvas() {
  const canvasRef = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const video = videoRef.current;

    video.addEventListener(
      "play",
      function () {
        const draw = function () {
          if (video.paused || video.ended) return false;
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
          requestAnimationFrame(draw);
        };
        draw();
      },
      false
    );
  }, []);

  const style = {
    position: "relative",
    left: 0,
    bottom: 140,
    width: "100%",
    height: "250px",
    transformOrigin: "0 100%",
    transform: "skewY(-6deg)",
    willChange: "transform",
    zIndex: 0,
    overflow: "hidden",
  };

  const styles = {
    position: "absolute",
    bottom: 0,
    width: "100%",
  };
  return (
    <>
      <div style={style}>
        <canvas
          className="Gradient__canvas isLoaded"
          data-js-controller="Gradient"
          data-transition-in=""
          width="1536"
          height="600"
          ref={canvasRef}
          style={styles}
        />
        <video
          ref={videoRef}
          className="features-video"
          muted
          autoPlay
          playsInline
          loop
        >
          <source src="../../assests/videos/background6.mp4" type="video/mp4" />
        </video>
      </div>
    </>
  );
}

export default GradientCanvas;
