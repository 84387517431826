import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { SpinnerCircular } from "spinners-react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import _ from "lodash";
import { Icon } from "@iconify/react";
import Profile from "../profile/profile";
import Summary from "../summarize/summary";
import Appointments from "../appointments/appointments";
import Favourites from "../favourites/favourites";
import Users from "../users/users";
import { STRIPE_SUBSCRIPTION_STATUS } from "../../constants/constant";
import SetItemInLocalStorage from "../../utils/SetItemInLocalStorage";
import "./adminDasboard.scss";
import useCheckFreemium from "../../customHooks/useCheckFreemium";
import PusherContext from "../../pusherContext";
import getItemFromLocalStorage from "../../utils/getItemFromLocalStorage";
import Offcanvas from "react-bootstrap/Offcanvas";
import Filter from "../../components/summarize/filter";
import Upload from "../upload/upload";

const ADMIN_EMAIL_LIST = JSON.parse(process.env.REACT_APP_ADMIN_EMAIL);

function AdminDashboard({ userPropHandler, user, contractId, summaryData }) {
  const navigate = useNavigate();

  const { subscription, subscriptionRemainingDays } = useCheckFreemium();

  const { unSubscribeChannel } = useContext(PusherContext);

  const [activeFilter, setActiveFilter] = useState("Subscription");
  const [isLeftDivVisible, setIsLeftDivVisible] = useState(true);
  const [showSidebar, setShowSidebar] = useState(false);
  const [imageData, setImageData] = useState(null);
  const sidebarRef = useRef(null);
  const [isFilterButtonClose, setIsFilterButtonClose] = useState(false);
  const [totalPayments, setTotalPayments] = useState([]);
  const [subscriptionApiCalled, setSubscriptionApiCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [searchBoxValue, setSearchBoxValue] = useState("");
  const [naicsCode, setNaicsCode] = useState([]);
  const [naicstitle, setNaicstitle] = useState([]);
  const [department, setDepartment] = useState([]);
  const [agencyCode, setAgencyCode] = useState([]);
  const [agencyName, setAgencyName] = useState([]);
  const [orderBy, setOrderBy] = useState("");
  const [status, setStatus] = useState("");
  const [location, setLocation] = useState("");
  const [setAside, setSetAside] = useState([]);
  const [allSelectedFilters, setAllSelectedFilters] = useState([]);

  const handleFilterBtnClick = () => {
    setIsLeftDivVisible(!isLeftDivVisible);
    setIsFilterButtonClose(!isFilterButtonClose);
  };

  useEffect(() => {
    if (user) {
      const headers = {
        Authorization: `${user.token}`,
        "Content-Type": "application/json",
      };
      axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/users/protected`,
          { userId: user.userID },
          { headers: headers }
        )
        .then((res) => {
          if (res.data && res.data?.data?.subscription) {
            const userInLc = getItemFromLocalStorage("user");
            if (userInLc) {
              Object.assign(userInLc, {
                subscription: res.data?.data?.subscription,
              });
            }
            SetItemInLocalStorage("user", userInLc);
          }
          userPropHandler();
        })
        .catch((err) => {
          console.log(err);
          toast.error("Session Expired!", { toastId: "sessionExpired" });
          localStorage.clear();
          userPropHandler();
          unSubscribeChannel();
          navigate("/login");
        });
    } else {
      toast.warning("Please Login First!", { toastId: "loginFirst" });
      unSubscribeChannel();
      localStorage.clear();
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setIsLeftDivVisible(false);
      } else {
        setIsLeftDivVisible(true);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const logoutUser = () => {
    unSubscribeChannel();
    if (user) {
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}/users/logout`, {
          userId: user.userID,
          token: user.token,
        })
        .then((res) => {
          localStorage.removeItem("user");
          localStorage.clear();
          userPropHandler();
          navigate("/login");
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.data.message === "User not found") {
            localStorage.removeItem("user");
            navigate("/login");
          }
          localStorage.clear();
          localStorage.removeItem("user");
          userPropHandler();
        });
    } else {
      toast.warning("Please Login First!", { toastId: "loginFirst" });
      localStorage.clear();
      userPropHandler();
      navigate("/login");
    }
  };

  const getUserData = () => {
    if (user) {
      const url = `${process.env.REACT_APP_SERVER_URL}/users/${user.userID}`;
      axios
        .get(url)
        .then((res) => {
          if (res.data.userinfo.img.includes("data:undefined")) {
            setImageData("");
          } else {
            setImageData(res.data.userinfo.img);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      //Checking payments
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/pay/payments/${user.userID}`)
        .then((res) => {
          const userObj = _.cloneDeep(user);
          if (res.data.status) {
            Object.assign(userObj, {
              subscriptionStatus: res.data.status,
              userTrialExpired: res.data.userTrialExpired,
              trialDaysRemaining: res.data.trialDaysRemaining,
            });
          }
          setTotalPayments(res.data.payments);

          setSubscriptionApiCalled(true);
          if (user?.isAdmin === true) {
          } else if (res.data.userTrialExpired === false) {
          } else if (
            res.data.userTrialExpired === true &&
            res.data.trialDaysRemaining <= 0 &&
            res.data.status !== STRIPE_SUBSCRIPTION_STATUS.TRIALING &&
            res.data.status !== STRIPE_SUBSCRIPTION_STATUS.ACTIVE
          ) {
          } else if (
            res.data.userTrialExpired === true &&
            res.data.status !== STRIPE_SUBSCRIPTION_STATUS.TRIALING &&
            subscriptionRemainingDays > 0
          ) {
          } else {
            toast.warning(
              () => {
                return (
                  <div className="toast-div">
                    <span>
                      Your subscription has ended! Please subscribe again OR{" "}
                    </span>
                    <a href="#" onClick={logoutUser}>
                      Logout
                    </a>
                  </div>
                );
              },
              {
                toastId: "subscriptionEnded",
                position: "top-right",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            );
            navigate("/pricing");
            return;
          }
          SetItemInLocalStorage("user", userObj);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const handleCustomerPortalRedirection = async () => {
    if (isLoading || !user) return;
    try {
      const headers = {
        Authorization: `${user.token}`,
        "Content-Type": "application/json",
      };
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/pay/payments/customer-portal-session/${user.userID}`,
        { headers: headers }
      );
      if (response?.data && response?.data?.portalSessionUrl) {
        window.open(response?.data?.portalSessionUrl, "_blank");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("err", err);
    }
  };

  const showSubscriptionOrTrailMessage = () => {
    if (subscriptionApiCalled === true) {
      if (user && user.isAdmin === true) {

      } else if (
        user &&
        user?.userTrialExpired === true &&
        user?.subscriptionStatus === STRIPE_SUBSCRIPTION_STATUS.ACTIVE &&
        subscriptionRemainingDays > 0
      ) {
 
      } else if (
        user &&
        user?.userTrialExpired === true &&
        user?.subscriptionStatus !== STRIPE_SUBSCRIPTION_STATUS.ACTIVE &&
        user?.subscriptionStatus !== STRIPE_SUBSCRIPTION_STATUS.PAUSED &&
        subscriptionRemainingDays <= 0
      ) {
        return (
          <span className="fw-bold me-3 text-white">
            {`Your subscription has ${
              user?.subscriptionStatus &&
              STRIPE_SUBSCRIPTION_STATUS.find(
                (x) => x === user?.subscriptionStatus
              )
                ? user?.subscriptionStatus?.replace("_", " ")
                : "ended"
            }`}
          </span>
        );
      } else if (
        user &&
        user?.userTrialExpired === false &&
        user?.trialDaysRemaining <= 7 &&
        user?.trialDaysRemaining >= 0
      ) {
        return (
          <span className="fw-bold me-3 text-white">
            Trial Remaining Days : {user.trialDaysRemaining}
          </span>
        );
      } else if (
        user &&
        user?.userTrialExpired === true &&
        user?.trialDaysRemaining <= 0 &&
        user?.subscriptionStatus === STRIPE_SUBSCRIPTION_STATUS.PAUSED
      ) {
        return (
          <span className="fw-bold me-3 text-white">Trail Period Over!</span>
        );
      }
    }
    return <></>;
  };

  return (
    <section className="admin-dashboard">
      {isLoading && (
        <>
          <div className="loader">
            <SpinnerCircular
              color="#ffffff"
              size={"20%"}
              thickness={150}
              secondaryColor="#32C0BE"
            />
            <span className="customLoadingText">Loading...</span>
          </div>
        </>
      )}
      <div className="filter">
        <Icon
          className={`filter-btn ${isFilterButtonClose ? "close" : ""}`}
          icon={
            isFilterButtonClose ? "eva:close-fill" : "fluent:filter-24-filled"
          }
          width="30"
          height="30"
          onClick={handleFilterBtnClick}
        />
      </div>
      {isLeftDivVisible && (
        <div className="sidebar" ref={sidebarRef}>
          <div className="top">
            <Link className="dashboard-logo" to="/">
              <img src="/assests/images/dashboard/logo.svg" alt="..." />
            </Link>
            <div className="center">
              <a
                className={`${activeFilter === "Subscription" ? "active" : ""}`}
                onClick={() => setActiveFilter("Subscription")}
              >
                Solicitations
              </a>
              {user && ADMIN_EMAIL_LIST.includes(user.email.toLowerCase()) && (
                <a
                  className={`${
                    activeFilter === "Appointments" ? "active" : ""
                  }`}
                  onClick={() => setActiveFilter("Appointments")}
                >
                  Appointments
                </a>
              )}
              <a
                className={`${activeFilter === "Favourites" ? "active" : ""}`}
                onClick={() => setActiveFilter("Favourites")}
              >
                Favorites
              </a>
              {user && ADMIN_EMAIL_LIST.includes(user.email.toLowerCase()) && (
                <a
                  className={`${activeFilter === "Users" ? "active" : ""}`}
                  onClick={() => setActiveFilter("Users")}
                >
                  Users
                </a>
              )}
            </div>
          </div>

          <div className="bottom">
            {user && !ADMIN_EMAIL_LIST.includes(user.email.toLowerCase()) && (
              <button
                onClick={() => {
                  if (!ADMIN_EMAIL_LIST.includes(user.email.toLowerCase())) {
                    handleCustomerPortalRedirection();
                  }
                }}
              >
                <Icon
                  icon="solar:dollar-linear"
                  color="white"
                  width="24"
                  height="24"
                />
                {subscription?.isFreemium === true ? "Upgrade" : "Billing"}
              </button>
            )}

            <button
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              <div onClick={() => setActiveFilter("Profile")}>
                <Icon
                  icon="iconamoon:profile-circle-light"
                  color="white"
                  width="24"
                  height="24"
                />
                <a
                  className={`${activeFilter === "Profile" ? "active  " : ""}`}
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Profile
                </a>
              </div>
            </button>
            <button
              onClick={() => {
                navigate("/contact-us");
              }}
            >
              <Icon
                icon="fluent-mdl2:edit-contact"
                color="white"
                width="24"
                height="24"
              />
              Contact Us
            </button>
            <button
              onClick={() => {
                navigate("/changePassword");
              }}
            >
              <Icon
                icon="carbon:password"
                color="white"
                width="24"
                height="24"
              />
              Change Password
            </button>
            <button onClick={logoutUser}>
              <Icon
                icon="material-symbols:logout"
                color="white"
                width="24"
                height="24"
              />
              Log Out
            </button>
          </div>
        </div>
      )}

      <div className="content-side">
        <div className="top justify-content-between">
            <Link className="dashboard-logo" to="/">
              <img src="/assests/images/dashboard/logo.svg" alt="..." />
            </Link>
          {showSubscriptionOrTrailMessage()}
          <div className="profile-section">
            <Icon
              icon="charm:menu-hamburger"
              color="white"
              width="32"
              height="32"
              onClick={() => setShowSidebar(true)}
            />
            <Offcanvas show={showSidebar} onHide={() => setShowSidebar(false)} placement="end" className="mobile-sidebar">
              <Offcanvas.Header closeButton className="mobile-sidebar-header">
                <Offcanvas.Title>Menu</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="mobile-sidebar">
                <div className="top">
                  <Link className="dashboard-logo" to="/">
                    <img src="/assests/images/dashboard/logo.svg" alt="..." />
                  </Link>
                  <div className="center">
                    <a
                      className={`${activeFilter === "Subscription" ? "active" : ""}`}
                      onClick={() => {
                        setActiveFilter("Subscription");
                        setShowSidebar(false);
                      }}
                    >
                      Solicitations
                    </a>
                    {user && ADMIN_EMAIL_LIST.includes(user.email.toLowerCase()) && (
                      <a
                        className={`${
                          activeFilter === "Appointments" ? "active" : ""
                        }`}
                        onClick={() => {
                          setActiveFilter("Appointments");
                          setShowSidebar(false);
                        }}
                      >
                        Appointments
                      </a>
                    )}
                    <a
                      className={`${activeFilter === "Favourites" ? "active" : ""}`}
                      onClick={() => {
                        setActiveFilter("Favourites");
                        setShowSidebar(false);
                      }}
                    >
                      Favorites
                    </a>
                    {user && ADMIN_EMAIL_LIST.includes(user.email.toLowerCase()) && (
                      <a
                        className={`${activeFilter === "Users" ? "active" : ""}`}
                        onClick={() => {
                          setActiveFilter("Users");
                          setShowSidebar(false);
                        }}
                      >
                        Users
                      </a>
                    )}
                  </div>
                </div>
                <div className="bottom">
                  {user && !ADMIN_EMAIL_LIST.includes(user.email.toLowerCase()) && (
                    <button
                      onClick={() => {
                        if (!ADMIN_EMAIL_LIST.includes(user.email.toLowerCase())) {
                          handleCustomerPortalRedirection();
                          setShowSidebar(false);
                        }
                      }}
                    >
                      <Icon
                        icon="solar:dollar-linear"
                        color="white"
                        width="24"
                        height="24"
                      />
                      {subscription?.isFreemium === true ? "Upgrade" : "Billing"}
                    </button>
                  )}

                  <button
                    onClick={() => {
                      navigate("/dashboard");
                      setShowSidebar(false);
                    }}
                  >
                    <div onClick={() => setActiveFilter("Profile")}>
                      <Icon
                        icon="iconamoon:profile-circle-light"
                        color="white"
                        width="24"
                        height="24"
                      />
                      <a
                        className={`${activeFilter === "Profile" ? "active  " : ""}`}
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        Profile
                      </a>
                    </div>
                  </button>
                  <button
                    onClick={() => {
                      navigate("/contact-us");
                      setShowSidebar(false);
                    }}
                  >
                    <Icon
                      icon="fluent-mdl2:edit-contact"
                      color="white"
                      width="24"
                      height="24"
                    />
                    Contact Us
                  </button>
                  <button
                    onClick={() => {
                      navigate("/changePassword");
                      setShowSidebar(false);
                    }}
                  >
                    <Icon
                      icon="carbon:password"
                      color="white"
                      width="24"
                      height="24"
                    />
                    Change Password
                  </button>
                  <button
                    onClick={() => {
                      logoutUser();
                      setShowSidebar(false);
                    }}
                  >
                    <Icon
                      icon="material-symbols:logout"
                      color="white"
                      width="24"
                      height="24"
                    />
                    Log Out
                  </button>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </div>


            <>
              {activeFilter === "Profile" ? (
                <Profile
                  onGetUserData={getUserData}
                  userPropHandler={userPropHandler}
                />
              ) : activeFilter === "Subscription" ? (
                <Summary from={"dashboard"} userPropHandler={userPropHandler} />
              ) : activeFilter === "Appointments" ? (
                <Appointments userPropHandler={userPropHandler} />
              ) : activeFilter === "Users" ? (
                <Users userPropHandler={userPropHandler} />
              ) : (
                <Favourites userPropHandler={userPropHandler} />
              )}
            </>

      </div>
    </section>
  );
}

export default AdminDashboard;
