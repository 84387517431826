import React, { useContext, useEffect, useRef, useState } from "react";
import Filter from "./filter";
import { Icon } from "@iconify/react";
import print from "../../assets/icons/printer.svg";
import Button from "../shared/button";
import { toast } from "react-toastify";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import * as chrono from "chrono-node";
import moment from "moment/moment";
import getItemFromLocalStorage from "../../utils/getItemFromLocalStorage";
import SetItemInLocalStorage from "../../utils/SetItemInLocalStorage";
import PusherContext from "../../pusherContext";
import useCheckFreemium from "../../customHooks/useCheckFreemium";
import { classNames, maskString } from "../../utils/utils";
import ToolTip from "../common/tooltip/Tooltip";

const SummaryReport = ({
  summaryData,
  hide,
  contractId,
  onSearchBoxChange,
  onOrderByChange,
  onStatusChange,
  onLocationChange,
  onSetAsideChange,
  onNAICSCodeChange,
  onNAICSTitleChange,
  onDepartmentChange,
  onAgencyCodeChange,
  onAgencyNameChange,
  onAllSelectedFilters,
}) => {
  const containerRef = useRef(null);
  const { handleSetUser } = useContext(PusherContext);
  const { subscription } = useCheckFreemium();
  const [closingDateState, setClosingDateState] = useState("");
  const navigate = useNavigate();
  const contractReportRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [allFavourites, setAllFavourites] = useState([]);
  const user = getItemFromLocalStorage("user");
  const dueDate = summaryData?.["offer due date"] || summaryData?.["Closing Date"];

  useEffect(() => {
    containerRef?.current?.scrollIntoView({ behavior: "smooth" });
    if (summaryData && contractId && user) {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/users/favorite-contracts/${user.userID}`
        )
        .then((res) => {
          setAllFavourites(res.data.favoriteContracts);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [summaryData]);

  useEffect(() => {
    if (allFavourites && allFavourites.length > 0) {
      const favourite = allFavourites.findIndex(
        (item) => item.contractId === contractId
      );
      if (favourite > -1) {
        setClicked(true);
      } else {
        setClicked(false);
      }
    }
  }, [allFavourites]);

  const handleClick = () => {
    if (user) {
      if (isLoading) {
        toast.warning("Please wait!", { toastId: "pleaseWait" });
      } else {
        setIsLoading(true);
        axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/users/contractFav/${user.userID}`,
            {
              contractId: contractId,
              isFavorite: !clicked,
              contractTitle: summaryData?.["RFP Title"],
              contractLocation: summaryData.Location,
            }
          )
          .then((res) => {
            setClicked(!clicked);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
          });
      }
    }
  };

  const isSolicitationOpen = (dueDate) => {
    if (!dueDate) return "N/A"; // Return N/A if there is no due date
    const today = moment();
    const due = moment(dueDate, "YYYY-MM-DD"); // Adjust the date format if needed
    return due.isAfter(today) ? "Yes" : "No";
  };

  useEffect(() => {
    const user = getItemFromLocalStorage("user");
    if (user) {
      const headers = {
        Authorization: `${user.token}`,
        "Content-Type": "application/json",
      };
      axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/users/protected`,
          { userId: user.userID },
          { headers: headers }
        )
        .then((res) => {
          if (res.data && res.data?.data?.subscription) {
            const userInLc = getItemFromLocalStorage("user");
            if (userInLc) {
              Object.assign(userInLc, {
                subscription: res.data?.data?.subscription,
              });
            }
            SetItemInLocalStorage("user", userInLc);
            handleSetUser();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Session Expired!", { toastId: "sessionExpired" });
          localStorage.clear();
          navigate("/login");
        });
    } else {
      toast.warning("Please Login First!", { toastId: "loginFirst" });
      navigate("/login");
      localStorage.clear();
    }
  }, [summaryData]);

  const handlePrint = useReactToPrint({
    content: () => contractReportRef.current,
  });

  const toastId = useRef(null);

  const notify = () => (toastId.current = toast("Zipping.."));

  const dismiss = () => toast.dismiss(toastId.current);

  const dismissAll = () => toast.dismiss();

  const DownloadAllLinksToZip = async (links) => {
    const notifyId = toast.info(
      `Zipping ${links.length} Files... Please wait.`,
      {
        draggable: false,
        closeOnClick: false,
        autoClose: false,
        closeButton: false,
        icon: (
          <span
            className="spinner-border spinner-border-sm text-warning"
            role="status"
            aria-hidden="true"
          ></span>
        ),
      }
    );
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/download/download-zip`,
        { files: links },
        { responseType: "arraybuffer" }
      );
      const blob = new Blob([response.data], { type: "application/zip" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "pdf.zip";
      link.click();
      toast.update(notifyId, {
        type: toast.TYPE.SUCCESS,
        render: "Zipped Successfully!",
        autoClose: 3000,
        icon: false,
      });
    } catch (error) {
      console.error("Error:", error);
      toast.update(notifyId, {
        type: toast.TYPE.ERROR,
        render: "Zip failed. Please try again.",
        autoClose: 3000,
        icon: false,
      });
    }
  };

  function extractAndParseDates(text) {
    const results = chrono.parse(text);
    const extractedDates = results
      .filter((result) => result.start)
      .map((result) => result.start.date());
    if (extractedDates.length > 0) {
      const dateMoment = moment(extractedDates[0]);
      if (dateMoment.isValid()) {
        return dateMoment;
      }
    }
    return null;
  }

  function checkCloseOrOpen(closingDate) {
    if (closingDate) {
      const date = extractAndParseDates(closingDate);
      const momentDate = moment(date, "DD/MM/YYYY");
      return momentDate.isAfter();
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (summaryData) {
      setClosingDateState(
        summaryData?.["Location"] === "Canada"
          ? summaryData?.["Closing Date and Time"]
          : summaryData?.["Closing Date"]
          ? summaryData?.["Closing Date"]
          : "NA"
      );
    }
  }, [summaryData]);

  function valueOrNA(value) {
    if (value && value !== "NA" && value !== "N/A") return true;
    return false;
  }

  const getBitTypeForContractDetails = (tender, bitType) => {
    const value =
      tender !== "NA" && tender !== "N/A" && tender
        ? tender
        : bitType
        ? bitType
        : "No Type";

    return subscription?.isFreemium === true ? (
      <ToolTip content="Upgrade for full access.">{maskString(value)}</ToolTip>
    ) : (
      value
    );
  };



  return (
    <div ref={containerRef} id="scroll">
      <div className="summary-reports" data-bs-spy="scroll">
        <div className="header pt-3">
          <div className="v-center justify-content-end gap-2 mb-5 summary-btns">
            <span>
              {subscription?.isFreemium === true ? (
                <ToolTip content="Upgrade to premium for favorite feature.">
                  <Icon
                    icon={clicked ? "mdi:favourite" : "mdi:favourite-border"}
                    color="#122e97"
                    width="30"
                    height="30"
                    className={clicked ? "clicked" : ""}
                    onClick={() => {
                      if (subscription?.isFreemium === true) {
                        toast.info(
                          "Upgrade to premium for favorite feature.",
                          {
                            toastId: "fav-button-subs-check",
                          }
                        );
                        return;
                      } else {
                        handleClick();
                      }
                    }}
                    style={
                      subscription?.isFreemium === true
                        ? { cursor: "default" }
                        : { cursor: "pointer" }
                    }
                  />
                </ToolTip>
              ) : (
                <Icon
                  icon={clicked ? "mdi:favourite" : "mdi:favourite-border"}
                  color="#122e97"
                  width="30"
                  height="30"
                  className={clicked ? "clicked" : ""}
                  onClick={() => {
                    if (subscription?.isFreemium === true) {
                      toast.info("Upgrade for full access.", {
                        toastId: "fav-button-subs-check",
                      });
                      return;
                    } else {
                      handleClick();
                    }
                  }}
                  style={
                    subscription?.isFreemium === true
                      ? { cursor: "default" }
                      : { cursor: "pointer" }
                  }
                />
              )}
            </span>
            <Button
              imageSrc={print}
              text="Print"
              width="118px"
              height="40px"
              className="btn-prime btn-blue text-white h-center v-center br-35 print-btn"
              imageClassName="me-2"
              onClick={() => handlePrint()}
            />
            {summaryData?.["all_resource link"] ? (
              <Button
                text={`Download Documents (${summaryData?.["all_resource link"].length})`}
                width="auto"
                height="40px"
                className="btn-prime btn-blue text-white h-center v-center br-35 ps-3 pe-3"
                imageClassName="me-2"
                onClick={() => {
                  DownloadAllLinksToZip(summaryData?.["all_resource link"]);
                }}
              />
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="summary-box-wrapper" ref={contractReportRef}>
          {/* Summary */}
          <div className="summary-box">
            <div className="header">
              <p className="fw-semibold text-blue text-center">Summary </p>
            </div>
            <div className="summary-table d-flex">
              <div className="summary-row border-0">
                <div className="summary-details" style={{ maxWidth: "100%" }}>
                  <p className="text-blue fw-500">
                    {summaryData?.["Summary"]
                      ? summaryData?.["Summary"]
                      : "NA"}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* basic information */}
          <div className="summary-box">
            <div className="header mb-3">
              <p className="fw-semibold text-blue">Overview</p>
            </div>

            <div className="summary-table">
              {valueOrNA(summaryData?.["RFP Title"]) === true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p>Contract Title</p>
                  </div>
                  <div className="summary-details">
                    <p className={classNames("text-blue fw-500")}>
                      {summaryData?.["RFP Title"]}
                    </p>
                  </div>
                </div>
              )}

              {dueDate && (
                  <div className="summary-row">
                    <div className="summary-title">
                      <p>Open Solicitation</p>
                    </div>
                    <div className="summary-details">
                      <p className="text-blue fw-500">
                        {isSolicitationOpen(dueDate)}
                      </p>
                    </div>
                  </div>
                )}

              {valueOrNA(summaryData?.["Bid Number"]) === true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p>Solicitation number </p>
                  </div>
                  <div className="summary-details">
                    <p className="text-blue fw-500">
                      {summaryData?.["Bid Number"]
                        ? summaryData?.["Bid Number"]
                        : "NA"}
                    </p>
                  </div>
                </div>
              )}

              {valueOrNA(summaryData?.["Date Issued"]) === true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p>Issue Date </p>
                  </div>
                  <div className="summary-details">
                    <p className="text-blue fw-500">
                      {summaryData?.["Date Issued"]}
                    </p>
                  </div>
                </div>
              )}

              {valueOrNA(summaryData?.["offer due date"]) === true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p> Offer Due Date </p>
                  </div>
                  <div className="summary-details">
                    <p className="text-blue fw-500">
                      {moment(summaryData?.["offer due date"]).format(
                        "YYYY-MM-DD"
                      )}
                    </p>
                  </div>
                </div>
              )}

              {valueOrNA(closingDateState) === true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p> Archive Date </p>
                  </div>
                  <div className="summary-details">
                    <p className="text-blue fw-500">{closingDateState}</p>
                  </div>
                </div>
              )}

              {valueOrNA(summaryData?.["Issuing Organization"]) === true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p> Issuing Organization</p>
                  </div>
                  <div className="summary-details">
                    <p className="text-blue fw-500">
                      {summaryData?.["Issuing Organization"]}
                    </p>
                  </div>
                </div>
              )}

              {valueOrNA(summaryData?.["typeOfSetAsideDescription"]) ===
                true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p>Set Aside</p>
                  </div>
                  <div className="summary-details">
                    <p className="text-blue fw-500">
                      {summaryData?.["typeOfSetAsideDescription"]}
                    </p>
                  </div>
                </div>
              )}
              {valueOrNA(summaryData?.["typeOfSetAside"]) === true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p> Set Aside Code</p>
                  </div>
                  <div className="summary-details">
                    <p className="text-blue fw-500">
                      {summaryData?.["typeOfSetAside"]}
                    </p>
                  </div>
                </div>
              )}

              {valueOrNA(summaryData?.["NaicsCode"]) === true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p>NAICS Code</p>
                  </div>
                  <div className="summary-details">
                    <p className="text-blue fw-500">
                      {summaryData?.["NaicsCode"]}
                    </p>
                  </div>
                </div>
              )}
              {valueOrNA(summaryData?.["Agency Office Address"]) === true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p>Agency Office Address</p>
                  </div>
                  <div className="summary-details">
                    <p className="text-blue fw-500">
                      {summaryData?.["Agency Office Address"]}
                    </p>
                  </div>
                </div>
              )}

              {valueOrNA(summaryData?.["Agency name"]) === true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p>Agency Name</p>
                  </div>
                  <div className="summary-details">
                    <p className={classNames("text-blue fw-500")}>
                      {summaryData?.["Agency name"]}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Contract Details */}
          <div className="summary-box">
            <div className="header mb-3">
              <p className="fw-semibold text-blue">Contract Details</p>
            </div>

            <div className="summary-table">
              <div className="summary-row">
                <div className="summary-title">
                  <p> Bid Type</p>
                </div>
                <div className="summary-details">
                  <p className={classNames("text-blue fw-500")}>
                    {summaryData?.["Bid Type: RFP/RFQ/Tender"]}
                    {summaryData?.["Bid Type"]}
                  </p>
                </div>
              </div>
              {valueOrNA(summaryData?.["Link to Similar Awarded Bids"]) ===
                true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p> Link to Similar Awarded Bids </p>
                  </div>
                  <div className="summary-details">
                    <p
                      className={classNames(
                        "text-blue fw-500",
                        subscription?.isFreemium === true && "blur-background"
                      )}
                    >
                      {subscription?.isFreemium === true ? (
                        <ToolTip content="Upgrade for full access.">
                          {maskString(
                            summaryData?.["Link to Similar Awarded Bids"]
                          )}
                        </ToolTip>
                      ) : (
                        summaryData?.["Link to Similar Awarded Bids"]
                      )}
                    </p>
                  </div>
                </div>
              )}
              {valueOrNA(summaryData?.["Place of Performance"]) === true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p>Place of Performance</p>
                  </div>
                  <div className="summary-details">
                    <p className="text-blue fw-500">
                      {summaryData?.["Place of Performance"]}
                    </p>
                  </div>
                </div>
              )}
              {valueOrNA(summaryData?.["Link to Bid"]) === true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p> Link to Bid </p>
                  </div>
                  <div className="summary-details">
                    <p className={classNames("text-blue fw-500")}>
                      <a
                        target="_blank"
                        href={
                          summaryData?.["Link to Bid"]
                            ? summaryData?.["Link to Bid"]
                            : "#"
                        }
                      >
                        {summaryData?.["Link to Bid"]}
                      </a>
                    </p>
                  </div>
                </div>
              )}

              {summaryData?.["Contact Info"] &&
                valueOrNA(summaryData?.["Contact Info"][0]?.fullName) ===
                  true && (
                  <div className="summary-row">
                    <div className="summary-title">
                      <p>Name</p>
                    </div>
                    <div className="summary-details">
                      <p
                        className={classNames(
                          "text-blue fw-500",
                          subscription?.isFreemium === true && "blur-background"
                        )}
                      >
                        {subscription?.isFreemium === true ? (
                          <ToolTip content="Upgrade for full access.">
                            {maskString(
                              summaryData?.["Contact Info"][0]?.fullName
                            )}
                          </ToolTip>
                        ) : (
                          summaryData?.["Contact Info"][0]?.fullName
                        )}
                      </p>
                    </div>
                  </div>
                )}
              {summaryData?.["Contact Info"] &&
                valueOrNA(summaryData?.["Contact Info"][0]?.email) === true && (
                  <div className="summary-row">
                    <div className="summary-title">
                      <p>Email</p>
                    </div>
                    <div className="summary-details">
                      <p
                        className={classNames(
                          "text-blue fw-500",
                          subscription?.isFreemium === true && "blur-background"
                        )}
                      >
                        {subscription?.isFreemium === true ? (
                          <ToolTip content="Upgrade for full access.">
                            {maskString(summaryData?.["Contact Info"][0]?.email)}
                          </ToolTip>
                        ) : (
                          summaryData?.["Contact Info"][0]?.email
                        )}
                      </p>
                    </div>
                  </div>
                )}
              <div className="summary-row">
                <div className="summary-title">
                  <p>Site Visit </p>
                </div>
                <div className="summary-details">
                  <p
                    className={classNames(
                      "text-blue fw-500",
                      subscription?.isFreemium === true && "blur-background"
                    )}
                  >
                    {subscription?.isFreemium === true ? (
                      <ToolTip content="Upgrade for full access.">
                        {maskString(
                          summaryData?.["Site Visit (Y/N)"] ? "Yes" : "No"
                        )}
                      </ToolTip>
                    ) : summaryData?.["Site Visit (Y/N)"] ? (
                      "Yes"
                    ) : (
                      "No"
                    )}
                  </p>
                </div>
              </div>

              {valueOrNA(summaryData?.["Term of Contract/Length of Contract"]) ===
                true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p>Length of Contract</p>
                  </div>
                  <div className="summary-details">
                    <p
                      className={classNames(
                        "text-blue fw-500",
                        subscription?.isFreemium === true && "blur-background"
                      )}
                    >
                      {subscription?.isFreemium === true ? (
                        <ToolTip content="Upgrade for full access.">
                          {maskString(
                            summaryData?.["Term of Contract/Length of Contract"]
                          )}
                        </ToolTip>
                      ) : (
                        summaryData?.["Term of Contract/Length of Contract"]
                      )}
                    </p>
                  </div>
                </div>
              )}
              {valueOrNA(summaryData?.["Delivery information"]) === true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p>Delivery Information</p>
                  </div>
                  <div className="summary-details">
                    <p
                      className={classNames(
                        "text-blue fw-500",
                        subscription?.isFreemium === true && "blur-background"
                      )}
                    >
                      {subscription?.isFreemium === true ? (
                        <ToolTip content="Upgrade for full access.">
                          {maskString(summaryData?.["Delivery information"])}
                        </ToolTip>
                      ) : (
                        summaryData?.["Delivery information"]
                      )}
                    </p>
                  </div>
                </div>
              )}
              {valueOrNA(summaryData?.["Question Deadline"]) === true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p> Question Deadline</p>
                  </div>
                  <div className="summary-details">
                    <p
                      className={classNames(
                        "text-blue fw-500",
                        subscription?.isFreemium === true && "blur-background"
                      )}
                    >
                      {subscription?.isFreemium === true ? (
                        <ToolTip content="Upgrade for full access.">
                          {maskString(summaryData?.["Question Deadline"])}
                        </ToolTip>
                      ) : (
                        summaryData?.["Question Deadline"]
                      )}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Performance Work Statement */}
          <div className="summary-box">
            <div className="header mb-3">
              <p className="fw-semibold text-blue">
                Performance Work Statement{" "}
              </p>
            </div>

            <div className="summary-table">
              {valueOrNA(summaryData?.["Scope of Work"]) === true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p>Scope</p>
                  </div>
                  <div className="summary-details">
                    <ul
                      className={classNames(
                        "text-blue fw-500 scope-ul",
                        subscription?.isFreemium === true && "blur-background"
                      )}
                    >
                      {subscription?.isFreemium === true ? (
                        <ToolTip content="Upgrade for full access.">
                          {summaryData["Scope of Work"]
                            .split(",")
                            .map((item, index) => (
                              <li key={index}>{maskString(item.trim())}</li>
                            ))}
                        </ToolTip>
                      ) : (
                        summaryData["Scope of Work"]
                          .split(",")
                          .map((item, index) => <li key={index}>{item.trim()}</li>)
                      )}
                    </ul>
                  </div>
                </div>
              )}
              {valueOrNA(summaryData?.["Back Ground"]) === true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p>Back Ground</p>
                  </div>
                  <div className="summary-details">
                    <p
                      className={classNames(
                        "text-blue fw-500",
                        subscription?.isFreemium === true && "blur-background"
                      )}
                    >
                      {subscription?.isFreemium === true ? (
                        <ToolTip content="Upgrade for full access.">
                          {maskString(summaryData?.["Back Ground"])}
                        </ToolTip>
                      ) : (
                        summaryData?.["Back Ground"]
                      )}
                    </p>
                  </div>
                </div>
              )}

              {valueOrNA(summaryData?.["Description of Service"]) === true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p>Description of Service </p>
                  </div>
                  <div className="summary-details">
                    <p
                      className={classNames(
                        "text-blue fw-500",
                        subscription?.isFreemium === true && "blur-background"
                      )}
                    >
                      {subscription?.isFreemium === true ? (
                        <ToolTip content="Upgrade for full access.">
                          {maskString(summaryData?.["Description of Service"])}
                        </ToolTip>
                      ) : (
                        summaryData?.["Description of Service"]
                      )}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Requirements */}
          <div className="summary-box">
            <div className="header mb-3">
              <p className="fw-semibold text-blue">Requirements </p>
            </div>

            <div className="summary-table">
              {valueOrNA(summaryData?.["Overview"]) === true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p>Overview </p>
                  </div>
                  <div className="summary-details">
                    <p
                      className={classNames(
                        "text-blue fw-500",
                        subscription?.isFreemium === true && "blur-background"
                      )}
                    >
                      {subscription?.isFreemium === true ? (
                        <ToolTip content="Upgrade for full access.">
                          {maskString(summaryData?.["Overview"])}
                        </ToolTip>
                      ) : (
                        summaryData?.["Overview"]
                      )}
                    </p>
                  </div>
                </div>
              )}
              {valueOrNA(summaryData?.["Shipping and Handling"]) === true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p>Shipping and Handling </p>
                  </div>
                  <div className="summary-details">
                    <p
                      className={classNames(
                        "text-blue fw-500",
                        subscription?.isFreemium === true && "blur-background"
                      )}
                    >
                      {subscription?.isFreemium === true ? (
                        <ToolTip content="Upgrade for full access.">
                          {maskString(summaryData?.["Shipping and Handling"])}
                        </ToolTip>
                      ) : (
                        summaryData?.["Shipping and Handling"]
                      )}
                    </p>
                  </div>
                </div>
              )}
              {valueOrNA(summaryData?.["Shipping Requirements"]) === true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p>Shipping Requirements </p>
                  </div>
                  <div className="summary-details">
                    <p
                      className={classNames(
                        "text-blue fw-500",
                        subscription?.isFreemium === true && "blur-background"
                      )}
                    >
                      {subscription?.isFreemium === true ? (
                        <ToolTip content="Upgrade for full access.">
                          {maskString(summaryData?.["Shipping Requirements"])}
                        </ToolTip>
                      ) : (
                        summaryData?.["Shipping Requirements"]
                      )}
                    </p>
                  </div>
                </div>
              )}
              {valueOrNA(summaryData?.["Ship to Address"]) === true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p>Ship to address </p>
                  </div>
                  <div className="summary-details">
                    <p
                      className={classNames(
                        "text-blue fw-500",
                        subscription?.isFreemium === true && "blur-background"
                      )}
                    >
                      {subscription?.isFreemium === true ? (
                        <ToolTip content="Upgrade for full access.">
                          {maskString(summaryData?.["Ship to Address"])}
                        </ToolTip>
                      ) : (
                        summaryData?.["Ship to Address"]
                      )}
                    </p>
                  </div>
                </div>
              )}
              {valueOrNA(summaryData?.["Supplies/Services"]) === true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p>Supplies/Services </p>
                  </div>
                  <div className="summary-details">
                    <p
                      className={classNames(
                        "text-blue fw-500",
                        subscription?.isFreemium === true && "blur-background"
                      )}
                    >
                      {subscription?.isFreemium === true ? (
                        <ToolTip content="Upgrade for full access.">
                          {maskString(summaryData?.["Supplies/Services"])}
                        </ToolTip>
                      ) : (
                        summaryData?.["Supplies/Services"]
                      )}
                    </p>
                  </div>
                </div>
              )}
              {valueOrNA(summaryData?.["Quantity"]) === true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p>Quantity</p>
                  </div>
                  <div className="summary-details">
                    <p
                      className={classNames(
                        "text-blue fw-500",
                        subscription?.isFreemium === true && "blur-background"
                      )}
                    >
                      {subscription?.isFreemium === true ? (
                        <ToolTip content="Upgrade for full access.">
                          {maskString(summaryData?.["Quantity"])}
                        </ToolTip>
                      ) : (
                        summaryData?.["Quantity"]
                      )}
                    </p>
                  </div>
                </div>
              )}
              {valueOrNA(summaryData?.["Item Name"]) === true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p>Item Name</p>
                  </div>
                  <div className="summary-details">
                    <p
                      className={classNames(
                        "text-blue fw-500",
                        subscription?.isFreemium === true && "blur-background"
                      )}
                    >
                      {subscription?.isFreemium === true ? (
                        <ToolTip content="Upgrade for full access.">
                          {maskString(summaryData?.["Item Name"])}
                        </ToolTip>
                      ) : (
                        summaryData?.["Item Name"]
                      )}
                    </p>
                  </div>
                </div>
              )}
              {valueOrNA(summaryData?.["Clause incorporated"]) === true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p>Clause incorporated </p>
                  </div>
                  <div className="summary-details">
                    <p
                      className={classNames(
                        "text-blue fw-500",
                        subscription?.isFreemium === true && "blur-background"
                      )}
                    >
                      {subscription?.isFreemium === true ? (
                        <ToolTip content="Upgrade for full access.">
                          {maskString(summaryData?.["Clause incorporated"])}
                        </ToolTip>
                      ) : (
                        summaryData?.["Clause incorporated"]
                      )}
                    </p>
                  </div>
                </div>
              )}
              {valueOrNA(summaryData?.["Delivery Date"]) === true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p>Delivery Date</p>
                  </div>
                  <div className="summary-details">
                    <p
                      className={classNames(
                        "text-blue fw-500",
                        subscription?.isFreemium === true && "blur-background"
                      )}
                    >
                      {subscription?.isFreemium === true ? (
                        <ToolTip content="Upgrade for full access.">
                          {maskString(summaryData?.["Delivery Date"])}
                        </ToolTip>
                      ) : (
                        summaryData?.["Delivery Date"]
                      )}
                    </p>
                  </div>
                </div>
              )}
              {valueOrNA(summaryData?.["Documents Required"]) === true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p> Documents Required </p>
                  </div>
                  <div className="summary-details">
                    <p
                      className={classNames(
                        "text-blue fw-500",
                        subscription?.isFreemium === true && "blur-background"
                      )}
                    >
                      {subscription?.isFreemium === true ? (
                        <ToolTip content="Upgrade for full access.">
                          {maskString(summaryData?.["Documents Required"])}
                        </ToolTip>
                      ) : (
                        summaryData?.["Documents Required"]
                      )}
                    </p>
                  </div>
                </div>
              )}

              {valueOrNA(summaryData?.["General Liability Insurance"]) ===
                true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p> General Liability Insurance </p>
                  </div>
                  <div className="summary-details">
                    <p
                      className={classNames(
                        "text-blue fw-500",
                        subscription?.isFreemium === true && "blur-background"
                      )}
                    >
                      {subscription?.isFreemium === true ? (
                        <ToolTip content="Upgrade for full access.">
                          {maskString(
                            summaryData?.["General Liability Insurance"]
                          )}
                        </ToolTip>
                      ) : (
                        summaryData?.["General Liability Insurance"]
                      )}
                    </p>
                  </div>
                </div>
              )}

              {valueOrNA(summaryData?.["Evaluation Criteria"]) === true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p> Evaluation Criteria </p>
                  </div>
                  <div className="summary-details">
                    <p
                      className={classNames(
                        "text-blue fw-500",
                        subscription?.isFreemium === true && "blur-background"
                      )}
                    >
                      {subscription?.isFreemium === true ? (
                        <ToolTip content="Upgrade for full access.">
                          {maskString(summaryData?.["Evaluation Criteria"])}
                        </ToolTip>
                      ) : (
                        summaryData?.["Evaluation Criteria"]
                      )}
                    </p>
                  </div>
                </div>
              )}

              {valueOrNA(summaryData?.["If Yes - List equipment"]) === true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p>Equipment/supplies Required</p>
                  </div>
                  <div className="summary-details">
                    <p
                      className={classNames(
                        "text-blue fw-500",
                        subscription?.isFreemium === true && "blur-background"
                      )}
                    >
                      <ul className="text-blue fw-500 scope-ul">
                        {subscription?.isFreemium === true ? (
                          <ToolTip content="Upgrade for full access.">
                            {summaryData["If Yes - List equipment"]
                              .split(",")
                              .map((item, index) => (
                                <li key={index}>{maskString(item.trim())}</li>
                              ))}
                          </ToolTip>
                        ) : (
                          summaryData["If Yes - List equipment"]
                            .split(",")
                            .map((item, index) => <li key={index}>{item.trim()}</li>)
                        )}
                      </ul>
                    </p>
                  </div>
                </div>
              )}
              {valueOrNA(summaryData?.["If Yes - List requirements"]) ===
                true && (
                <div className="summary-row">
                  <div className="summary-title">
                    <p>Requirements </p>
                  </div>
                  <div className="summary-details">
                    <p
                      className={classNames(
                        "text-blue fw-500",
                        subscription?.isFreemium === true && "blur-background"
                      )}
                    >
                      <ul className="text-blue fw-500 scope-ul">
                        {subscription?.isFreemium === true ? (
                          <ToolTip content="Upgrade for full access.">
                            {summaryData["If Yes - List requirements"]
                              .split(",")
                              .map((item, index) => (
                                <li key={index}>{maskString(item.trim())}</li>
                              ))}
                          </ToolTip>
                        ) : (
                          summaryData["If Yes - List requirements"]
                            .split(",")
                            .map((item, index) => <li key={index}>{item.trim()}</li>)
                        )}
                      </ul>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="note mt-3 ">
            <h5 style={{ color: "#1f2d5c" }}>Disclaimer:</h5>
            <div className="content-dis">
              <p>
                We recommend a thorough review of the entire documentation, as
                the provided summary may not encompass all pertinent details.
              </p>
              <a className="arrow-up-icon pointer" href="#scroll">
                <Icon
                  icon="mi:arrow-up"
                  color="white"
                  width="32"
                  height="32"
                  className="ico"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryReport;
