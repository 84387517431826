import React, { useContext, useEffect, useState } from "react";
import logo from "../../assets/image/home/logo3.png";

import chatBtn from "../../assets/image/home/accountChat.svg";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import getItemFromLocalStorage from "../../utils/getItemFromLocalStorage";
import PusherContext from "../../pusherContext";
import SetItemInLocalStorage from "../../utils/SetItemInLocalStorage";

const Forgot = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const { handleSetUser } = useContext(PusherContext);

  /**
   *
   * Code To Check Updated Secure Localstorage Variable
   */
  const onStorageUpdate = (e) => {
    const { key, newValue } = e;
    if (key == "@secure.j.user") {
      window.location.reload();
    }
  };
  useEffect(() => {
    window.addEventListener("storage", onStorageUpdate);
    return () => {
      window.removeEventListener("storage", onStorageUpdate);
    };
  }, []);
  /**
   *
   *End of Code To Check Updated Secure Localstorage Variable
   */
  useEffect(() => {
    const user = getItemFromLocalStorage("user");
    if (user) {
      const headers = {
        Authorization: `${user.token}`,
        "Content-Type": "application/json",
      };
      axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/users/protected`,
          { userId: user.userID },
          { headers: headers }
        )
        .then((res) => {
          if (res.data && res.data?.data?.subscription) {
            const userInLc = getItemFromLocalStorage("user");
            if (userInLc) {
              Object.assign(userInLc, {
                subscription: res.data?.data?.subscription,
              });
            }
            SetItemInLocalStorage("user", userInLc);
            handleSetUser();
          }
          navigate("/solicitation");
        })
        .catch((err) => {
          console.log(err);
          toast.error("Session Expired!", { toastId: "sessionExpired" });
          localStorage.removeItem("user");
        });
    }
  }, []);

  const handleEmailChange = ({ target }) => {
    setEmail(target.value);
  };
  function checkValidation() {
    let error = false;
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (
      email.trim() === "" ||
      email.trim() === null ||
      email.trim() === undefined ||
      !emailRegex.test(email.trim())
    ) {
      error = true;
      toast.error("Invalid Email", { toastId: "invalidEmail" });
    }

    return error;
  }
  const forgotPasswordHandler = (e) => {
    e.preventDefault();

    if (checkValidation() == false) {
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}/users/forgotPassword`, {
          email: email,
        })
        .then((res) => {
          console.log(res.data);
          toast.success(
            `Reset password email has been sent to email: ${email}`,
            { toastId: "resetPasswordEmailSent" }
          );
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message, {
            toastId: "resetPasswordEmailError",
          });
        });
    }
  };
  return (
    <div className="registerParent">
      <div className="register-sec">
        <div className="reg-container">
          <div className="left">
            <img
              src={logo}
              alt="Simpli logo"
              className="img-fluid simpli-logo pointer"
              onClick={() => navigate("/")}
              style={{ width: "175px", height: "auto" }}
            />
            <div className="content">
              <h4>
                {/* Best Canada / USA <br /> Business Site */}
                Navigate Government Contracts <br /> with Ease.
              </h4>
              {/* <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididun.
              </p> */}
            </div>
          </div>
          <div className="right">
            <h4 className="text-center1-forgot">
              Forgot Password? 
            </h4>

            <div className="form-sec" onSubmit={forgotPasswordHandler}>
              <form action="#">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Email"
                    id="email"
                    onChange={handleEmailChange}
                    name="email"
                    required
                  />
                </div>

                <div className="agree-sec">
                  <p className="d-flex align-items-center gap-2">
                    {/* <input type="checkbox" /> Remember me */}
                  </p>
                  <button className="btn-gradient sign-up">SUBMIT</button>
                </div>
              </form>
            </div>
            <div className="own-account">
              <p>
                No Account yet?{" "}
                <a className="pointer" onClick={() => navigate(-1)}>
                  GO BACK
                </a>
              </p>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
};

export default Forgot;
