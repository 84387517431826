import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./style.css";

const ToolTip = (props) => {
  const { placement = "auto", content, children } = props;

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {content}
    </Tooltip>
  );
  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <span>{children}</span>
    </OverlayTrigger>
  );
};

export default ToolTip;
