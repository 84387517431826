import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import logo from "../../assets/image/home/logo3.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import getItemFromLocalStorage from "../../utils/getItemFromLocalStorage";
import { SpinnerCircular } from "spinners-react";
import ScrollToTop from "../../customHooks/scrollToTop";
import SetItemInLocalStorage from "../../utils/SetItemInLocalStorage";
import PusherContext from "../../pusherContext";
import Login from "./login";

const Register = ({ userPropHandler }) => {
  const [lgShow, setLgShow] = useState(false);
  const navigate = useNavigate();
  const { handleSetUser } = useContext(PusherContext);

  if (getItemFromLocalStorage("user") !== null) {
    navigate("/dashboard");
  }

  useEffect(() => {
    const user = getItemFromLocalStorage("user");
    if (user) {
      const headers = {
        Authorization: `${user.token}`,
        "Content-Type": "application/json",
      };
      axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/users/protected`,
          { userId: user.userID },
          { headers: headers }
        )
        .then((res) => {
          userPropHandler();
          if (res.data && res.data?.data?.subscription) {
            const userInLc = getItemFromLocalStorage("user");
            if (userInLc) {
              Object.assign(userInLc, {
                subscription: res.data?.data?.subscription,
              });
            }
            SetItemInLocalStorage("user", userInLc);
            handleSetUser();
          }
          navigate("/dashboard");
        })
        .catch((err) => {
          console.log(err);
          toast.error("Session Expired!", { toastId: "sessionExpired" });
          localStorage.removeItem("user");
          localStorage.clear();
        });
    }
  }, []);

  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [company, setCompany] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [termsCheckValue, setTermsCheckValue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const planType = {
    ANNUALLY: "annually",
    MONTHLY: "monthly",
    FREEMIUM: "freemium",
  };

  const search = useLocation().search;
  const plan = new URLSearchParams(search).get("plan") ?? planType.MONTHLY;
  const [planSelect, setPlanSelect] = useState(plan);

  const handleEmailChange = ({ target }) => {
    setEmail(target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handlePassChange = ({ target }) => {
    setPassword(target.value);
  };

  const handleConfirmPass = ({ target }) => {
    setConfirmPassword(target.value);
  };

  function checkValidation() {
    let error = false;
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{8,20}$/;
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!emailRegex.test(email.trim())) {
      error = true;
      toast.error("Invalid Email", { toastId: "invalidEmail" });
    }
    if (!firstname) {
      toast.error("First name is required!", { toastId: "invalidFirstname" });
      error = true;
    }
    if (!lastname) {
      toast.error("Last name is required!", { toastId: "invalidLastname" });
      error = true;
    }
    if (!passwordRegex.test(password)) {
      toast.error(
        "Invalid Password! Password should be 8-20 characters and must contain 1 uppercase letter, 1 number",
        { toastId: "invalidPassword" }
      );
      error = true;
    }
    if (password !== confirmPassword) {
      error = true;
      toast.error("Passwords do not match", {
        toastId: "invalidConfirmPassword",
      });
    }
    if (!termsCheckValue) {
      error = true;
      toast.error("Please accept the terms & condition to sign up", {
        toastId: "invalidTerms",
      });
    }
    if (!planSelect) {
      error = true;
      toast.error("Please select one plan!", {
        toastId: "planSelection",
      });
    }
    return error;
  }

  const handleSubmit = async () => {
    if (isLoading) {
      toast.warning("Please wait!", { toastId: "pleaseWait" });
      return;
    }
  
    if (!checkValidation()) {
      setIsLoading(true);
      const url = `${process.env.REACT_APP_SERVER_URL}/users/signup`;
      try {
        const res = await axios.post(url, {
          email: email.toLowerCase(),
          password: password,
          firstname: firstname,
          lastname: lastname,
          company: company,
          plan: planSelect,
        });
  
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        toast.success("Registered successfully!");
        await SetItemInLocalStorage("user", res?.data);
        userPropHandler();
        setIsLoading(false);
  
        if (planSelect === "FREEMIUM") {
          navigate("/dashboard");
        } else {
          navigate("/survey", {
            state: { userSurveyId: res.data.userId },
          });
        }
      } catch (er) {
        console.log(er);
        setIsLoading(false);
        toast.error(er.response?.data?.message || "Registration failed", { toastId: "errorRegister" });
      }
    }
  };
  

  ScrollToTop();

  return (
    <div className="registerParent">
      {isLoading && (
        <div className="loader">
          <SpinnerCircular
            color="#ffffff"
            size={"20%"}
            thickness={150}
            secondaryColor="#32C0BE"
          />
          <span className="customLoadingText">Loading...</span>
        </div>
      )}
      <div className="register-sec">
        <div className="reg-container">
          <div className="left">
            <img
              src={logo}
              alt="Simpli logo"
              className="img-fluid simpli-logo pointer"
              onClick={() => navigate("/")}
              style={{ width: "175px", height: "auto" }}
            />
            <div className="content">
              <h4>Navigate Government Contracts with Ease.</h4>
            </div>
          </div>
          <div className="right">
            <div className="btnbtn-box">
              <Link to="/login" className="btn1 button-1" id="login">Sign In</Link>
              <div className="btn1 button-2" id="register">Sign Up</div>
            </div>  

            <div className="form-sec">
              <div className="form-group">
                <input
                  autoComplete="off-random-string"
                  type="text"
                  className="form-control1"
                  placeholder="First Name"
                  value={firstname}
                  onChange={(e) => {
                    setFirstname(e.target.value);
                  }}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  autoComplete="off-random-string"
                  value={lastname}
                  onChange={(e) => {
                    setLastname(e.target.value);
                  }}
                  className="form-control1"
                  placeholder="Last Name"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  autoComplete="off-random-string"
                  type="email"
                  className="form-control1"
                  placeholder="Email"
                  id="email"
                  value={email}
                  name="email"
                  onChange={handleEmailChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  type={showPassword ? "text" : "password"}
                  className="form-control1"
                  placeholder="Password"
                  id="password"
                  name="password"
                  onChange={handlePassChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  type={showConfirmPassword ? "text" : "password"}
                  className="form-control1"
                  placeholder="Confirm Password"
                  id="confirm-password"
                  name="confirm-password"
                  onChange={handleConfirmPass}
                  required
                />
              </div>
              <div className="form-group">

                <select
                  onChange={(e) => setPlanSelect(e.target.value)}
                  value={planSelect || ""}
                  id="billing_plan"
                  className="form-control2"

                >

                  <option value={planType.FREEMIUM}>Forever Free - $0</option>
                  <option value={planType.ANNUALLY}>Yearly - $99/Mo* - Billed Annually</option>
                </select>
              </div>
              <div className="agree-sec">
                <p className="d-flex align-items-center gap-2">
                  <input
                    type="checkbox"
                    checked={termsCheckValue}
                    onChange={(e) => setTermsCheckValue(!termsCheckValue)}
                  />
                  <p>
                    I accept the
                    <span className="pointer" onClick={() => setLgShow(true)}>
                      <nbsp></nbsp>
                      <span
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                      >
                        Terms & Condition
                      </span>
                    </span>
                  </p>
                </p>
                <button className="btn-gradient sign-up" onClick={handleSubmit}>
                  SIGN UP
                </button>
              </div>
            </div>

          </div>
        </div>  
      </div>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        className="terms-modal"
      >
        <Modal.Header closeButton className="terms-modalheader">
          <Modal.Title id="example-modal-sizes-title-lg">
            Terms and Conditions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="terms-modalbody">
          <p>
            These terms and conditions outline the rules and regulations for the
            use of the Simpli RFP website. By accessing this website, we assume
            you accept these terms and conditions in full. Do not continue to
            use Simpli RFP's website if you do not accept all of the terms and
            conditions stated on this page. The following terminology applies to
            these Terms and Conditions, Privacy Statement and Disclaimer Notice
            and any or all Agreements: "Client", "You" and "Your" refers to you,
            the person accessing this website and accepting the Company's terms
            and conditions. "The Company", "Ourselves", "We", "Our" and "Us",
            refers to our Company. "Party", "Parties", or "Us", refers to both
            the Client and ourselves, or either the Client or ourselves. All
            terms refer to the offer, acceptance and consideration of payment
            necessary to undertake the process of our assistance to the Client
            in the most appropriate manner, whether by formal meetings of a
            fixed duration, or any other means, for the express purpose of
            meeting the Client's needs in respect of provision of the Company's
            stated services/products, in accordance with and subject to,
            prevailing law of. Any use of the above terminology or other words
            in the singular, plural, capitalisation and/or he/she or they, are
            taken as interchangeable and therefore as referring to same.
          </p>
          <div>
            <h4>Cookies</h4>
            <p>
              We employ the use of cookies. By using Simpli RFP's website you
              consent to the use of cookies in accordance with Simpli RFP's
              privacy policy. Most modern day interactive web sites use cookies
              to enable us to retrieve user details for each visit. Cookies are
              used in some areas of our site to enable the functionality of this
              area and ease of use for those people visiting.
            </p>
          </div>
          <div>
            <h4>License</h4>
            <p>
              Unless otherwise stated, Simpli RFP and/or its licensors own the
              intellectual property rights for all material on Simpli RFP. All
              intellectual property rights are reserved. You may view and/or
              print pages from
              <a href="https://www.simplirfp.com" target="_blank">
                https://www.simplirfp.com
              </a>{" "}
              for your own personal use subject to restrictions set in these
              terms and conditions.
            </p>
          </div>
          <div>
            <h4>You must not:</h4>
            <ul>
              <li>
                Republish material from
                <a href="https://www.simplirfp.com" target="_blank">
                  https://www.simplirfp.com
                </a>
              </li>
              <li>
                Sell, rent or sub-license material from
                <a href="https://www.simplirfp.com" target="_blank">
                  https://www.simplirfp.com
                </a>
              </li>
              <li>
                Reproduce, duplicate or copy material from
                <a href="https://www.simplirfp.com" target="_blank">
                  https://www.simplirfp.com
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h4>Reservation of Rights</h4>
            <p>
              We reserve the right at any time and in its sole discretion to
              request that you remove all links or any particular link to our
              Web site. You agree to immediately remove all links to our Web
              site upon such request. We also reserve the right to amend these
              terms and conditions and its linking policy at any time. By
              continuing to link to our Web site, you agree to be bound to and
              abide by these linking terms and conditions.
            </p>
          </div>
          <div>
            <h4>Removal of links from our website</h4>
            <p>
              If you find any link on our Web site or any linked web site
              objectionable for any reason, you may contact us about this. We
              will consider requests to remove links but will have no obligation
              to do so or to respond directly to you. Whilst we endeavour to
              ensure that the information on this website is correct, we do not
              warrant its completeness or accuracy; nor do we commit to ensuring
              that the website remains available or that the material on the
              website is kept up to date.
            </p>
          </div>
          <div>
            <h4>Content Liability</h4>
            <p>
              We shall have no responsibility or liability for any content
              appearing on your Web site. You agree to indemnify and defend us
              against all claims arising out of or based upon your Website. No
              link(s) may appear on any page on your Web site or within any
              context containing content or materials that may be interpreted as
              libelous, obscene or criminal
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Register;
