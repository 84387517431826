import React, { useState, useEffect } from "react";

const Countdown = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const now = new Date();
    const difference = targetDate - now;

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="d-flex gap-5 countdown">
      <div className="countdowns">
        {timeLeft.days}
        <div className="days">DAYS</div>
      </div>
      <div className="countdowns">
        {timeLeft.hours}
        <div className="days">HOURS</div>
      </div>
      <div className="countdowns">
        {timeLeft.minutes}
        <div className="days">MINUTES</div>
      </div>
      <div className="countdowns">
        {timeLeft.seconds}
        <div className="days">SECONDS</div>
      </div>
    </div>
  );
};

export default Countdown;
