// import React, { useEffect } from "react";
// import "./faq.scss";
// import ScrollToTop from "../../customHooks/scrollToTop";
// function Faq() {
//   ScrollToTop();

//   return (
//     <section className="faq-sec">
//       <h1>FAQ's</h1>
//       <div class="accordion faq" id="accordionExample">
//         <div class="accordion-item">
//           <h2 class="accordion-header" id="headingOne">
//             <button
//               class="accordion-button"
//               type="button"
//               data-bs-toggle="collapse"
//               data-bs-target="#collapseOne"
//               aria-expanded="true"
//               aria-controls="collapseOne"
//             >
//               z
//             </button>
//           </h2>
//           <div
//             id="collapseOne"
//             class="accordion-collapse collapse show"
//             aria-labelledby="headingOne"
//             data-bs-parent="#accordionExample"
//           >
//             <div class="accordion-body">
//               <p>Simpli RFP is not a government-owned service.</p>
//             </div>
//           </div>
//         </div>
//         <div class="accordion-item">
//           <h2 class="accordion-header" id="headingTwo">
//             <button
//               class="accordion-button collapsed"
//               type="button"
//               data-bs-toggle="collapse"
//               data-bs-target="#collapseTwo"
//               aria-expanded="false"
//               aria-controls="collapseTwo"
//             >
//               Who utilizes Simpli RFP for Public Tenders?
//             </button>
//           </h2>
//           <div
//             id="collapseTwo"
//             class="accordion-collapse collapse"
//             aria-labelledby="headingTwo"
//             data-bs-parent="#accordionExample"
//           >
//             <div class="accordion-body">
//               <p>
//                 Simpli RFP for Public Tenders is employed by all levels
//                 of the public sector to publish their procurement requirements.
//                 Whether it is to align with global trade agreements or comply
//                 with broader public sector procurement regulations, purchasing
//                 departments rely on Simpli RFP to enhance efficiency, lower
//                 procurement costs, and ensure accountability to all
//                 stakeholders.
//               </p>
//             </div>
//           </div>
//         </div>
//         <div class="accordion-item">
//           <h2 class="accordion-header" id="headingThree">
//             <button
//               class="accordion-button collapsed"
//               type="button"
//               data-bs-toggle="collapse"
//               data-bs-target="#collapseThree"
//               aria-expanded="false"
//               aria-controls="collapseThree"
//             >
//               Is Simpli RFP for Public Tenders exclusively designed for large
//               businesses?
//             </button>
//           </h2>
//           <div
//             id="collapseThree"
//             class="accordion-collapse collapse"
//             aria-labelledby="headingThree"
//             data-bs-parent="#accordionExample"
//           >
//             <div class="accordion-body">
//               <p>
//                 The majority of users of Simpli RFP for Public Tenders fall
//                 within the Small and Medium Enterprise (SME) category.
//                 Statistics indicate that over 80% of our customers belong to
//                 organizations with a workforce of fewer than 50 employees.
//               </p>
//             </div>
//           </div>
//         </div>
//         <div class="accordion-item">
//           <h2 class="accordion-header" id="headingFour">
//             <button
//               class="accordion-button collapsed"
//               type="button"
//               data-bs-toggle="collapse"
//               data-bs-target="#collapseFour"
//               aria-expanded="false"
//               aria-controls="collapseFour"
//             >
//               Is Simpli RFP for Public Tenders exclusively designed for large
//               businesses?
//             </button>
//           </h2>
//           <div
//             id="collapseFour"
//             class="accordion-collapse collapse"
//             aria-labelledby="headingFour"
//             data-bs-parent="#accordionExample"
//           >
//             <div class="accordion-body">
//               <p>
//                 The majority of users of Simpli RFP for Public Tenders fall
//                 within the Small and Medium Enterprise (SME) category.
//                 Statistics indicate that over 80% of our customers belong to
//                 organizations with a workforce of fewer than 50 employees.
//               </p>
//             </div>
//           </div>
//         </div>
//         <div class="accordion-item">
//           <h2 class="accordion-header" id="headingFive">
//             <button
//               class="accordion-button collapsed"
//               type="button"
//               data-bs-toggle="collapse"
//               data-bs-target="#collapseFive"
//               aria-expanded="false"
//               aria-controls="collapseFive"
//             >
//               What is Opportunity Matching, and is it suitable for my needs?
//             </button>
//           </h2>
//           <div
//             id="collapseFive"
//             class="accordion-collapse collapse"
//             aria-labelledby="headingFive"
//             data-bs-parent="#accordionExample"
//           >
//             <div class="accordion-body">
//               <p>
//                 Upon registering with Simpli RFP, you receive one complimentary
//                 profile for the Opportunity Matching feature. Opportunity
//                 Matching is designed to identify opportunities relevant to your
//                 business and send them to you each weekday via email at no
//                 additional cost. You define the search criteria profile, and we
//                 handle the rest. As a Simpli RFP subscriber, you can create up
//                 to five profiles for this valuable service by selecting the
//                 'Setup Profile' link under the Opportunity Matching section on
//                 the Opportunities page. Remember to test your new profile before
//                 saving it to ensure you receive pertinent opportunities.
//               </p>
//             </div>
//           </div>
//         </div>
//         <div class="accordion-item">
//           <h2 class="accordion-header" id="headingSix">
//             <button
//               class="accordion-button collapsed"
//               type="button"
//               data-bs-toggle="collapse"
//               data-bs-target="#collapseSix"
//               aria-expanded="false"
//               aria-controls="collapseSix"
//             >
//               How are contracts typically awarded?
//             </button>
//           </h2>
//           <div
//             id="collapseSix"
//             class="accordion-collapse collapse"
//             aria-labelledby="headingSix"
//             data-bs-parent="#accordionExample"
//           >
//             <div class="accordion-body">
//               <p>
//                 The evaluation criteria used to select the winning bid will be
//                 clearly outlined in the tender documents. These documents will
//                 specify mandatory requirements, desirable elements, and the
//                 weighting of the criteria to be scored. They will also list the
//                 selection factors that determine the contract's recipient. For
//                 inquiries regarding a specific tender, please reach out to the
//                 appropriate contracting authority.
//               </p>
//             </div>
//           </div>
//         </div>
//         <div class="accordion-item">
//           <h2 class="accordion-header" id="headingSeven">
//             <button
//               class="accordion-button collapsed"
//               type="button"
//               data-bs-toggle="collapse"
//               data-bs-target="#collapseSeven"
//               aria-expanded="false"
//               aria-controls="collapseSeven"
//             >
//               If we do not secure a contract, how can we obtain feedback on why
//               we were unsuccessful?
//             </button>
//           </h2>
//           <div
//             id="collapseSeven"
//             class="accordion-collapse collapse"
//             aria-labelledby="headingSeven"
//             data-bs-parent="#accordionExample"
//           >
//             <div class="accordion-body">
//               <p>
//                 Upon request, numerous government contracting authorities offer
//                 debriefings to organizations that were not successful in their
//                 bids.
//               </p>
//             </div>
//           </div>
//         </div>
//         <div class="accordion-item">
//           <h2 class="accordion-header" id="headingEight">
//             <button
//               class="accordion-button collapsed"
//               type="button"
//               data-bs-toggle="collapse"
//               data-bs-target="#collapseEight"
//               aria-expanded="false"
//               aria-controls="collapseEight"
//             >
//               How can I update my contact or organization profile?
//             </button>
//           </h2>
//           <div
//             id="collapseEight"
//             class="accordion-collapse collapse"
//             aria-labelledby="headingEight"
//             data-bs-parent="#accordionExample"
//           >
//             <div class="accordion-body">
//               <p>
//                 Maintaining up-to-date profiles for both your contact
//                 information and your organization is crucial to ensure that
//                 ordered documents are delivered to the correct destination. For
//                 more information on how to update this information, please refer
//                 to the tutorials available after logging in.
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

// export default Faq;
import React, { useEffect } from "react";
import "./faq.scss";
import ScrollToTop from "../../customHooks/scrollToTop";

function Faq() {
  ScrollToTop();

  return (
    <section className="faq-sec">
      <h1>FAQs</h1>
      <div class="accordion faq" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              What is Simpli RFP?
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                Simpli RFP is a cutting-edge government contracting database
                that uses AI technology to summarize contracts, offering an
                efficient way for businesses to access vital information
                quickly. Our platform facilitates the search and download of
                documents and provides intuitive search filters to enhance your
                experience.
              </p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              How does the AI summary feature work?
            </button>
          </h2>
          <div
            id="collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                Our AI summary feature analyzes contracts and provides concise
                summaries, highlighting key information. This allows users to
                quickly grasp essential details without going through the entire
                document, saving time and resources.
              </p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              What is the difference between the free and premium versions?
            </button>
          </h2>
          <div
            id="collapseThree"
            class="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                The freemium model allows users to find and download documents,
                offering better search and filter capabilities than any other
                resource, including paid services. However, the premium version
                grants access to our full AI summary feature, providing more
                in-depth insights and analysis.
              </p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingFour">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              Why is Simpli RFP better than other contracting databases?
            </button>
          </h2>
          <div
            id="collapseFour"
            class="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                Simpli RFP stands out for its AI-driven summaries, superior
                search and filtering capabilities, and user-friendly interface.
                Our platform is designed to be more intuitive and efficient than
                any other available option, both free and paid.
              </p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingFive">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              How can Simpli RFP help businesses win more contracts?
            </button>
          </h2>
          <div
            id="collapseFive"
            class="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                By providing quick access to summarized contract information,
                intuitive search tools, and detailed analyses of previously
                awarded contracts, Simpli RFP equips businesses with the
                knowledge and resources to identify and pursue the most suitable
                opportunities more effectively.
              </p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingSix">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              What future services is Simpli RFP planning to offer?
            </button>
          </h2>
          <div
            id="collapseSix"
            class="accordion-collapse collapse"
            aria-labelledby="headingSix"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                Our roadmap includes the introduction of AI proposal writing to
                help businesses craft winning proposals, expansion to include
                Canadian contracts for broader coverage, and research data on
                previously awarded contracts for strategic insights.
              </p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingSeven">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSeven"
              aria-expanded="false"
              aria-controls="collapseSeven"
            >
              What type of research data on previously awarded contracts can I
              expect?
            </button>
          </h2>
          <div
            id="collapseSeven"
            class="accordion-collapse collapse"
            aria-labelledby="headingSeven"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                Users will be able to access comprehensive data on previously
                awarded contracts, including award amounts, winning companies,
                and key success factors. This information will help businesses
                understand competitive landscapes and improve their proposals.
              </p>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingEight">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEight"
              aria-expanded="false"
              aria-controls="collapseEight"
            >
              How does the AI proposal writing feature work?
            </button>
          </h2>
          <div
            id="collapseEight"
            class="accordion-collapse collapse"
            aria-labelledby="headingEight"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                Our upcoming AI proposal writing feature will leverage advanced
                AI technology to assist users in crafting compelling proposals.
                By analyzing successful proposals and incorporating
                industry-specific insights, it will guide users through the
                proposal writing process, enhancing their chances of success.
              </p>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingNine">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseNine"
              aria-expanded="false"
              aria-controls="collapseNine"
            >
              How can I get the most out of Simpli RFP?
            </button>
          </h2>
          <div
            id="collapseNine"
            class="accordion-collapse collapse"
            aria-labelledby="headingNine"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                To maximize the benefits of Simpli RFP, we recommend exploring
                all available features, including our AI summaries, intuitive
                search and filters, and keeping an eye out for our new services
                as they launch. Premium subscribers will gain access to
                additional resources and insights, further enhancing their
                ability to win contracts.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faq;
