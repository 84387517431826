import React, { useState, useEffect, useContext } from "react";
import "./profile.scss";
import { toast } from "react-toastify";
import axios from "axios";
import { Buffer } from "buffer";
import userPng from "../../assets/icons/user.png";

import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { Icon } from "@iconify/react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import getItemFromLocalStorage from "../../utils/getItemFromLocalStorage";
import PusherContext from "../../pusherContext";
import SetItemInLocalStorage from "../../utils/SetItemInLocalStorage";
// import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'

function Profile({ onGetUserData, userPropHandler }) {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [title, setTitle] = useState("abc");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [showButton, setshowButton] = useState(false);
  const [edit, setEdit] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [imagePlaceHolder, setImagePlaceHolder] = useState(null);
  const [paymentInfo, setPaymentInfo] = useState("");
  const user = getItemFromLocalStorage("user");
  const { unSubscribeChannel, handleSetUser } = useContext(PusherContext);

  const logoutUser = () => {
    unSubscribeChannel();
    if (user) {
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}/users/logout`, {
          userId: user.userID,
          token: user.token,
        })
        .then((res) => {
          localStorage.removeItem("user");
          userPropHandler();

          navigate("/login");
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.data.message == "User not found") {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
    } else {
      toast.warning("Please Login First!", { toastId: "loginFirst" });
      navigate("/login");
    }
  };
  const checkPayments = () => {
    const user = getItemFromLocalStorage("user");

    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/pay/payments/${user.userID}`)
      .then((res) => {
        console.log(res.data);
        console.log(user);

        if (res.data?.subscriptionDetails?.remainingDays > 0) {
        } else if (res.data.userTrialExpired === false) {
        } else {
          // toast.warning("Your subscription has ended! Please subscribe again", { toastId: "subscriptionEnded" })
          toast.warning(
            () => {
              return (
                <div className="toast-div">
                  <span>
                    Your subscription has ended! Please subscribe again OR{" "}
                  </span>
                  <a href="#" onClick={logoutUser}>
                    Logout
                  </a>
                </div>
              );
            },
            {
              toastId: "subscriptionEnded",
              position: "top-right",
              autoClose: false,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          navigate("/pricing");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (user) {
      const headers = {
        Authorization: `${user.token}`,
        "Content-Type": "application/json",
      };
      axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/users/protected`,
          { userId: user.userID },
          { headers: headers }
        )
        .then((res) => {
          // console.log(res);
          checkPayments();
          if (res.data && res.data?.data?.subscription) {
            const userInLc = getItemFromLocalStorage("user");
            if (userInLc) {
              Object.assign(userInLc, {
                subscription: res.data?.data?.subscription,
              });
            }
            SetItemInLocalStorage("user", userInLc);
            handleSetUser();
          }
        })
        .catch((err) => {
          console.log(err);

          toast.error("Session Expired!", { toastId: "sessionExpired" });
          localStorage.removeItem("user");
          navigate("/login");
        });
    } else {
      toast.warning("Please Login First!", { toastId: "loginFirst" });
      navigate("/login");
    }
  }, []);
  useEffect(() => {
    if (user) {
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/pay/payments/${user.userID}`)
        .then((res) => {
          console.log(res.data);
          setPaymentInfo(res.data.payments[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  useEffect(() => {
    if (user) {
      // const url = `http://localhost:4000/users/${user.userID}`;
      // console.log(user)
      const url = `${process.env.REACT_APP_SERVER_URL}/users/${user.userID}`;
      axios
        .get(url)
        .then((res) => {
          setEmail(res.data.userinfo.email);
          setFirstname(res.data.userinfo.firstname);
          setLastname(res.data.userinfo.lastname);
          setAddress(res.data.userinfo.address);
          setPhone(res.data.userinfo.phonenumber);
          setJobTitle(res.data.userinfo.jobtitle);
          setCompany(res.data.userinfo.company);
          if (res.data.userinfo.img.includes("data:undefined")) {
            setImagePlaceHolder("");
          } else {
            setImagePlaceHolder(res.data.userinfo.img);
          }
          // setImageData(res.data.userinfo.img);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  const onProfilePicChange = (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file.size < 4999999) {
        const imgURI = URL.createObjectURL(e.target.files[0]);
        //~~~~~~~~~~~~~~~~~~~~~~Upload thumbnail in firestore storage~~~~~~~~~~~~~~~~~~~~~~
        const formData = new FormData();
        formData.append("image", file);
        formData.append("userId", user.userID); // Add the userId to the formData
        const url = `${process.env.REACT_APP_SERVER_URL}/users/upload/image`;
        axios
          .post(url, formData, {
            headers: {
              "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data
            },
          })
          .then((response) => {
            setImagePlaceHolder(imgURI);
            onGetUserData();
            // Handle the response from the server here
          })
          .catch((error) => {
            console.error(error);
            // Handle any errors here
            toast.error("Error! while upload picture", {
              toastId: "pictureUploadError",
            });
          });
      } else {
        toast.error("File is too big. Must be less than 5 MBs", {
          toastId: "sizeError",
        });
      }
    }
  };
  const saveProfileHandler = () => {
    try {
      if (isLoading) {
        toast.warning("Please wait!", { toastId: "pleaseWait" });
      } else {
        setIsLoading(true);
        const url = `${process.env.REACT_APP_SERVER_URL}/users/${user.userID}`;
        // const url = `http://192.168.18.26:4000/users/${user.userID}`
        const data = {
          firstname,
          lastname,
          jobtitle: jobTitle,
          company: company,
          address,
          phonenumber: phone,
        };
        axios
          .post(url, data)
          .then((res) => {
            setIsLoading(false);
            toast.success("Updated Successfully!", {
              toastId: "updatedSuccessfully",
            });
          })
          .catch((err) => {
            toast.error("Error! While updating profile", {
              toastId: "errorProfile",
            });
            setIsLoading(false);
            console.log(err);
          });
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const handleCustomerPortalRedirection = async () => {
    if (isLoading || !user) return;
    try {
      const headers = {
        Authorization: `${user.token}`,
        "Content-Type": "application/json",
      };
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/pay/payments/customer-portal-session/${user.userID}`,
        { headers: headers }
      );
      if (response?.data && response?.data?.portalSessionUrl) {
        window.open(response?.data?.portalSessionUrl, "_blank");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("err", err);
    }
  };

  return (
    <div className="profile-comp">
      {/* {paymentInfo ? (
        <div className="top-sec">
          <div className="plan">
            <p>
              Your current plan: <span>Basic</span>
            </p>
            <p>Payment Id: {paymentInfo.paymentIntentId}</p>
          </div>
          <div className="payment">
            <p>Payment</p>
            <div className="price">
              <p>${paymentInfo.amount}</p>
              <span>{moment(paymentInfo.createdAt).format("DD/MM/YYYY")}</span>
            </div>
          </div>
          <div className="renewal"></div>
        </div>
      ) : (
        <div className="top-empty">
          <button
            onClick={() => {
              handleCustomerPortalRedirection();
            }}
          >
            Buy Now
          </button>
        </div>
      )} */}
      <section className="profile-section">
        <div className="profile-top">
          <input
            id="pfp"
            type="file"
            accept="image/*"
            onChange={onProfilePicChange}
            style={{ display: "none" }}
          />

          <label htmlFor="pfp">
            {imagePlaceHolder ? (
              <img
                src={imagePlaceHolder}
                alt="..."
                accept="image/*"
                onChange={onProfilePicChange}
              />
            ) : (
              <Icon
                className="profileIcon"
                icon="iconoir:profile-circle"
                color="black"
              />
            )}
          </label>

          <div className="about">
            <h6>{firstname}</h6>
            <p>{email}</p>
          </div>
        </div>
        <div className="deatils">
          <div className="profile-left">
            <h2>Personal Information</h2>
            <div className="content">
              <div className="name">
                <h4>First Name</h4>

                <input
                  type="text"
                  value={firstname}
                  placeholder="Your First Name"
                  onChange={(e) => {
                    setFirstname(e.target.value);
                  }}
                  maxLength={25}
                />
              </div>
              <div className="name">
                <h4>Last Name</h4>

                <input
                  type="text"
                  value={lastname}
                  placeholder="Your Last Name"
                  onChange={(e) => {
                    setLastname(e.target.value);
                  }}
                  maxLength={25}
                />
              </div>
              <div className="job">
                <h4>Job Title</h4>

                <input
                  type="text"
                  placeholder="Job Title"
                  value={jobTitle}
                  onChange={(e) => {
                    setJobTitle(e.target.value);
                  }}
                  maxLength={40}
                />
              </div>
              <div className="job">
                <h4>Company</h4>

                <input
                  type="text"
                  placeholder="Company"
                  value={company}
                  onChange={(e) => {
                    setCompany(e.target.value);
                  }}
                  maxLength={40}
                />
              </div>
              <div className="phone">
                <h4>Phone Number</h4>
                {/* <PhoneInput international
                  countryCallingCodeEditable={false}
                  placeholder="Enter phone number"
                  value={phone}
                  onChange={(phone) => setPhone(phone)} /> */}
                <input
                  type="number"
                  value={phone}
                  placeholder="Your Phone Number"
                  maxLength={15}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>

              {/* <div className="time">
                <h4>Time zone</h4>
                <input type="text" placeholder="Eastern (US & Canada)" />
              </div> */}
            </div>
          </div>

          <div className="profile-right">
            <h2>Account Information</h2>
            <div className="content">
              {/* <div className="method">
                <h4>Contact Method</h4>
                <input type="text" placeholder="Email" readOnly />
              </div> */}
              <div className="email">
                <h4>Email Address</h4>
                <input type="email" placeholder="" readOnly value={email} />
              </div>
              <div className="address">
                <h4>Address</h4>
                <textarea
                  placeholder="Your Address"
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  maxLength={128}
                ></textarea>
              </div>
              {/* <div className="status">
                <h4>Status</h4>
                <input type="text" placeholder="Active" />
              </div> */}
              {/* <div className="language">
                <h4>Language</h4>
                <input type="text" placeholder="English" />
              </div> */}
            </div>
          </div>
          <button
            className="edit-profile text-left"
            onClick={saveProfileHandler}
          >
            Save Profile
          </button>
          {/* {showButton == true ? (
            <div>
              <button className="cancel-profile" onClick={editFunction}>
                Cancel
              </button>
              <button className="save-profile">Save</button>
            </div>
          ) : (
            <button className="edit-profile" onClick={editFunction}>
              Edit Profile
            </button>
          )} */}
        </div>
      </section>
    </div>
  );
}

export default Profile;
