import moment from "moment";
import { STRIPE_SUBSCRIPTION_STATUS } from "../constants/constant";

export const capitalizeFirstLetter = (string) => {
  // console.log('strng',string);
  if (!string || string === undefined) return "";
  return string?.charAt(0).toUpperCase() + string.slice(1);
};

export const capitalizeAllFirstLetters = (string) => {
  if (!string || string === undefined) return "";
  return string.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
    letter.toUpperCase()
  );
};

export const centsToDollars = (cents) => {
  if (typeof cents !== "number" || isNaN(cents)) {
    return "N/A";
  }
  const dollars = cents / 100;
  return `$${dollars}`;
};

export function sleep(ms = 650) {
  console.log("Kindly remember to remove `sleep`");
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const showSubscriptionOrTrailMessage = (
  user,
  subscriptionDaysRemaining,
  subscriptionApiCalled = true
) => {
  if (subscriptionApiCalled === true) {
    if (
      user &&
      user?.userTrialExpired === true &&
      user?.subscriptionStatus === STRIPE_SUBSCRIPTION_STATUS.ACTIVE &&
      subscriptionDaysRemaining > 0
    ) {
      return (
        <span className="fw-bold me-3 text-white">
          Remaining Subscription Days : {subscriptionDaysRemaining}
        </span>
      );
    } else if (
      user &&
      user?.userTrialExpired === true &&
      user?.subscriptionStatus !== STRIPE_SUBSCRIPTION_STATUS.ACTIVE &&
      user?.subscriptionStatus !== STRIPE_SUBSCRIPTION_STATUS.PAUSED &&
      subscriptionDaysRemaining <= 0
    ) {
      return (
        <span className="fw-bold me-3 text-white">
          {`Your subscription has ${
            user?.subscriptionStatus &&
            STRIPE_SUBSCRIPTION_STATUS.find(
              (x) => x === user?.subscriptionStatus
            )
              ? user?.subscriptionStatus?.replace("_", " ")
              : "ended"
          }`}
        </span>
      );
    } else if (
      user &&
      user?.userTrialExpired === false &&
      user?.trialDaysRemaining <= 7 &&
      user?.trialDaysRemaining >= 0
    ) {
      return (
        <span className="fw-bold me-3 text-white">
          Trial Remaining Days : {user.trialDaysRemaining}
        </span>
      );
    } else if (
      user &&
      user?.userTrialExpired === true &&
      user?.trialDaysRemaining <= 0 &&
      user?.subscriptionStatus === STRIPE_SUBSCRIPTION_STATUS.PAUSED
    ) {
      return (
        <span className="fw-bold me-3 text-white">Trail Period Over!</span>
      );
    }
  }
  return <></>;
};

export const calculateSubscriptionRemainingDays = (subscription) => {
  if (subscription) {
    const currentDate = subscription.currentPeriodStart
      ? moment(subscription.currentPeriodStart).startOf("day")
      : moment().startOf("day");

    return moment(subscription.currentPeriodEnd)
      .startOf("day")
      .diff(currentDate, "days");
  }
  return 0;
};

export const maskString = (inputString) => {
  if (!inputString) return "";
  const words = inputString.split(" ");
  const maskedWords = words.map((word) => "*".repeat(word.length));
  return maskedWords.join(" ");
};

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};
