import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./style/main.scss";
import { Helmet } from "react-helmet";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Helmet>
      <title>Simpli RFP</title>
      <meta
        name="description"
        content={
          "At Simpli RFP, our aim is to make government contracts easier to understand. Our software simplifies these complex contracts, making them less overwhelming. We believe that knowledge is a game-changer, and our mission is to empower businesses by reducing contract confusion, enabling them to take advantage of new opportunities with confidence. Your Gateway to Unmatched Supplier"
        }
      />

      <meta property="og:title" content="Simpli RFP" />
      <meta
        property="og:description"
        content={
          "At Simpli RFP, our aim is to make government contracts easier to understand. Our software simplifies these complex contracts, making them less overwhelming. We believe that knowledge is a game-changer, and our mission is to empower businesses by reducing contract confusion, enabling them to take advantage of new opportunities with confidence. Your Gateway to Unmatched Supplier"
        }
      />
      <meta property="og:url" content={"https://www.simplirfp.com/"} />
    </Helmet>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
