import React, { useState, useEffect } from 'react';

const DynamicText = ({ text, typeSpeed = 200, deleteSpeed = 100, delay = 1000, onComplete }) => {
    const [displayText, setDisplayText] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);
    const [charIndex, setCharIndex] = useState(0);

    useEffect(() => {
        let timeoutId;

        const handleTyping = () => {
            if (isDeleting) {
                if (charIndex > 0) {
                    // Continue deleting
                    timeoutId = setTimeout(() => {
                        setDisplayText(text.substring(0, charIndex - 1));
                        setCharIndex(charIndex - 1);
                    }, deleteSpeed);
                } else {
                    // Deleting complete, prepare to type next word
                    setIsDeleting(false);
                    setDisplayText(''); // Clear display text
                    onComplete(); // Notify parent to change the word
                }
            } else {
                if (charIndex < text.length) {
                    // Continue typing
                    timeoutId = setTimeout(() => {
                        setDisplayText(text.substring(0, charIndex + 1));
                        setCharIndex(charIndex + 1);
                    }, typeSpeed);
                } else {
                    // Typing complete, start deleting after a delay
                    timeoutId = setTimeout(() => setIsDeleting(true), delay);
                }
            }
        };

        handleTyping();
        
        return () => clearTimeout(timeoutId);
    }, [text, charIndex, isDeleting, typeSpeed, deleteSpeed, delay, onComplete]);

    return <span className="dynamic-text">{displayText}</span>;
};

export default DynamicText;
