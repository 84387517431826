import { React, useState, useEffect } from "react";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import "../../style/main.scss";
import logo from "../../assets/image/logo.png";
import Button from "./button";
import { PopupWidget } from "react-calendly";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Icon } from "@iconify/react";
import { Modal } from "react-bootstrap";

const Navbar = ({ userProp }) => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [user, setUser] = useState(userProp);
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);

useEffect(() => {
  const handleScroll = () => {
    const position = window.pageYOffset;
    setIsScrolled(position > 200);
  };

  window.addEventListener("scroll", handleScroll);

  return () => {
    window.removeEventListener("scroll", handleScroll);
  };
}, []);

useEffect(() => {
  setUser(userProp);
}, [userProp]);

const navbarStyle = {
  position: "fixed",
  top: "0",
  width: "100%",
  zIndex: 1000,
  transition: "all 1s ease", // Smooth transition for all changes
  backgroundColor: isScrolled ? "rgba(0,0,0,0.4)" : "transparent", // Change background color on scroll
  boxShadow: isScrolled ? "0 2px 4px rgba(0,0,0,0.1)" : "none" // Add box-shadow on scroll
};
  const shouldCustomNav = [
    "/solicitatins",
    "/solicitation",
    "/ai-report",
    "/pricing",
    "/contract-details",
    "/registration",
    "/blog-detail",
    "/Tips-and-Strategies-for-Government-RFPs",
    "/Mistakes-Small-Businesses-Make-When-Applying-for-Government-RFPs",
    "/Understanding-the-Language-of-Government-RFPs",
    "/How-AI-is-Revolutionizing-the-Process-of-Government-Contract-Bidding",
    "/The-Lifecycle-of-a-Government-RFP",
    "/Role-of-Small-Businesses-in-Government-Contracting",
    "/Navigating-the-Complexities-of-Government-Contracts",
    "/Government-Contracting-for-Beginners",
    "/7-Innovative-Ways-to-Streamline-Your-RFP-Response-Process",
    "/Navigating-Success-in-Government-Contract-Regulation",
    "/Impact-of-Government-Contracts-on-Small-Business-Growth",
    "/How-to-Stand-Out-in-Government-Contract-Bidding",
    "/Trends-to-Watch-in-2024-for-Government-Contracting",
    "/Decoding-Government-RFPs-Evaluation-Criteria",
    "/Building-Strong-Relationships-with-Government-Agencies-for-Contracting-Success",
    "/blog-detail-3",
    "/summarize",
    "/faq",
    "/contact-us",
    "/about-us",
  ].includes(location.pathname);

  const shouldHideNav = [
    "/dashboard",
    "/survey",
    "/dashboard",
    "/changePassword",
    "/resetpassword/:id",
    "/resetpassword",
    "*",
  ].includes(location.pathname);

  const customMbNav = [
    "/pricing",
    "/solicitation",
    "/faq",
    "/contact-us",
    "/about-us",
    "/learning-center",
  ].includes(location.pathname);

  const handleMenu = () => setShow(!show);
  const handleNavigation = (link) => {
    navigate(link);
    setShow(false);
  };
  /**
   *
   * Code To Check Updated Secure Localstorage Variable
   */
  const onStorageUpdate = (e) => {
    const { key, newValue } = e;
    if (key == "@secure.j.user") {
      window.location.reload();
    }
  };
  useEffect(() => {
    window.addEventListener("storage", onStorageUpdate);
    return () => {
      window.removeEventListener("storage", onStorageUpdate);
    };
  }, []);
  /**
   *
   *End of Code To Check Updated Secure Localstorage Variable
   */

  useEffect(() => {
    setUser(userProp);
  }, [userProp]);

  const [showDemo, setShowDemo] = useState(false);

  return (
    <div>
      <nav className={`navbar navbar-expand-lg simpli-navbar hide-on-mobile ${location.pathname.startsWith('/dashboard') ? "d-none" : ""}`} style={navbarStyle}>
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="Logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 v-center">
                <li className="nav-item">
                  <NavLink className="nav-link" to="/">
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/blog">
                    Resources
                  </NavLink>
                </li>


                <li className="nav-item">
                  <NavLink className="nav-link" to="/pricing">
                    Plans
                  </NavLink>
                </li>
                {/* {user ? (
                  <>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/pricing">
                        Pricing
                      </NavLink>
                    </li>
                  </>
                ) : (
                  <></>
                )} */}
                {/* <form className=" v-center" role="search"> */}
                <li
                  className="nav-item"
                  // style={{ textColor: "#ffffff", color: "00a2ff" }}
                >
                  { <PopupWidget
                      url={process.env.REACT_APP_CALENDLY_MEETING_LINK}
                      rootElement={document.getElementById("root")}
                      text="Get A Demo"
                      textColor="#ffffff"
                      color="#00a2ff"
                    /> }
                    

                </li>
                {/* </form> */}
                {user ? (
                  <>
                    <Button
                      onClick={(e) => {
                        navigate("/dashboard");
                      }}
                      text="Dashboard"
                      className="btn-prime"
                      width=""
                      height=""
                    />
                  </>
                ) : (
                  <Button
                    onClick={(e) => {
                      navigate("/login");
                    }}
                    text="Login / Signup"
                    className="btn-prime"
                    width=""
                    height=""
                  />
                )}
              </ul>
            </div>
          </div>
        </nav>

        {shouldHideNav ? (
          <></>
        ) : (
          <div
            className={`mobile-menu show-on-mobile ${
              customMbNav ? "customMbNav" : ""
            } `}
            style={{
              position: customMbNav ? " relative" : "absolute",
              top: customMbNav ? "0 " : "0px",
            }}
          >
            <Link to="/">
              <img src={logo} alt="" className="mb-logo" />
            </Link>
            {show ? (
              <Icon
               
                color="white"
                width="32"
                height="32"
                onClick={handleMenu}
              />
            ) : (
              <Icon
                icon="charm:menu-hamburger"
                color="white"
                width="32"
                height="32"
                onClick={handleMenu}
              />
            )}
            <Offcanvas show={show} onHide={handleMenu} className="mb-menu-slider">
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                  <img src={logo} alt="" className="mb-logo" />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <ul className="">
                  <li className="nav-item">
                    <a className="nav-link" onClick={() => handleNavigation("/")}>
                      Home
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link"
                      onClick={() => handleNavigation("/pricing")}
                    >
                      Plans
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      onClick={() => handleNavigation("/blog")}
                    >
                      Resources
                    </a>
                  </li>
                  <li className="nav-item">
                    <PopupWidget
                      url={process.env.REACT_APP_CALENDLY_MEETING_LINK}
                      rootElement={document.getElementById("root")}
                      text="Get A Demo"
                      textColor="#ffffff"
                      color="#00a2ff"
                    />
                  </li>
                  {/* <li className="nav-item">
                  <a className="nav-link" href="#">
                    Why Us
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Blogs
                  </a>
                </li> */}

                  <form className=" v-center" role="search">
                    {user ? (
                      <>
                        <Button
                          onClick={() => handleNavigation("/dashboard")}
                          text="Dashboard"
                          className="btn-prime mt-0"
                          width=""
                          height=""
                        />
                      </>
                    ) : (
                      <Button
                        onClick={() => handleNavigation("/login")}
                        text="Login / Signup"
                        className="btn-prime mt-0"
                        width=""
                        height=""
                      />
                    )}
                  </form>
                </ul>
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        )}

      {showDemo && (
        <Modal
          show={showDemo}
          onHide={() => setShowDemo(false)}
          centered
          size="xl"
          // backdrop="static"
          keyboard={false}
          contentClassName="custom-modal-style"
          // className="px-4"
        >
          <Modal.Body style={{ padding: "0", height: "820px" }}>
            <iframe
              // className=""
              src="https://api.leadconnectorhq.com/widget/booking/wLlX0T7NFTRVz1dhAJWW?backgroundColor=%23ffffff&primaryColor=%23178af6ff&buttonText=Schedule+Meeting&showCalendarTitle=true&showCalendarDescription=true&showCalendarDetails=true&default=true
              "
              style={{
                width: "100%",
                height: "850px",
                border: "none",
                overflowX: "hidden",
              }}
              scrolling="no"
            />
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default Navbar;
