import React, { useEffect, useState } from "react";
import "./appointments.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { Icon } from "@iconify/react";
import axios from "axios";
import moment from "moment/moment";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import Pagination from "rc-pagination";
import localeInfo from "rc-pagination/lib/locale/en_GB"

function Appointments({ userPropHandler }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //Canceled Event Uri
  const [canceledEventURI, setCanceledEventURI] = useState("");

  const [allAppointments, setAllAppointments] = useState([]);
  //Pagination
  const countPerPage = 4; //Total number of records show per page
  const [totalPages, setTotalPages] = useState(1);
  const [value, setValue] = useState(""); //value for search input box
  const [currentPage, setCurrentPage] = useState(1); //state for current
  const [allAppointmentsPagination, setAllAppointmentsPagination] = useState(
    allAppointments.slice(0, countPerPage)
  );
  const [userURI, setUserURI] = useState("");
  //Loading
  const [isLoading, setIsLoading] = useState("");
  // const fetchEventInvitee = () => {
  //   const options = {
  //     method: 'GET',
  //     url: 'https://api.calendly.com/scheduled_events/22e6e497-63a3-43f2-9fac-07f5ae54ccc6',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: 'eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjk4NjUxNTk5LCJqdGkiOiI1MTE3NGI2Mi05NDE1LTQ3YTYtYjljYS1iNmYyNGMxMGY0MzQiLCJ1c2VyX3V1aWQiOiI3ZWE5MTM3NS0xNjQ0LTRjZTgtYjk3Ni00Y2Q2MWFhZmZhZTAifQ.aPyTQ6J4DEiLs3q--hZqkWItglFCrUgYLAYkxuIQrWBEEx7_UFbiFeJZiw-s0D6vQ5gykkMFJ8y0NKTnk3mF6Q'
  //     }
  //   };

  //   axios.request(options).then(function (response) {
  //     console.log(response.data);
  //   }).catch(function (error) {
  //     console.error(error);
  //   });
  // }
  const fetchScheduledEvents = async (
    user_uri,
    start_time_type = "asc",
    status = "active"
  ) => {
    try {
      setIsLoading(true);
      const options = {
        method: "GET",
        url: "https://api.calendly.com/scheduled_events",
        params: {
          user: user_uri,
          sort: `start_time:${start_time_type}`,
          status: status,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_CALENDLY_TOKEN}`,
        },
      };
      axios
        .request(options)
        .then(function (response) {
          console.log(response.data);
          setIsLoading(false);
          setAllAppointments(response.data.collection);
        })
        .catch(function (error) {
          setIsLoading(false);
          console.error(error);
        });
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching scheduled events:", error);
    }
  };
  const getCurrentUser = () => {
    const options = {
      method: "GET",
      url: "https://api.calendly.com/users/me",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_CALENDLY_TOKEN}`,
      },
    };
    axios
      .request(options)
      .then(function (response) {
        console.log(response.data);
        setUserURI(response.data.resource.uri);
        fetchScheduledEvents(response.data.resource.uri);
      })
      .catch(function (error) {
        console.error(error);
      });
  };
  useEffect(() => {
    getCurrentUser();
  }, []);

  const cancelAppointment = () => {
    try {
      setIsLoading(true);
      const options = {
        method: "POST",
        url: `${canceledEventURI}/cancellation`,
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjk4NjUxNTk5LCJqdGkiOiI1MTE3NGI2Mi05NDE1LTQ3YTYtYjljYS1iNmYyNGMxMGY0MzQiLCJ1c2VyX3V1aWQiOiI3ZWE5MTM3NS0xNjQ0LTRjZTgtYjk3Ni00Y2Q2MWFhZmZhZTAifQ.aPyTQ6J4DEiLs3q--hZqkWItglFCrUgYLAYkxuIQrWBEEx7_UFbiFeJZiw-s0D6vQ5gykkMFJ8y0NKTnk3mF6Q",
        },
        data: { reason: "string" },
      };
      axios
        .request(options)
        .then(function (response) {
          setIsLoading(false);
          console.log(response.data);
          toast.warning("Cancelled Successfully", {
            toastId: "cancelledSuccess",
          });
          setCanceledEventURI("");
          handleClose();
          fetchScheduledEvents(userURI, "asc", "active");
        })
        .catch(function (error) {
          setIsLoading(false);
          console.error(error);
          if (error?.response?.data?.message) {
            toast.error(error.response.data.message, { toastId: "emailError" });
          } else {
            toast.error(error.message, { toastId: "emailError" });
          }
          setCanceledEventURI("");
        });
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };
  function formatConferenceName(name) {
    // Remove underscores and split the string into words
    const words = name.split("_");

    // Capitalize the first letter of each word and join them back together
    const formattedName = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return formattedName;
  }
  const updatePage = (p) => {
    if (allAppointments && allAppointments.length > 0) {
      setCurrentPage(p);
      const to = countPerPage * p;
      const from = to - countPerPage;
      // let temp = allFavouritesDetails.slice(from, to);
      setAllAppointmentsPagination(allAppointments.slice(from, to));
      window.scrollTo(0, 0);
    }
  };
  useEffect(() => {
    if (allAppointments) {
      setAllAppointmentsPagination(allAppointments.slice(0, countPerPage));
      setTotalPages(allAppointments.length);
    }
  }, [allAppointments]);
  return (
    <section className="appointments-sec">
      <div className="top-bar">
        <div className="bar-left">
          <Dropdown>
            <Dropdown.Toggle> Filter Status </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                href="#/action-1"
                onClick={(e) => {
                  fetchScheduledEvents(userURI, "asc", "active");
                }}
              >
                Active
              </Dropdown.Item>
              <Dropdown.Item
                href="#/action-2"
                onClick={(e) => {
                  fetchScheduledEvents(userURI, "asc", "canceled");
                }}
              >
                Canceled
              </Dropdown.Item>
              {/* <Dropdown.Item href="#/action-3">Filter3</Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
          {/* <div className="search-bar">
            <input type="text" placeholder="Search" />
            <Icon
              icon="material-symbols:search"
              color="#4f5b67"
              width="15.21"
              height="15.21"
            />
          </div> */}
        </div>
        <div className="bar-right">
          <Dropdown>
            <Dropdown.Toggle>Sort by date</Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                href="#/action-1"
                onClick={(e) => {
                  fetchScheduledEvents(userURI, "asc");
                }}
              >
                Asc{" "}
              </Dropdown.Item>
              <Dropdown.Item
                href="#/action-2"
                onClick={(e) => {
                  fetchScheduledEvents(userURI, "desc");
                }}
              >
                Desc
              </Dropdown.Item>
              {/* <Dropdown.Item href="#/action-3">Date3</Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
          {/* <Dropdown className="empty-dropdown">
            <Dropdown.Toggle></Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">1 </Dropdown.Item>
              <Dropdown.Item href="#/action-2">2</Dropdown.Item>
              <Dropdown.Item href="#/action-3">3</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
        </div>
      </div>
      <div className="cards-sec">
        {allAppointmentsPagination && allAppointmentsPagination.length > 0 ? (
          allAppointmentsPagination.map((item, index) => {
            return (
              <div className="appoint-card">
                {/* <div className="img-side">
                <img src={item.img} alt="..." />
              </div> */}
                <div className="detail-side">
                  <div className="det-left">
                    <div className="det-top">
                      <h4>{item.name}</h4>
                      <p>{item.status}</p>
                    </div>
                    <div className="det-bottom">
                      <h6>{item.location.join_url}</h6>
                      <h5>
                        {moment(item.start_time).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </h5>
                    </div>
                  </div>
                  <div className="det-right">
                    <div className="detail-top">
                      <div className="icon-side">
                        <img
                          src="/assests/images/dashboard/location.svg"
                          alt="..."
                        />
                        <div>
                          <h5>{formatConferenceName(item.location.type)}</h5>
                        </div>
                      </div>
                      {/* <img className="star-icon" src={item.starimg} alt="..." /> */}
                    </div>
                    <div className="det-buttons">
                      <button
                        className="cancel-btn"
                        onClick={(e) => {
                          // cancelAppointment(item.uri)
                          handleShow();
                          setCanceledEventURI(item.uri);
                        }}
                      >
                        Cancel
                      </button>
                      {/* <button className="accept-btn">Accept</button> */}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>
      {allAppointmentsPagination && allAppointmentsPagination.length > 0 ?
        <div className="">
          <Pagination
            locale={localeInfo}
            showLessItems={true}
            jumpNextIcon={() => { return <button>...</button> }}
            jumpPrevIcon={() => { return <button>...</button> }}
            pageSize={countPerPage}
            onChange={updatePage}
            current={currentPage}
            total={totalPages}
            className="  pt-5  "
          />
        </div> : <></>}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Appointment?</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to cancel this appointment?</Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={cancelAppointment}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <button className="see-more">See More</button>
    </section>
  );
}

export default Appointments;

const data = [
  {
    img: "/assests/images/dashboard/subs/img.svg",
    name: "Johnson swift",
    mail: "johnson@gmail.com",
    time: "2023/10/30 03:00:00 PM",
    address: "Winnipeg, MB, CAN",
    starimg: "/assests/images/dashboard/star.svg",
  },
  {
    img: "/assests/images/dashboard/subs/img.svg",
    name: "Johnson swift",
    mail: "johnson@gmail.com",
    time: "2023/10/30 03:00:00 PM",
    address: "Winnipeg, MB, CAN",
    starimg: "/assests/images/dashboard/star.svg",
  },
  {
    img: "/assests/images/dashboard/subs/img.svg",
    name: "Johnson swift",
    mail: "johnson@gmail.com",
    time: "2023/10/30 03:00:00 PM",
    address: "Winnipeg, MB, CAN",
    starimg: "/assests/images/dashboard/starfill.svg",
  },
  {
    img: "/assests/images/dashboard/subs/img.svg",
    name: "Johnson swift",
    mail: "johnson@gmail.com",
    time: "2023/10/30 03:00:00 PM",
    address: "Winnipeg, MB, CAN",
    starimg: "/assests/images/dashboard/star.svg",
  },
  {
    img: "/assests/images/dashboard/subs/img.svg",
    name: "Johnson swift",
    mail: "johnson@gmail.com",
    time: "2023/10/30 03:00:00 PM",
    address: "Winnipeg, MB, CAN",
    starimg: "/assests/images/dashboard/star.svg",
  },
  {
    img: "/assests/images/dashboard/subs/img.svg",
    name: "Johnson swift",
    mail: "johnson@gmail.com",
    time: "2023/10/30 03:00:00 PM",
    address: "Winnipeg, MB, CAN",
    starimg: "/assests/images/dashboard/starfill.svg",
  },
];
