import React, { forwardRef } from "react";
import poster from "../../assets/image/posters.png";
const VideoPlayer = forwardRef(
  ({ width = "560", height = "315", src, videoPlaying, onSvgClick }, ref) => {
    return (
      <>
        <video
          ref={ref}
          loop={true}
          width={width}
          height={height}
          src={src}
          className="mainImage"
          playsInline={true}
          poster={poster}
        ></video>
        {/* <div className={`animation pointer ${videoPlaying ? "d-none" : ""}`}>
          <img
            onClick={onSvgClick}
            src="/assets/images/anim.png"
            alt="img"
            className="inner"
          />
          <img src="/assets/images/PNG.png" alt="img" className="outer" />
        </div> */}
      </>
    );
  }
);

export default VideoPlayer;
