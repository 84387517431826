import React, { useContext, useEffect, useState } from "react";
import "./favourite.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { Icon } from "@iconify/react";
import axios from "axios";
import moment from "moment/moment";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import canada from "../../assets/icons/canada.svg";
import usa from "../../assets/icons/usa.svg";
import * as chrono from "chrono-node";

import Pagination from "rc-pagination";
import { useNavigate } from "react-router-dom";
import localeInfo from "rc-pagination/lib/locale/en_GB";
import SummaryReport from "../summarize/summaryReport";
import getItemFromLocalStorage from "../../utils/getItemFromLocalStorage";
import useCheckFreemium from "../../customHooks/useCheckFreemium";
import PusherContext from "../../pusherContext";
import SetItemInLocalStorage from "../../utils/SetItemInLocalStorage";
import { SpinnerCircular } from "spinners-react";
import Locked from "../../../src/assets/image/Locked (1).gif";

// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
function Favourites({ userPropHandler }) {
  // Modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { subscription } = useCheckFreemium();
  const { unSubscribeChannel, handleSetUser } = useContext(PusherContext);

  const [clickedFavoriteContract, setClickedFavoriteContract] = useState("");

  const navigate = useNavigate();

  const logoutUser = () => {
    unSubscribeChannel();
    if (user) {
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}/users/logout`, {
          userId: user.userID,
          token: user.token,
        })
        .then((res) => {
          localStorage.removeItem("user");
          userPropHandler();

          navigate("/login");
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.data.message === "User not found") {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
    } else {
      toast.warning("Please Login First!", { toastId: "loginFirst" });
      navigate("/login");
    }
  };

  const checkPayments = () => {
    const user = getItemFromLocalStorage("user");

    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/pay/payments/${user.userID}`)
      .then((res) => {
        // console.log("res: ", res);
        if (res.data.subscriptionDetails?.remainingDays > 0) {
        } else if (res.data.userTrialExpired === false) {
        } else if (
          res.data.isFreemium === true &&
          res.data.subscriptionDetails === true
        ) {
        } else {
          // toast.warning("Your subscription has ended! Please subscribe again", { toastId: "subscriptionEnded" })
          toast.warning(
            () => {
              return (
                <div className="toast-div">
                  <span>
                    Your subscription has ended! Please subscribe again OR
                  </span>
                  <a href="#" onClick={logoutUser}>
                    Logout
                  </a>
                </div>
              );
            },
            {
              toastId: "subscriptionEnded",
              position: "top-right",
              autoClose: false,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          navigate("/pricing");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (user) {
      const headers = {
        Authorization: `${user.token}`,
        "Content-Type": "application/json",
      };
      axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/users/protected`,
          { userId: user.userID },
          { headers: headers }
        )
        .then((res) => {
          checkPayments();
          if (res.data && res.data?.data?.subscription) {
            const userInLc = getItemFromLocalStorage("user");
            if (userInLc) {
              Object.assign(userInLc, {
                subscription: res.data?.data?.subscription,
              });
            }
            SetItemInLocalStorage("user", userInLc);
            handleSetUser();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Session Expired!", { toastId: "sessionExpired" });
          localStorage.clear();
          unSubscribeChannel();
          navigate("/login");
        });
    } else {
      toast.warning("Please Login First!", { toastId: "loginFirst" });
      localStorage.clear();
      navigate("/login");
    }
  }, []);

  const [allFavouritesDetails, setAllFavouritesDetails] = useState([]);
  const user = getItemFromLocalStorage("user");
  //Pagination
  const countPerPage = 4; //Total number of records show per page
  const [totalPages, setTotalPages] = useState(1);

  const [currentPage, setCurrentPage] = useState(1); //state for current
  const [allFavouritePagination, setAllFavouritePagination] = useState(
    allFavouritesDetails.slice(0, countPerPage)
  );
  //Status
  const [status, setStatus] = useState("");
  const handleStatusChange = (newValue) => {
    setStatus(newValue);
  };
  //Search Box Value
  const [searchBoxValue, setSearchBoxValue] = useState("");
  const handleSearchBoxChange = (newValue) => {
    // console.log(newValue)
    setSearchBoxValue(newValue.target.value);
  };
  //Order By
  const [orderBy, setOrderBy] = useState("");

  const handleOrderByChange = (newValue) => {
    // console.log(newValue);
    setOrderBy(newValue);
  };

  function dateFormat(dateTemp) {
    const dateCustom = dateTemp.split(/,|@/);
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const dateString = `${dateCustom[0]}, ${dateCustom[1]}`;
    const cleanedText = dateString.replace(/\s+/g, " ");
    const cleanedDate = cleanedText.replace(
      /\b(\d{1,2})(st|nd|rd|th)\b/g,
      "$1"
    );
    return new Date(cleanedDate).toLocaleDateString(undefined, options);
    // return `${dateCustom[0]}, ${dateCustom[1]}`;
  }

  function checkClosingDate(closingDate) {
    const date = dateFormat(closingDate);
    if (status === "Open") {
      return moment(date).isAfter();
    } else if (status === "Close") {
      return !moment(date).isAfter();
    }
    return false;
  }

  function extractAndParseDates(text) {
    const results = chrono.parse(text);
    const extractedDates = results
      .filter((result) => result.start)
      .map((result) => result.start.date());
    if (Array.isArray(extractedDates) && extractedDates.length > 0) {
      return moment(extractedDates[0]).format("DD/MM/YYYY");
    } else {
      return "N/A";
    }
    // return extractedDates;
  }

  function sortIssueDate(a, b) {
    if (a?.["Date Issued"] && b?.["Date Issued"]) {
      const dateA = extractAndParseDates(a?.["Date Issued"]);
      const dateB = extractAndParseDates(b?.["Date Issued"]);
      console.log(`dateA`, dateA, "dateB", dateB);
      console.log(moment(dateA).isBefore(dateB));
      if (orderBy === "Issue Date (Asc)") {
        return moment(dateA).isBefore(dateB) ? -1 : 1;
      } else if (orderBy === "Issue Date (Desc)") {
        return moment(dateA).isAfter(dateB) ? -1 : 1;
      }
    }
    return 0;
  }

  //Filter data
  function filterAllData() {
    const filteredData = allFavouritesDetails
      .filter((item) => {
        // Check if the item passes both search box and status filters
        return (
          // Check the search box filter
          (!searchBoxValue ||
            Object.values(item).some(
              (value) =>
                typeof value === "string" &&
                value.toLowerCase().includes(searchBoxValue.toLowerCase())
            )) &&
          // Check the status filter
          (status === "All" ||
            !status ||
            (item?.["Closing Date and Time"] &&
              checkClosingDate(item?.["Closing Date and Time"])))
        );
      })
      .sort((a, b) => sortIssueDate(a, b));
    // console.log(filteredData);
    setAllFavouritePagination(filteredData.slice(0, countPerPage));
  }

  useEffect(() => {
    if (searchBoxValue || orderBy || status) {
      filterAllData();
    }
  }, [searchBoxValue, orderBy, status]);

  const getAllFavouritesOfUser = () => {
    setAllFavouritesDetails([]);
    allFavouritesDetails.length = 0;
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/users/favorite-contracts/${user.userID}`
      )
      .then((res) => {
        console.log(res.data);
        res.data.favoriteContracts.map((item, index) => {
          let getQuery = item.contractLocation === "USA" ? "sam" : "can";

          let url = `${process.env.REACT_APP_RENDER_SERVER_URL}/get_${getQuery}/${item.contractId}`;
          axios
            .get(url)
            .then((res) => {
              console.log(res.data);
              setAllFavouritesDetails((prev) => [
                ...prev,
                { id: item.contractId, data: res.data },
              ]);
            })
            .catch((err) => {
              console.log(err);
            });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (user) {
      getAllFavouritesOfUser();
    }
  }, []);

  function extractAndParseDates(text) {
    const results = chrono.parse(text);
    const extractedDates = results
      .filter((result) => result.start)
      .map((result) => result.start.date());
    if (Array.isArray(extractedDates) && extractedDates.length > 0) {
      return moment(extractedDates[0]).format("DD/MM/YYYY");
    } else {
      return "N/A";
    }
    // return extractedDates;
  }

  //Pagination logic
  // useEffect(() => {
  //   if (!searchBoxValue) {
  //     updatePage(1);
  //     setTotalPages(allContractData.length);
  //   } else {
  //     // searchData.current(searchBoxValue, allContractData);
  //   }
  // }, [searchBoxValue]);

  const updatePage = (p) => {
    if (allFavouritesDetails && allFavouritesDetails.length > 0) {
      setCurrentPage(p);
      const to = countPerPage * p;
      const from = to - countPerPage;
      // let temp = allFavouritesDetails.slice(from, to);
      setAllFavouritePagination(allFavouritesDetails.slice(from, to));
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (allFavouritesDetails) {
      setAllFavouritePagination(allFavouritesDetails.slice(0, countPerPage));
      setTotalPages(allFavouritesDetails.length);
    }
  }, [allFavouritesDetails]);

  const [isLoading, setIsLoading] = useState(false);

  const handleCustomerPortalRedirection = async () => {
    if (isLoading || !user) return;
    try {
      const headers = {
        Authorization: `${user.token}`,
        "Content-Type": "application/json",
      };
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/pay/payments/customer-portal-session/${user.userID}`,
        { headers: headers }
      );
      if (response?.data && response?.data?.portalSessionUrl) {
        window.open(response?.data?.portalSessionUrl, "_blank");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("err", err);
    }
  };

  return (
    <section className="appointments-sec">
      {isLoading && (
        <>
          <div className="loader">
            <SpinnerCircular
              color="#ffffff"
              size={"20%"}
              thickness={150}
              secondaryColor="#32C0BE"
            />
            <span className="customLoadingText">Loading...</span>
          </div>
        </>
      )}
      {subscription?.isFreemium === true ? (
        <>
          <div className="d-flex justify-content-center lead lead-text">
            <span>
              <img
                style={{ height: "250px", width: "250px" }}
                src={Locked}
                draggable="false"
                onContextMenu={(e) => e.preventDefault()}
              />
            </span>
          </div>
          <div
            className="d-flex justify-content-center lead lead-text"
            style={{ fontWeight: "bold" }}
          >
            Upgrade to premium and enjoy unlimited access to our favorites
            feature.
          </div>
        </>
      ) : (
        <>
          <div className="top-bar">
            <div className="bar-left">
              {/* <Dropdown>
            <Dropdown.Toggle> Filter Status </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#">Active</Dropdown.Item>
              <Dropdown.Item href="#">Canceled</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
              <Dropdown>
                <Dropdown.Toggle>Status</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    href="#"
                    onClick={(e) => {
                      handleStatusChange("All");
                    }}
                  >
                    All{" "}
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={(e) => {
                      handleStatusChange("Open");
                    }}
                  >
                    Open{" "}
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={(e) => {
                      handleStatusChange("Close");
                    }}
                  >
                    Close
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className="search-bar">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchBoxValue}
                  onChange={handleSearchBoxChange}
                />
                <Icon
                  icon="material-symbols:search"
                  color="#4f5b67"
                  width="15.21"
                  height="15.21"
                />
              </div>
            </div>
            {/* <div className="bar-right">
          <Dropdown>
            <Dropdown.Toggle>Status</Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#" onClick={e => { handleStatusChange("All") }}>All </Dropdown.Item>
              <Dropdown.Item href="#" onClick={e => { handleStatusChange("Open") }}>Open </Dropdown.Item>
              <Dropdown.Item href="#" onClick={e => { handleStatusChange("Close") }}>Close</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div> */}
            <div className="bar-right">
              <Dropdown>
                <Dropdown.Toggle>Sort by date</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    href="#"
                    onClick={(e) => {
                      handleOrderByChange("Issue Date (Asc)");
                    }}
                  >
                    Asc{" "}
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={(e) => {
                      handleOrderByChange("Issue Date (Desc)");
                    }}
                  >
                    Desc
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="cards-sec">
            {allFavouritePagination && allFavouritePagination.length > 0 ? (
              allFavouritePagination.map((item, index) => {
                return (
                  <div
                    style={{ cursor: "pointer" }}
                    className="appoint-card"
                    key={index}
                    onClick={(e) => {
                      handleShow();
                      setClickedFavoriteContract({
                        id: item.id,
                        data: item.data,
                      });
                    }}
                  >
                    {/* <div className="img-side">
            <img src={item.img} alt="..." />
          </div> */}
                    <div className="detail-side">
                      <div className="det-left">
                        <div className="det-top">
                          <h4>{item?.data?.["RFP Title"]}</h4>
                          <p>{item?.data?.["Bid Number"]}</p>
                        </div>
                        <div className="det-bottom">
                          <h6>
                            {extractAndParseDates(
                              item?.data?.["offer due date"]
                            )}
                          </h6>
                          <h5>Closing Date</h5>
                        </div>
                      </div>
                      <div className="det-right">
                        <div className="detail-top">
                          <div className="icon-side">
                            <img
                              src="/assests/images/dashboard/location.svg"
                              alt="..."
                            />
                            <div>
                              <h6>Location</h6>
                              <h5>{item.data.State}</h5>
                            </div>
                          </div>
                          <img
                            className="star-icon"
                            src={item.data.Location === "Canada" ? canada : usa}
                            alt="..."
                          />
                        </div>
                        {/* <div className="det-buttons">
                      <button className="cancel-btn">Cancel</button> 
                    </div> */}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
          {/* <button className="see-more">See More</button> */}
          {allFavouritePagination && allFavouritePagination.length > 0 ? (
            <div className="">
              <Pagination
                locale={localeInfo}
                showLessItems={true}
                jumpNextIcon={() => {
                  return <button>...</button>;
                }}
                jumpPrevIcon={() => {
                  return <button>...</button>;
                }}
                pageSize={countPerPage}
                onChange={updatePage}
                current={currentPage}
                total={totalPages}
                className="  pt-5  "
              />
            </div>
          ) : (
            <></>
          )}

          {/* Details Modal */}
          <Modal show={show} onHide={handleClose} centered size="xl">
            <Modal.Header closeButton>
              <Modal.Title>Contract Details</Modal.Title>
            </Modal.Header>
            <Modal.Body className="detail-modal-body">
              <SummaryReport
                isFavouriteScreen={true}
                summaryData={clickedFavoriteContract.data}
                contractId={clickedFavoriteContract.id}
              />
            </Modal.Body>
            {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
          </Modal>
        </>
      )}
    </section>
  );
}

export default Favourites;
