import React, { useState, useEffect, useContext } from "react";
import PackageCard from "../components/shared/packageCard";
import price1 from "../assets/image/home/price1.png";
import price3 from "../assets/image/home/price3.png";
import price5 from "../assets/image/home/price5.png";
import Price from "../components/home/price";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import axios from "axios"; // Import Axios
import ClipLoader from "react-spinners/ClipLoader";
import Faq from "../components/faq/faq";
import AI from "../assets/image/contract.png";
import DATABASE from "../assets/image/database-security.png";
import SEARCH from "../assets/image/document-check-mark.png";
import mentor from "../assets/image/mentor.png";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";

import { signUpFunction } from "../components/accounts/functions";
import { Icon } from "@iconify/react";
import bannerimg from "../assets/image/home/pricing-nanner.png";
// import StripePricingTable from "../components/StripePricing/StripePricingTable";
import PusherContext from "../pusherContext";
import SetItemInLocalStorage from "../utils/SetItemInLocalStorage";
import { Helmet } from "react-helmet";
import GradientCanvas from "./GradientCanvas";
import ScrollToTop from "../customHooks/scrollToTop";

const Registration = ({ userPropHandler }) => {
  const planType = {
    ANNUALLY: "annually",
    MONTHLY: "monthly",
    FREEMIUM: "freemium",
  };

  const navigate = useNavigate();
  // ............Use these for testing.........
  // CardNumber=  4242424242424242
  // expiryDate=  04/24
  // cvv=  837
  // postalCode=  28976
  // const stripePromise = loadStripe(
  //   "pk_test_51NSzOGAgKqAATbrgLHjOEJwW197Fcwn1JohAu74xJxgs30FlLzZDGYWj8F7fG6FbtoH5W9fMdR00E8sAb9tJhgBB00ZLSxstEN"
  // );
  const apiUrl = `${process.env.REACT_APP_SERVER_URL}/pay/payment`; // Your server URL
  const testapiUrl = `${process.env.REACT_APP_SERVER_URL}/pay/testingpayment`; // Your server URL
  const [cardHolderName, setCardHolderName] = useState("Hamza");
  //Billing address data
  const [creditAgreeTerms, setCreditAgreeTerms] = useState(false);
  const [address, setAddress] = useState("");
  const [userName, setUserName] = useState("");
  const [city, setCity] = useState("");
  const [pc, setPC] = useState("");
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");

  const { user } = useContext(PusherContext);
  // const [user, setUser] = useState(getItemFromLocalStorage("user"));

  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle the state to show/hide password
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword); // Toggle the state to show/hide password
  };

  // const [isLoadingForSessionId, setIsLoadingForSessionId] = useState(null);

  const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#1f2d5c",
        fontSize: "16px",
        ":-webkit-autofill": { color: "#1f2d5c" },
        "::placeholder": { color: "#12121" },
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee",
      },
    },
  };

  useEffect(() => {
    console.log(amount);
  }, [amount]);

  const submitPayment = async (newUser) => {
    try {
      if (amount == 0) {
        alert("Subscribed Successfull");
        userPropHandler();
        setLoading(false);
        navigate("/dashboard");
        // const response = await axios.post(apiUrl, {
        //   amount: amount,
        //   userId: newUser.userID,
        //   userEmail: newUser.email,
        // });
        // console.log(response);
        // if (response.data.success) {
        //   console.log("Successfully Payment");
        //   alert("Subscribed Successfull");
        //   setLoading(false);
        //   navigate("/dashboard");
        // } else {
        //   console.log("Error", response);
        //   // alert("Payment not sent. Try again");
        //   setLoading(false);
        // }
      } else {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
        });
        if (error) {
          console.log("If error", error);
          alert("Payment not sent. Try again");
          setLoading(false);
        } else {
          console.log("Not error");

          const { id } = paymentMethod;
          // console.log(id);
          const response = await axios.post(apiUrl, {
            amount: amount,
            id,
            userId: newUser.userID,
            userEmail: newUser.email,
          });
          console.log(response);
          if (response.data.success) {
            console.log("Successfully Payment");
            userPropHandler();
            alert("Payment Successfull");
            setLoading(false);
            navigate("/dashboard");
          } else if (response.data.alreadyTrial) {
            console.log("Error", response);
            alert(response.data.message);
            setLoading(false);
          } else {
            console.log("Error", response);
            alert("Payment not sent. Try again");
            setLoading(false);
          }
        }
      }
    } catch (error) {
      console.log("Error", error);
      alert("Payment not sent. Try again");
      setLoading(false);
    }
  };
  // const testingsubmitPayment = async (newUser) => {
  //   try {
  //     if (amount == 0) {
  //       const response = await axios.post(testapiUrl, {
  //         amount: amount,
  //         userId: newUser.userID,
  //         userEmail: newUser.email,
  //       });
  //       console.log(response);
  //       if (response.data.success) {
  //         console.log("Successfully Payment");
  //         alert("Subscribed Successfull");
  //         setLoading(false);
  //         navigate("/dashboard");
  //       } else {
  //         console.log("Error", response);
  //         alert("Payment not sent. Try again");
  //         setLoading(false);
  //       }
  //     }
  //     else {

  //       console.log("Not error");

  //       const id = "1234";
  //       console.log(id);
  //       const response = await axios.post(testapiUrl, {
  //         amount: amount,
  //         id,
  //         userId: newUser.userID,
  //         userEmail: newUser.email,
  //       });
  //       console.log(response);
  //       if (response.data.success) {
  //         console.log("Successfully Payment");
  //         alert("Payment Successfull");
  //         setLoading(false);
  //         navigate("/dashboard");
  //       } else if (response.data.alreadyTrial) {
  //         console.log("Error", response);
  //         alert(response.data.message);
  //         setLoading(false);
  //       } else {
  //         console.log("Error", response);
  //         alert("Payment not sent. Try again");
  //         setLoading(false);
  //       }

  //     }
  //   } catch (error) {
  //     console.log("Error", error);
  //     alert("Payment not sent. Try again");
  //     setLoading(false);
  //   }
  // };
  async function checkingPayments() {
    //Checking payments
    if (!user || !user.userID) return;
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/pay/payments/${user.userID}`)
      .then((res) => {
        console.log(res.data);
        console.log(user);
        // setTotalPayments(res.data.payments);
        // setSubscriptionDaysRemaining(res.data.subscriptionDetails.remainingDays)
        // setSubscriptionApiCalled(true)
        if (res.data.subscriptionDetails.remainingDays > 0) {
          toast.warning("You already have a subscription plan!", {
            toastId: "alreadySubscribed",
          });
          return false;
        } else {
          // toast.warning("Your subscription has ended! Please subscribe again", { toastId: "subscriptionEnded" })
          return true;
        }
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  }

  const handleSubmit = async (e) => {
    // console.log(user);
    // console.log(amount);
    checkingPayments()
      .then((subRes) => {
        if (subRes == true) {
          if (amount) {
            // console.log("Handling submit");
            // e.preventDefault();
            if (
              !address ||
              !city ||
              !pc ||
              !creditAgreeTerms ||
              !country ||
              !region
            ) {
              setLoading(false);
              toast.warning("Enter all data first", { toastId: "allFields" });
            } else {
              if (user?.userID) {
                if (amount <= 0) {
                  toast.warning(
                    "Your Trial Period is Ended Kindly subscribe to use",
                    { toastId: "trialVersion" }
                  );
                } else if (amount >= 2000) {
                  setLoading(true);
                  submitPayment(user);
                  // testingsubmitPayment(user)
                }
              } else {
                setLoading(true);

                signUpFunction(
                  email,
                  password,
                  firstname,
                  lastname,
                  confirmPassword
                )
                  .then(async (res) => {
                    if (res.success) {
                      console.log(res.data);
                      const newUser = res.data;
                      await SetItemInLocalStorage("user", newUser);
                      // setUser(newUser);
                      submitPayment(newUser);
                    } else {
                      toast.error("Error while registering", {
                        toastId: "registerError",
                      });
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    setLoading(false);
                    toast.error("Error while registering", {
                      toastId: "registerError",
                    });
                  });
              }
            }
          } else {
            toast.error("Please select a plan", { toastId: "planError" });
          }
        } else {
          toast.warning("You already have a subscription plan!", {
            toastId: "alreadySubscribed",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(() => {
  //   setIsLoadingForSessionId(true);
  //   console.log("useruseruser", user);
  //   if (user && user?.subscription?.customerId) {
  //     console.log("herererer use effect");
  //     gettingCustomerSessionForStripeTable();
  //   } else {
  //     setIsLoadingForSessionId(false);
  //   }
  // }, [user]);

  // const gettingCustomerSessionForStripeTable = async () => {
  //   try {
  //     console.log("herererer");
  //     //Checking payments
  //     const res = await axios.get(
  //       `${process.env.REACT_APP_SERVER_URL}/pay/payments/customer-session/${user.userID}/${user.subscription?.customerId}`
  //     );

  //     console.log("session portal customer id ", res);
  //     setIsLoadingForSessionId(false);
  //   } catch (err) {
  //     console.log(err);
  //     setIsLoadingForSessionId(false);
  //   }
  // };

  ScrollToTop();
  return (
    <div>
      <Helmet>
        <meta property="og:title" content="Simpli RFP" />
        <meta
          property="og:description"
          content={
            "Discover transparent pricing on our pricing  page, simplifying government contracts for streamlined efficiency. Find the right plan for your needs"
          }
        />
        <meta
          property="og:image"
          content="https://www.simplirfp.com/static/media/logo.a6091cc24034d116e1a2.png"
        />
        <meta property="og:url" content="https://www.simplirfp.com/" />
      </Helmet>
      <div className="bgpricing">
      <div>

        <div className="maxwidth-updated-for displayrow-updated">
          <div className="displayrow-updated pt-5">
            <div>
              <div className="centeredupdated">
                <span
                  className="badge"
                >
                  Simpli RFP Plans
                </span>
              </div>
            </div>
            <div className="ResponsiveTextupdated">
            Get Started for Free -<br></br> Upgrade for Full Access
            </div>
          </div>
      </div>
          <div className="CardContainer">
            <div class="HeroCard__container yourUniqueClassName">
              <div
                class="
        HeroCard__copy
        
      "
              >
                <div
                  class="
    CopyTitle
    CopyTitle--variantSubsection
    
    
    
    HeroCard__title
  "
                >
                  Free
                </div>
                <div
                  class="
    CopyBody
    CopyBody--variantDetail
    
    
    HeroCard__body
  "
                >
                  Find Gov Contracts Faster: Always Free AI Search, Filters &
                  Document Downloads with Simpli RFP.
                </div>

                <div class="HeroCard__ctaContainer">
                  <a
                    style={{
                      color: "white",
                      background:
                        "linear-gradient(270deg, #000 0%, #1f2d5c 100%)",
                    }}
                    href={`${process.env.REACT_APP_FRONTEND_URL}/register?plan=${planType.FREEMIUM}`}
                    className="
                    
    CtaButton
    variant--Button
    CtaButton--arrow
    hoverEffect
  "
                  >
                    Get Started
                  </a>
                </div>
              </div>

              <div class="HeroCard__graphic">
                <div class="HeroPricingSubcard">
                  <div class="HeroPricingSubcard__container">
                    <h2 class="HeroPricingSubcard__title">
                      <span class=" Price">Forever free</span>
                    </h2>

                    <span class="HeroPricingSubcard__suffix">
                      No credit card required
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="HeroCard__container yourUniqueClassName"
              style={{
                backgroundImage:
                  "linear-gradient(270deg, #000 0%, #1f2d5c 100%)",
              }}
            >
              <div
                class="
        HeroCard__copy
        
      "
                style={{ color: "white" }}
              >
                <div
                  class="
    CopyTitle
    CopyTitle--variantSubsection
    
    
    
    HeroCard__title
  "
                >
                  Premium
                </div>
                <div
                  class="
    CopyBody
    CopyBody--variantDetail
    
    
    HeroCard__body
  "
                  style={{ color: "white" }}
                >
                  Upgrade and start saving time today with Unlimited summaries &
                  more. Get hours back on research.
                </div>

                <div class="HeroCard__ctaContainer ">
                  <a
                    style={{ color: "black", background: "white" }}
                    href={`${process.env.REACT_APP_FRONTEND_URL}/register?plan=${planType.ANNUALLY}`}
                    className="CtaButton variant--Button CtaButton--arrow hoverEffect"
                  >
                    Get Started
                  </a>
                </div>
              </div>

              <div class="HeroCard__graphic">
                <ul class="PricingCustomTierSubcard">
                  <li class="PricingCustomTierSubcard__item">
                    <div className="pricing-container">
                      <p className="price-text">$99</p><span className="monthly">/mo* - Billed Annually</span>
                    </div>
                  </li>
                  {/* <li class="PricingCustomTierSubcard__item">IC+ pricing</li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="maxwidth pt-5 displayrow-updated">
        {/* <div style={{ position: "relative", zIndex: "10" }}> */}
        {/* </div> */}

        <div className="displayrow-updated">
          <div>
            <div className="ResponsiveText">What’s Included</div>
          </div>
          <div>
            <div className="ResponsiveTextforpara">
              Unlock the full potential of your Simpli RFP journey with these
              features:
            </div>
          </div>
        </div>
      </div>
      <div className="grid-container pt-5 maxwidthwhatsinclude">
        <div>
          <section data-column-count="1">
            <ul class="List__list">
              <div>
                <img src={AI} style={{ height: "40px", width: "40px" }} />
              </div>
              <div className="textletterspacingupdated">
                AI-Powered Contract Summaries
              </div>

              <li>
                <svg width="16" height="16" viewBox="0 0 16 16">
                  <g fill="var(--accentColor)" fill-rule="evenodd">
                    <circle opacity=".15" cx="8" cy="8" r="8"></circle>
                    <path d="M11.41 4.93L6.64 9.54 5.38 8.18a.7.7 0 0 0-.87-.04.61.61 0 0 0-.18.8l1.5 2.45c.15.22.41.36.69.36.28 0 .53-.14.68-.36.24-.31 4.82-5.78 4.82-5.78.6-.6-.13-1.15-.6-.68z"></path>
                  </g>
                </svg>

                <span className="font-Size-Updated">
                  ⁠ ⁠Efficient AI-driven summaries.
                  {/* <span
                      class="TooltipItem__tooltip"
                      data-js-target="TooltipItem.tooltip"
                    >
                      <p>
                        Add a beautiful checkout form with one line of
                        JavaScript.
                      </p>
                    </span> */}
                </span>
              </li>

              <li>
                <svg width="16" height="16" viewBox="0 0 16 16">
                  <g fill="var(--accentColor)" fill-rule="evenodd">
                    <circle opacity=".15" cx="8" cy="8" r="8"></circle>
                    <path d="M11.41 4.93L6.64 9.54 5.38 8.18a.7.7 0 0 0-.87-.04.61.61 0 0 0-.18.8l1.5 2.45c.15.22.41.36.69.36.28 0 .53-.14.68-.36.24-.31 4.82-5.78 4.82-5.78.6-.6-.13-1.15-.6-.68z"></path>
                  </g>
                </svg>

                <span className="font-Size-Updated">
                  ⁠ ⁠Quick key contract insights.
                  {/* <span
                      class="TooltipItem__tooltip"
                      data-js-target="TooltipItem.tooltip"
                    >
                      <p>
                        Design your own pixel-perfect checkout flows across
                        desktop and mobile.
                      </p>
                    </span> */}
                </span>
              </li>

              <li>
                <svg width="16" height="16" viewBox="0 0 16 16">
                  <g fill="var(--accentColor)" fill-rule="evenodd">
                    <circle opacity=".15" cx="8" cy="8" r="8"></circle>
                    <path d="M11.41 4.93L6.64 9.54 5.38 8.18a.7.7 0 0 0-.87-.04.61.61 0 0 0-.18.8l1.5 2.45c.15.22.41.36.69.36.28 0 .53-.14.68-.36.24-.31 4.82-5.78 4.82-5.78.6-.6-.13-1.15-.6-.68z"></path>
                  </g>
                </svg>

                <span className="font-Size-Updated">
                  ⁠ Accelerates assessment and decisions.
                  {/* <span
                      class="TooltipItem__tooltip"
                      data-js-target="TooltipItem.tooltip"
                    >
                      <p>
                        Use Stripe Checkout or Elements to qualify for the
                        simplest method of PCI validation (SAQ A).
                      </p>
                    </span> */}
                </span>
              </li>

              {/* <li>
                  <svg width="16" height="16" viewBox="0 0 16 16">
                    <g fill="var(--accentColor)" fill-rule="evenodd">
                      <circle opacity=".15" cx="8" cy="8" r="8"></circle>
                      <path d="M11.41 4.93L6.64 9.54 5.38 8.18a.7.7 0 0 0-.87-.04.61.61 0 0 0-.18.8l1.5 2.45c.15.22.41.36.69.36.28 0 .53-.14.68-.36.24-.31 4.82-5.78 4.82-5.78.6-.6-.13-1.15-.6-.68z"></path>
                    </g>
                  </svg>

                  <span>Invoice support</span>
                </li> */}
            </ul>
          </section>
        </div>
        <div>
          <section data-column-count="1">
            <ul class="List__list">
              <div>
                <img src={DATABASE} style={{ height: "40px", width: "40px" }} />
              </div>
              <div className="textletterspacingupdated">
                Real-Time and Free Database
              </div>
              <li>
                <svg width="16" height="16" viewBox="0 0 16 16">
                  <g fill="var(--accentColor)" fill-rule="evenodd">
                    <circle opacity=".15" cx="8" cy="8" r="8"></circle>
                    <path d="M11.41 4.93L6.64 9.54 5.38 8.18a.7.7 0 0 0-.87-.04.61.61 0 0 0-.18.8l1.5 2.45c.15.22.41.36.69.36.28 0 .53-.14.68-.36.24-.31 4.82-5.78 4.82-5.78.6-.6-.13-1.15-.6-.68z"></path>
                  </g>
                </svg>

                <span className="font-Size-Updated">
                  {" "}
                  ⁠ Live contract updates.
                </span>
              </li>

              <li>
                <svg width="16" height="16" viewBox="0 0 16 16">
                  <g fill="var(--accentColor)" fill-rule="evenodd">
                    <circle opacity=".15" cx="8" cy="8" r="8"></circle>
                    <path d="M11.41 4.93L6.64 9.54 5.38 8.18a.7.7 0 0 0-.87-.04.61.61 0 0 0-.18.8l1.5 2.45c.15.22.41.36.69.36.28 0 .53-.14.68-.36.24-.31 4.82-5.78 4.82-5.78.6-.6-.13-1.15-.6-.68z"></path>
                  </g>
                </svg>

                <span className="font-Size-Updated">
                  {" "}
                  ⁠ Comprehensive solicitations repository.
                </span>
              </li>

              <li>
                <svg width="16" height="16" viewBox="0 0 16 16">
                  <g fill="var(--accentColor)" fill-rule="evenodd">
                    <circle opacity=".15" cx="8" cy="8" r="8"></circle>
                    <path d="M11.41 4.93L6.64 9.54 5.38 8.18a.7.7 0 0 0-.87-.04.61.61 0 0 0-.18.8l1.5 2.45c.15.22.41.36.69.36.28 0 .53-.14.68-.36.24-.31 4.82-5.78 4.82-5.78.6-.6-.13-1.15-.6-.68z"></path>
                  </g>
                </svg>

                <span className="font-Size-Updated">
                  {" "}
                  ⁠ Free document access; premium insights.
                </span>
              </li>

              {/* <li>
                <svg width="16" height="16" viewBox="0 0 16 16">
                  <g fill="var(--accentColor)" fill-rule="evenodd">
                    <circle opacity=".15" cx="8" cy="8" r="8"></circle>
                    <path d="M11.41 4.93L6.64 9.54 5.38 8.18a.7.7 0 0 0-.87-.04.61.61 0 0 0-.18.8l1.5 2.45c.15.22.41.36.69.36.28 0 .53-.14.68-.36.24-.31 4.82-5.78 4.82-5.78.6-.6-.13-1.15-.6-.68z"></path>
                  </g>
                </svg>

                <span className="font-Size-Updated">
                  {" "}
                  ⁠ Premium summaries with advanced insights.
                </span>
              </li> */}
            </ul>
          </section>
        </div>
        <div>
          <section data-column-count="1">
            <ul class="List__list">
              <div>
                <img src={SEARCH} style={{ height: "40px", width: "40px" }} />
              </div>
              <div className="textletterspacingupdated">
                Intuitive AI-Enhanced Search
              </div>
              <li>
                <svg width="16" height="16" viewBox="0 0 16 16">
                  <g fill="var(--accentColor)" fill-rule="evenodd">
                    <circle opacity=".15" cx="8" cy="8" r="8"></circle>
                    <path d="M11.41 4.93L6.64 9.54 5.38 8.18a.7.7 0 0 0-.87-.04.61.61 0 0 0-.18.8l1.5 2.45c.15.22.41.36.69.36.28 0 .53-.14.68-.36.24-.31 4.82-5.78 4.82-5.78.6-.6-.13-1.15-.6-.68z"></path>
                  </g>
                </svg>

                <span className="font-Size-Updated"> ⁠Advanced AI search.</span>
              </li>

              <li>
                <svg width="16" height="16" viewBox="0 0 16 16">
                  <g fill="var(--accentColor)" fill-rule="evenodd">
                    <circle opacity=".15" cx="8" cy="8" r="8"></circle>
                    <path d="M11.41 4.93L6.64 9.54 5.38 8.18a.7.7 0 0 0-.87-.04.61.61 0 0 0-.18.8l1.5 2.45c.15.22.41.36.69.36.28 0 .53-.14.68-.36.24-.31 4.82-5.78 4.82-5.78.6-.6-.13-1.15-.6-.68z"></path>
                  </g>
                </svg>

                <span className="font-Size-Updated">
                  {" "}
                  ⁠Custom filters for business needs.
                </span>
              </li>

              <li>
                <svg width="16" height="16" viewBox="0 0 16 16">
                  <g fill="var(--accentColor)" fill-rule="evenodd">
                    <circle opacity=".15" cx="8" cy="8" r="8"></circle>
                    <path d="M11.41 4.93L6.64 9.54 5.38 8.18a.7.7 0 0 0-.87-.04.61.61 0 0 0-.18.8l1.5 2.45c.15.22.41.36.69.36.28 0 .53-.14.68-.36.24-.31 4.82-5.78 4.82-5.78.6-.6-.13-1.15-.6-.68z"></path>
                  </g>
                </svg>

                <span className="font-Size-Updated">
                  {" "}
                  ⁠Intuitive UX for easy information access.
                </span>
              </li>

              {/* <li>
                  <svg width="16" height="16" viewBox="0 0 16 16">
                    <g fill="var(--accentColor)" fill-rule="evenodd">
                      <circle opacity=".15" cx="8" cy="8" r="8"></circle>
                      <path d="M11.41 4.93L6.64 9.54 5.38 8.18a.7.7 0 0 0-.87-.04.61.61 0 0 0-.18.8l1.5 2.45c.15.22.41.36.69.36.28 0 .53-.14.68-.36.24-.31 4.82-5.78 4.82-5.78.6-.6-.13-1.15-.6-.68z"></path>
                    </g>
                  </svg>

                  <span>Invoice support</span>
                </li> */}
            </ul>
          </section>
        </div>
        <div>
          <section data-column-count="1">
            <ul class="List__list">
              <div>
                <img src={mentor} style={{ height: "40px", width: "40px" }} />
              </div>
              <div className="textletterspacingupdated">
                Comprehensive Learning and Support
              </div>
              <li>
                <svg width="16" height="16" viewBox="0 0 16 16">
                  <g fill="var(--accentColor)" fill-rule="evenodd">
                    <circle opacity=".15" cx="8" cy="8" r="8"></circle>
                    <path d="M11.41 4.93L6.64 9.54 5.38 8.18a.7.7 0 0 0-.87-.04.61.61 0 0 0-.18.8l1.5 2.45c.15.22.41.36.69.36.28 0 .53-.14.68-.36.24-.31 4.82-5.78 4.82-5.78.6-.6-.13-1.15-.6-.68z"></path>
                  </g>
                </svg>

                <span className="font-Size-Updated">
                  {" "}
                  ⁠Free educational resources.
                </span>
              </li>

              <li>
                <svg width="16" height="16" viewBox="0 0 16 16">
                  <g fill="var(--accentColor)" fill-rule="evenodd">
                    <circle opacity=".15" cx="8" cy="8" r="8"></circle>
                    <path d="M11.41 4.93L6.64 9.54 5.38 8.18a.7.7 0 0 0-.87-.04.61.61 0 0 0-.18.8l1.5 2.45c.15.22.41.36.69.36.28 0 .53-.14.68-.36.24-.31 4.82-5.78 4.82-5.78.6-.6-.13-1.15-.6-.68z"></path>
                  </g>
                </svg>

                <span className="font-Size-Updated">
                  {" "}
                  ⁠Guidance for all expertise levels.
                </span>
              </li>

              <li>
                <svg width="16" height="16" viewBox="0 0 16 16">
                  <g fill="var(--accentColor)" fill-rule="evenodd">
                    <circle opacity=".15" cx="8" cy="8" r="8"></circle>
                    <path d="M11.41 4.93L6.64 9.54 5.38 8.18a.7.7 0 0 0-.87-.04.61.61 0 0 0-.18.8l1.5 2.45c.15.22.41.36.69.36.28 0 .53-.14.68-.36.24-.31 4.82-5.78 4.82-5.78.6-.6-.13-1.15-.6-.68z"></path>
                  </g>
                </svg>

                <span className="font-Size-Updated">
                  ⁠ ⁠Government contract support.
                </span>
              </li>

              {/* <li>
                  <svg width="16" height="16" viewBox="0 0 16 16">
                    <g fill="var(--accentColor)" fill-rule="evenodd">
                      <circle opacity=".15" cx="8" cy="8" r="8"></circle>
                      <path d="M11.41 4.93L6.64 9.54 5.38 8.18a.7.7 0 0 0-.87-.04.61.61 0 0 0-.18.8l1.5 2.45c.15.22.41.36.69.36.28 0 .53-.14.68-.36.24-.31 4.82-5.78 4.82-5.78.6-.6-.13-1.15-.6-.68z"></path>
                    </g>
                  </svg>

                  <span>
                    Invoice support
                  
                  </span>
                </li> */}
            </ul>
          </section>
        </div>
        {/* <div>
          <section data-column-count="1">
            <ul class="List__list">
              <div>
                <img src={price1} style={{ height: "40px", width: "40px" }} />
              </div>
              <div className="textletterspacingupdated">
                Roadmap for Future Expansion *Coming Soon
              </div>
              <li>
                <svg width="16" height="16" viewBox="0 0 16 16">
                  <g fill="var(--accentColor)" fill-rule="evenodd">
                    <circle opacity=".15" cx="8" cy="8" r="8"></circle>
                    <path d="M11.41 4.93L6.64 9.54 5.38 8.18a.7.7 0 0 0-.87-.04.61.61 0 0 0-.18.8l1.5 2.45c.15.22.41.36.69.36.28 0 .53-.14.68-.36.24-.31 4.82-5.78 4.82-5.78.6-.6-.13-1.15-.6-.68z"></path>
                  </g>
                </svg>

                <span className="font-Size-Updated">
                  {" "}
                  ⁠⁠⁠AI assistance for proposal writing (forthcoming).
                </span>
              </li>

              <li>
                <svg width="16" height="16" viewBox="0 0 16 16">
                  <g fill="var(--accentColor)" fill-rule="evenodd">
                    <circle opacity=".15" cx="8" cy="8" r="8"></circle>
                    <path d="M11.41 4.93L6.64 9.54 5.38 8.18a.7.7 0 0 0-.87-.04.61.61 0 0 0-.18.8l1.5 2.45c.15.22.41.36.69.36.28 0 .53-.14.68-.36.24-.31 4.82-5.78 4.82-5.78.6-.6-.13-1.15-.6-.68z"></path>
                  </g>
                </svg>

                <span className="font-Size-Updated">
                  {" "}
                  ⁠Inclusion of Canadian contract opportunities.
                </span>
              </li>

              <li>
                <svg width="16" height="16" viewBox="0 0 16 16">
                  <g fill="var(--accentColor)" fill-rule="evenodd">
                    <circle opacity=".15" cx="8" cy="8" r="8"></circle>
                    <path d="M11.41 4.93L6.64 9.54 5.38 8.18a.7.7 0 0 0-.87-.04.61.61 0 0 0-.18.8l1.5 2.45c.15.22.41.36.69.36.28 0 .53-.14.68-.36.24-.31 4.82-5.78 4.82-5.78.6-.6-.13-1.15-.6-.68z"></path>
                  </g>
                </svg>

                <span className="font-Size-Updated">
                  ⁠ ⁠⁠Analytics on historical contract awards for strategic
                  insights
                </span>
              </li>

              <li>
                <svg width="16" height="16" viewBox="0 0 16 16">
                  <g fill="var(--accentColor)" fill-rule="evenodd">
                    <circle opacity=".15" cx="8" cy="8" r="8"></circle>
                    <path d="M11.41 4.93L6.64 9.54 5.38 8.18a.7.7 0 0 0-.87-.04.61.61 0 0 0-.18.8l1.5 2.45c.15.22.41.36.69.36.28 0 .53-.14.68-.36.24-.31 4.82-5.78 4.82-5.78.6-.6-.13-1.15-.6-.68z"></path>
                  </g>
                </svg>

                <span className="font-Size-Updated">
                  {" "}
                  ⁠Opportunity matching
                </span>
              </li>
              <li>
                <svg width="16" height="16" viewBox="0 0 16 16">
                  <g fill="var(--accentColor)" fill-rule="evenodd">
                    <circle opacity=".15" cx="8" cy="8" r="8"></circle>
                    <path d="M11.41 4.93L6.64 9.54 5.38 8.18a.7.7 0 0 0-.87-.04.61.61 0 0 0-.18.8l1.5 2.45c.15.22.41.36.69.36.28 0 .53-.14.68-.36.24-.31 4.82-5.78 4.82-5.78.6-.6-.13-1.15-.6-.68z"></path>
                  </g>
                </svg>

                <span className="font-Size-Updated">
                  {" "}
                  ⁠Private Company Solicitations
                </span>
              </li>

    
            </ul>
          </section>
        </div> */}
      </div>

      <div className="">
        {/* <h3 className="regist-heading">Pricing</h3> */}

        {/* <div className="package-selection">
          <p className="step-circle">1</p>
          <p className="step-pack-select">Package selection</p>
        </div> */}

        {/* <Price hide="d-none" onGetStartedClick={setAmount} /> */}

        {/* <StripePricingTable /> */}

        {/* <div className="registration-form">
          <form
            id="regist-form"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div className="personal-info">
              {amount && amount == 0 ? (
                <></>
              ) : (
                <div className="payment-method">
                  <div className="package-selection">
                    <p className="step-circle">2</p>
                    <p className="step-pack-select">Payment method</p>
                  </div>
                  <div className="payment-input">
                    <div className="form-group">
                      <p className="fw-semibold mb-4">Card Details </p>

                      <div className=" cardiv">
                        <CardElement options={CARD_OPTIONS} />
                      </div>
                    </div>
                    <div className="CES-input d-none">
                      <div className="form-group">
                        <label htmlFor="cardNumber">Card number</label>
                        <input
                          type="number"
                          name="card number"
                          placeholder="0000 0000 0000 0000"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="expiryDate">Expiry date</label>
                        <input
                          type="text"
                          name="expiry date"
                          placeholder="MM/YY"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="securityCode">Security code</label>
                        <input
                          type="number"
                          name="security code"
                          placeholder="CVV"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!user ? (
                <>
                  <div className="billing-main">
                    <p></p>
                    <h2 className="billing-address">Account</h2>
                    <hr style={{ width: "48%" }} />
                    <div className="billing-input input-fields">
                      <div className="form-group">
                        <label htmlFor="firstName">First Name</label>
                        <input
                          type="text"
                          name="firstName"
                          value={firstname}
                          onChange={(e) => {
                            setFirstname(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="lastName">Last Name</label>
                        <input
                          type="text"
                          name="lastName"
                          value={lastname}
                          onChange={(e) => {
                            setLastname(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="Email">Email</label>
                        <input
                          type="email"
                          name="email"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <input type="text" name="username" />
                      </div>
                      <div className="form-group mb-5">
                        <div className="position-relative">
                          <label htmlFor="password">Password</label>
                          <input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                          />
                          <span
                            onClick={togglePasswordVisibility}
                            className="position-absolute eye-btn pointer"
                          >
                            <Icon
                              icon={
                                showPassword
                                  ? "mdi:eye-outline"
                                  : "mdi:hide-outline"
                              }
                            />
                          </span>
                        </div>
                      </div>
                      <div className="form-group mb-5">
                        <div className="position-relative">
                          <label htmlFor="password">Confirm Password</label>
                          <input
                            type={showConfirmPassword ? "text" : "password"}
                            name="password"
                            value={confirmPassword}
                            onChange={(e) => {
                              setConfirmPassword(e.target.value);
                            }}
                          />
                          <span
                            onClick={toggleConfirmPasswordVisibility}
                            className="position-absolute eye-btn pointer"
                          >
                            <Icon
                              icon={
                                showConfirmPassword
                                  ? "mdi:eye-outline"
                                  : "mdi:hide-outline"
                              }
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div className="billing-main">
                <p></p>
                <h2 className="billing-address">Billing Address</h2>
                <hr style={{ width: "48%" }} />
                <div className="billing-input input-fields">
                  <div className="form-group">
                    <label htmlFor="address">Address</label>
                    <input
                      type="text"
                      name="Address"
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="city">City</label>
                    <input
                      type="text"
                      name="city"
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="postalCode">Postal Code</label>
                    <input
                      type="number"
                      name="postal code"
                      onChange={(e) => setPC(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="country">Country</label>
                    <CountryDropdown
                      value={country}
                      onChange={(val) => setCountry(val)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="stateProvince">State/Province</label>
                    <RegionDropdown
                      country={country}
                      value={region}
                      onChange={(val) => setRegion(val)}
                    />
                  </div>
                </div>
                <div className="credit-agree-terms">
                  <label>
                    <input
                      type="checkbox"
                      id="creditAgreeTerms"
                      name="credit agree"
                      checked={creditAgreeTerms}
                      onChange={(e) => {
                        setCreditAgreeTerms(!creditAgreeTerms);
                      }}
                    />
                    I agree to the credit card terms and conditions
                  </label>
                </div>
              </div>

              {loading == true ? (
                <div className="loader-container">
                  <ClipLoader
                    color={"black"}
                    loading={loading}
                    size={150}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="finalizeBtn">
              <button className="btn-finalize">Submit</button>
            </div>
          </form>
        </div> */}
      </div>
      <Faq />
    </div>
  );
};

export default Registration;
