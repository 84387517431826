import { useContext } from "react";
import PusherContext from "../pusherContext";
import { calculateSubscriptionRemainingDays } from "../utils/utils";

const useCheckFreemium = () => {
  const { user } = useContext(PusherContext);

  const remainingDays = calculateSubscriptionRemainingDays(user?.subscription);

  return {
    isFreemium: user?.isFreemium || null,
    subscription: user?.subscription,
    subscriptionRemainingDays:
      user?.isAdmin === true ? 365 : remainingDays || 0,
  };
};

export default useCheckFreemium;
