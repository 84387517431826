export const STRIPE_SUBSCRIPTION_STATUS = {
  INCOMPLETE: "incomplete",
  INCOMPLETE_EXIRED: "incomplete_expired",
  TRIALING: "trialing",
  ACTIVE: "active",
  PAST_DUE: "past_due",
  CANCELED: "canceled",
  UNPAID: "unpaid",
  PAUSED: "paused",
};

export const STRIPE_INVOICE_SUCCESS_ID = 1;
export const STRIPE_SUBSCRIPTION_UPDATED_ID = 2;
export const STRIPE_SUBSCRIPTION_DELETED_ID = 3;
