import React, { useState } from "react";
import "../../style/main.scss";
import logo from "../../assets/image/logo.png";
import facebook from "../../assets/icons/facebook.svg";
import messenger from "../../assets/icons/tiktok.svg";
import twitter from "../../assets/icons/x.svg";
import instagram from "../../assets/icons/instagram.svg";
import youtube from "../../assets/icons/youtube.svg";
import linkedIn from "../../assets/icons/linkedIn.svg";
import loader from "../../assets/gif/loader.gif";
import { Modal } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useEffect } from "react";
const Footer = () => {
  const [show, setShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [lgShow2, setLgShow2] = useState(false);

  const location = useLocation();
  const shouldCustomNav = [
    "/solicitatins",
    "/ai-report",
    "/contract-details",
    "/registration",
    "/blog-detail",
    "/Tips-and-Strategies-for-Government-RFPs",
    "/Mistakes-Small-Businesses-Make-When-Applying-for-Government-RFPs",
    "/Understanding-the-Language-of-Government-RFPs",
    "/summarize",
    "/faq",
  ].includes(location.pathname);
  const shouldHideNav = [
    "/solicitation",
    "/dashboard",
    "/login",
    "/register",
    "/forgot",
    "/registration",
    "/survey",
  ].includes(location.pathname);
  const handleAISummarize = () => {
    setShow(!show);
  };

  return (
    <div>
      <div className={`simplee-footer  ${shouldHideNav ? "d-none" : ""}`}>
        <div className="top">
          <div className="simplee-footer-content">
            <div className="left-content">
              <Link to="/">
                <img src={logo} alt="" className="footer-logo" />
              </Link>
            </div>
            <div className="center-content ">
              <ul>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
                <li>
                  <Link to="/pricing">Plans </Link>
                </li>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
              </ul>
            </div>
            <div className="center-content ">
              <ul>
                <li>
                  <Link
                    to="#"
                    onClick={() => setLgShow(true)}
                    className="pointer"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <a className="pointer" onClick={() => setLgShow2(true)}>
                    Terms and Conditions
                  </a>
                </li>
                {/* <li>
                  <Link to="#"> Why Us</Link>
                </li> */}

              </ul>
            </div>
            <div className="right-content">
              <p className="socialhead text-white ">Social Media</p>
              <ul className="v-center  mt-4">
                <li>
                  <a
                    href="https://www.facebook.com/profile.php?id=61552162325444"
                    target="_black"
                  >
                    {/* <img src={facebook} alt="" /> */}
                    <Icon
                      icon="ri:facebook-fill"
                      color="#32c4c0"
                      width="24"
                      height="24"
                    />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/SimpliRFP" target="_black">
                    {/* <img src={twitter} alt="" /> */}
                    <Icon
                      icon="simple-icons:x"
                      color="#32c4c0"
                      width="24"
                      height="24"
                    />
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.youtube.com/channel/UCb_xYKb3OLRyOnKTAiFKEbg"
                    target="_black"
                  >
                    <Icon
                      icon="mdi:youtube"
                      color="#32c4c0"
                      width="24"
                      height="24"
                    />
                    {/* <img src={youtube} alt="" /> */}
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/simpli-rfp/about/?viewAsMember=true"
                    target="_black"
                  >
                    {/* <img src={linkedIn} alt="" /> */}
                    <Icon
                      icon="bi:linkedin"
                      color="#32c4c0"
                      width="21"
                      height="21"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="bottom">
          <p>
            Copyright @ 2024 All Rights Reserved
            {/* | Powered by{" "}
            <a href="https://pulsemarketing.ca/" target="_blank">
              Pulse Marketing
            </a> */}
          </p>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleAISummarize}
        centered
        className="simpli-loader"
      >
        <Modal.Body>
          <div className="coming-soon-loader ">
            <img src={loader} alt="" className="img-fluid" />
            <p className="fw-bold text-center">Coming Soon</p>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        className="terms-modal"
      >
        <Modal.Header closeButton className="terms-modalheader">
          <Modal.Title id="example-modal-sizes-title-lg">
            Privacy Policy
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="terms-modalbody">
          <p>
            At Simpli RFP, accessible from{" "}
            <a href="https://www.simplirfp.com" target="_blank">
              https://www.simplirfp.com
            </a>{" "}
            one of our main priorities is the privacy of our visitors. This
            Privacy Policy document contains types of information that is
            collected and recorded by Simpli RFP and how we use it. If you have
            additional questions or require more information about our Privacy
            Policy, do not hesitate to contact us. This Privacy Policy applies
            only to our online activities and is valid for visitors to our
            website with regards to the information that they shared and/or
            collect in Simpli RFP. This policy is not applicable to any
            information collected offline or via channels other than this
            website.
          </p>
          <div>
            <h4>Information we collect</h4>
            <p>
              The personal information that you are asked to provide, and the
              reasons why you are asked to provide it, will be made clear to you
              at the point we ask you to provide your personal information. If
              you contact us directly, we may receive additional information
              about you such as your name, email address, phone number, the
              contents of the message and/or attachments you may send us, and
              any other information you may choose to provide.
            </p>
          </div>
          <div>
            <h4>Log Files</h4>
            <p>
              Simpli RFP follows a standard procedure of using log files. These
              files log visitors when they visit websites. All hosting companies
              do this and a part of hosting services' analytics. The information
              collected by log files include internet protocol (IP) addresses,
              browser type, Internet Service Provider (ISP), date and time
              stamp, referring/exit pages, and possibly the number of clicks.
              These are not linked to any information that is personally
              identifiable. The purpose of the information is for analyzing
              trends, administering the site, tracking users' movement on the
              website, and gathering demographic information.
            </p>
          </div>

          <div>
            <h4>Third Party Privacy Policies</h4>
            <p>
              Simpli RFP's Privacy Policy does not apply to other advertisers or
              websites. Thus, we are advising you to consult the respective
              Privacy Policies of these third-party ad servers for more detailed
              information. It may include their practices and instructions about
              how to opt-out of certain options. You can choose to disable
              cookies through your individual browser options. To know more
              detailed information about cookie management with specific web
              browsers, it can be found at the browsers' respective websites
            </p>
          </div>
          <div>
            <h4>Changes to This Privacy Policy</h4>
            <p>
              We may update our Privacy Policy from time to time. Thus, we
              advise you to review this page periodically for any changes. We
              will notify you of any changes by posting the new Privacy Policy
              on this page. These changes are effective immediately, after they
              are posted on this page.
            </p>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={lgShow2}
        onHide={() => setLgShow2(false)}
        aria-labelledby="example-modal-sizes-title-lg"
        className="terms-modal"
      >
        <Modal.Header closeButton className="terms-modalheader">
          <Modal.Title id="example-modal-sizes-title-lg">
            Terms and Conditions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="terms-modalbody">
          <p>
            These terms and conditions outline the rules and regulations for the
            use of the Simpli RFP website. By accessing this website, we assume
            you accept these terms and conditions in full. Do not continue to
            use Simpli RFP's website if you do not accept all of the terms and
            conditions stated on this page. The following terminology applies to
            these Terms and Conditions, Privacy Statement and Disclaimer Notice
            and any or all Agreements: "Client", "You" and "Your" refers to you,
            the person accessing this website and accepting the Company's terms
            and conditions. "The Company", "Ourselves", "We", "Our" and "Us",
            refers to our Company. "Party", "Parties", or "Us", refers to both
            the Client and ourselves, or either the Client or ourselves. All
            terms refer to the offer, acceptance and consideration of payment
            necessary to undertake the process of our assistance to the Client
            in the most appropriate manner, whether by formal meetings of a
            fixed duration, or any other means, for the express purpose of
            meeting the Client's needs in respect of provision of the Company's
            stated services/products, in accordance with and subject to,
            prevailing law of. Any use of the above terminology or other words
            in the singular, plural, capitalisation and/or he/she or they, are
            taken as interchangeable and therefore as referring to same.
          </p>
          <div>
            <h4>Cookies</h4>
            <p>
              We employ the use of cookies. By using Simpli RFP's website you
              consent to the use of cookies in accordance with Simpli RFP's
              privacy policy. Most modern day interactive web sites use cookies
              to enable us to retrieve user details for each visit. Cookies are
              used in some areas of our site to enable the functionality of this
              area and ease of use for those people visiting.
            </p>
          </div>
          <div>
            <h4>License</h4>
            <p>
              Unless otherwise stated, Simpli RFP and/or its licensors own the
              intellectual property rights for all material on Simpli RFP. All
              intellectual property rights are reserved. You may view and/or
              print pages from <nbsp />
              <a href="https://www.simplirfp.com" target="_blank">
                https://www.simplirfp.com
              </a>{" "}
              for your own personal use subject to restrictions set in these
              terms and conditions.
            </p>
          </div>
          <div>
            <h4>You must not:</h4>
            <ul>
              <li>
                Republish material from <nbsp />
                <a href="https://www.simplirfp.com" target="_blank">
                  https://www.simplirfp.com
                </a>
              </li>
              <li>
                Sell, rent or sub-license material from <nbsp />
                <a href="https://www.simplirfp.com" target="_blank">
                  https://www.simplirfp.com
                </a>
              </li>
              <li>
                Reproduce, duplicate or copy material from <nbsp />
                <a href="https://www.simplirfp.com" target="_blank">
                  https://www.simplirfp.com
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h4>Reservation of Rights</h4>
            <p>
              We reserve the right at any time and in its sole discretion to
              request that you remove all links or any particular link to our
              Web site. You agree to immediately remove all links to our Web
              site upon such request. We also reserve the right to amend these
              terms and conditions and its linking policy at any time. By
              continuing to link to our Web site, you agree to be bound to and
              abide by these linking terms and conditions.
            </p>
          </div>
          <div>
            <h4>Removal of links from our website</h4>
            <p>
              If you find any link on our Web site or any linked web site
              objectionable for any reason, you may contact us about this. We
              will consider requests to remove links but will have no obligation
              to do so or to respond directly to you. Whilst we endeavour to
              ensure that the information on this website is correct, we do not
              warrant its completeness or accuracy; nor do we commit to ensuring
              that the website remains available or that the material on the
              website is kept up to date.
            </p>
          </div>
          <div>
            <h4>Content Liability</h4>
            <p>
              We shall have no responsibility or liability for any content
              appearing on your Web site. You agree to indemnify and defend us
              against all claims arising out of or based upon your Website. No
              link(s) may appear on any page on your Web site or within any
              context containing content or materials that may be interpreted as
              libelous, obscene or criminal
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Footer;
