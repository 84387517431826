import React, { useEffect, useState } from "react";
import "./users.scss";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  TablePagination,
  unstable_createMuiStrictModeTheme,
} from "@mui/material";
import { Badge, Button, Modal } from "react-bootstrap";
import axios from "axios";

import { Icon } from "@iconify/react";
import { SpinnerCircular } from "spinners-react";
import {
  capitalizeAllFirstLetters,
  capitalizeFirstLetter,
  centsToDollars,
} from "../../utils/utils";
import {
  STRIPE_INVOICE_SUCCESS_ID,
  STRIPE_SUBSCRIPTION_DELETED_ID,
  STRIPE_SUBSCRIPTION_STATUS,
  STRIPE_SUBSCRIPTION_UPDATED_ID,
} from "../../constants/constant";
import { useContext } from "react";
import PusherContext from "../../pusherContext";
import _ from "lodash";
import getItemFromLocalStorage from "../../utils/getItemFromLocalStorage";

const data = [
  {
    id: 1,
    image: "/assests/images/dashboard/subs/img.svg",
    name: "John Doe",
    email: "john.doe@example.com",
    phoneNumber: "555-555-5555",
    jobTitle: "Developer",
    address: "123 Main Street, City, Country",
    status: "Block",
  },
  {
    id: 2,
    image: "/assests/images/dashboard/subs/img.svg",
    name: "John Doe",
    email: "john.doe@example.com",
    phoneNumber: "555-555-5555",
    jobTitle: "Developer",
    address: "123 Main Street, City, Country",
    status: "UnBlock",
  },
  {
    id: 3,
    image: "/assests/images/dashboard/subs/img.svg",
    name: "John Doe",
    email: "john.doe@example.com",
    phoneNumber: "555-555-5555",
    jobTitle: "Developer",
    address: "123 Main Street, City, Country",
    status: "UnBlock",
  },
  {
    id: 4,
    image: "/assests/images/dashboard/subs/img.svg",
    name: "John Doe",
    email: "john.doe@example.com",
    phoneNumber: "555-555-5555",
    jobTitle: "Developer",
    address: "123 Main Street, City, Country",
    status: "Block",
  },
  {
    id: 5,
    image: "/assests/images/dashboard/subs/img.svg",
    name: "John Doe",
    email: "john.doe@example.com",
    phoneNumber: "555-555-5555",
    jobTitle: "Developer",
    address: "123 Main Street, City, Country",
    status: "UnBlock",
  },
  {
    id: 6,
    image: "/assests/images/dashboard/subs/img.svg",
    name: "John Doe",
    email: "john.doe@example.com",
    phoneNumber: "555-555-5555",
    jobTitle: "Developer",
    address: "123 Main Street, City, Country",
    status: "UnBlock",
  },
];
function MyVerticallyCenteredModal(props) {
  console.log(props);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Survey Answers
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6>How did you hear about us?</h6>

        <p>
          {props?.surveyAnswers?.howDidYouHear
            ? props.surveyAnswers.howDidYouHear
            : "No Answer"}
        </p>
        <h6>Have you used our website before?</h6>

        <p>
          {props?.surveyAnswers?.usedOurWebsite
            ? props.surveyAnswers.usedOurWebsite
            : "No Answer"}
        </p>
        <h6>What is the purpose of your visit?</h6>

        <p>
          {props?.surveyAnswers?.purposeOfVisit
            ? props.surveyAnswers.purposeOfVisit
            : "No Answer"}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
function Users({ userPropHandler }) {
  const [testData, setTestData] = useState(data);
  const [modalShow, setModalShow] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const user = getItemFromLocalStorage("user");
  const [allUsers, setAllUsers] = useState([]);

  const [selectedUserInvoice, setSelectedUserInvoice] = useState(null);

  //View Survey Answers
  const [clickedSurveyAnswers, setClickedSurveyAnswers] = useState("");

  const { notiData, setNotiData } = useContext(PusherContext);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    // console.log("notiData", notiData);
    if (notiData && allUsers) {
      // STRIPE_SUBSCRIPTION_DELETED_ID
      // STRIPE_SUBSCRIPTION_UPDATED_ID
      // if (notiData.type === STRIPE_INVOICE_SUCCESS_ID) {
      if (notiData?.data?.userId) {
        const prevUsers = _.cloneDeep(allUsers);
        const index = prevUsers.findIndex(
          (x) => x._id === notiData?.data?.userId
        );
        if (index !== -1 && prevUsers[index]?.subscription) {
          const data = prevUsers[index];
          prevUsers.splice(index, 1, {
            ...data,
            subscription: {
              ...data.subscription,
              cancelAt: notiData?.data?.cancelAt,
              cancelAtPeriodEnd: notiData?.data?.cancelAtPeriodEnd,
              isTrail: notiData?.data?.isTrail,
              canceledAt: notiData?.data?.canceledAt,
              status: notiData?.data?.status,
            },
          });
          setAllUsers([...prevUsers]);
        }
      }
      // }
      setNotiData();
    }
  }, [notiData]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleStatusToggle = (id) => {
    // const updatedData = data.map((item) =>
    //   item.id === id
    //     ? { ...item, status: item.status === "Block" ? "UnBlock" : "Block" }
    //     : item
    // );
    // setTestData(updatedData);
    setModalShow(true);
  };

  useEffect(() => {
    if (user) {
      // console.log(user);
      const url = `${process.env.REACT_APP_SERVER_URL}/users/Get/AllUsers`;
      axios
        .get(url)
        .then((res) => {
          // console.log(res.data)
          setAllUsers(res.data);
          // setImageData(res.data.userinfo.img);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  function bufferToImg(imgbuffer, imgType) {
    const base64String = btoa(
      new Uint8Array(imgbuffer).reduce(function (data, byte) {
        return data + String.fromCharCode(byte);
      }, "")
    );
    const dataURI = `data:${imgType};base64,${base64String}`;
    return dataURI;
  }

  const [isLoadingInvoices, setIsLoadingInvoices] = useState(false);
  const [invoicesData, setInvoicesData] = useState([]);

  const getInvoicesByUserId = async ({ userId, customerId }) => {
    if (!userId || !customerId || isLoadingInvoices === true) return;
    try {
      setSelectedUserInvoice(userId);
      setIsLoadingInvoices(true);
      const url = `${process.env.REACT_APP_SERVER_URL}/pay/payments/invoices/${userId}/${customerId}`;
      const response = await axios.get(url);
      if (response?.data && response?.data?.invociesData) {
        setInvoicesData(response?.data?.invociesData);
      }
      setIsLoadingInvoices(false);
    } catch (err) {
      setIsLoadingInvoices(false);
      console.log("err", err);
    }
  };

  // export type Variant = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light' | string;
  const getSubscriptionStatus = (key = "text", status) => {
    switch (status) {
      case STRIPE_SUBSCRIPTION_STATUS.CANCELED:
        return key === "text" ? "Canceled" : "danger";
      case STRIPE_SUBSCRIPTION_STATUS.TRIALING:
        return key === "text" ? "Trial" : "info";
      case STRIPE_SUBSCRIPTION_STATUS.ACTIVE:
        return key === "text" ? "Subscribed" : "primary";
      case STRIPE_SUBSCRIPTION_STATUS.PAST_DUE:
        return key === "text" ? "Past Due" : "warning";
      case STRIPE_SUBSCRIPTION_STATUS.PAUSED:
        return key === "text" ? "Paused" : "dark";
      default:
        return key === "text" ? "N/A" : "secondary";
    }
  };

  return (
    <section className="users-sec">
      <TableContainer component={Paper}>
        <Table aria-label="responsive table">
          <TableHead>
            <TableRow>
              {/* <TableCell>Image</TableCell> */}
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Job Title</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Subscription</TableCell>
              <TableCell>Invoices</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allUsers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.id}>
                  {/* <TableCell>
                    {row?.img ? <img className='profileImg tableuser' src={bufferToImg(row?.img?.data.data, row.img.contentType)} alt={row.name} />
                      : <></>}
                  </TableCell> */}
                  <TableCell>{row.name ? row.name : "N/A"}</TableCell>
                  <TableCell>{row.email ? row.email : "N/A"}</TableCell>
                  <TableCell>
                    {row.phonenumber ? row.phonenumber : "N/A"}
                  </TableCell>
                  <TableCell>{row.jobtitle ? row.jobtitle : "N/A"}</TableCell>
                  <TableCell>{row.address ? row.address : "N/A"}</TableCell>
                  <TableCell>
                    <Badge
                      bg={getSubscriptionStatus(
                        "css",
                        row?.subscription?.status
                      )}
                      style={{ padding: "12px", fontSize: "15px" }}
                    >
                      {getSubscriptionStatus("text", row?.subscription?.status)}
                    </Badge>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="primary"
                      onClick={() => {
                        getInvoicesByUserId({
                          userId: row._id,
                          customerId: row.customerId,
                        });
                      }}
                    >
                      Invoices
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="primary"
                      onClick={(e) => {
                        setClickedSurveyAnswers(row.Survey);
                        handleStatusToggle(row.id);
                      }}
                    >
                      Survey Answers
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={allUsers.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        surveyAnswers={clickedSurveyAnswers}
      />
      {selectedUserInvoice && (
        <Modal
          size="lg"
          show={selectedUserInvoice}
          onHide={() => {
            setSelectedUserInvoice(null);
            setIsLoadingInvoices(false);
            setInvoicesData([]);
          }}
          aria-labelledby="example-modal-sizes-title-lg"
          className="invoices-modal"
        >
          <Modal.Header closeButton className="invoices-modalheader">
            <Modal.Title id="example-modal-sizes-title-lg">
              Invoices
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="invoices-modalbody">
            <TableContainer component={Paper}>
              <Table aria-label="responsive table">
                <TableHead>
                  <TableRow>
                    <TableCell>Invoice Id</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Amount Remaining</TableCell>
                    <TableCell>Amount Paid</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoadingInvoices ? (
                    <div className="loader">
                      <SpinnerCircular
                        color="#ffffff"
                        size={"20%"}
                        thickness={150}
                        secondaryColor="#32C0BE"
                      />
                      <span className="customLoadingText">Loading...</span>
                    </div>
                  ) : !isLoadingInvoices && invoicesData.length > 0 ? (
                    invoicesData.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>{row.number ?? "-"}</TableCell>
                        <TableCell>
                          {capitalizeAllFirstLetters(row.customer_name)}
                        </TableCell>
                        <TableCell>
                          {capitalizeFirstLetter(row.customer_email)}
                        </TableCell>
                        <TableCell>
                          {centsToDollars(row.amount_remaining)}
                        </TableCell>
                        <TableCell>{centsToDollars(row.amount_paid)}</TableCell>
                        <TableCell>
                          {capitalizeFirstLetter(row.status)}
                        </TableCell>
                        <TableCell>
                          {row.invoice_pdf ? (
                            <a
                              href={row.invoice_pdf}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Icon
                                icon={"material-symbols-light:download"}
                                color="black"
                                width="24"
                                height="24"
                              />
                            </a>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          No Data Found!
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Modal.Body>
        </Modal>
      )}
    </section>
  );
}

export default Users;
