import { Helmet } from "react-helmet";

const SEO = () => {
  const seoData = {
    og: {
      url: "https://www.simplirfp.com/",
      type: "Simpli RFP",
      image:
        "https://www.simplirfp.com/static/media/logo.a6091cc24034d116e1a2.png",
      title: "Government Contracts Simplified",
      description:
        "Experience excellence through simplified government contracts...",
    },
    title: "Government Contracts Simplified",
    author: "Simpli RFP",
    charset: "UTF-8",
    twitter: {
      card: "summary_large_image",
      site: "@SimpliRFP",
      image:
        "https://www.simplirfp.com/static/media/logo.a6091cc24034d116e1a2.png",
      title: "Government Contracts Simplified",
      description:
        "Experience excellence through simplified government contracts...",
    },
    keywords: [
      "Simpli RFP",
      "Government Contracts Simplified",
      "Contracts Simplified",
    ],
    viewport: "width=device-width, initial-scale=1.0",
    canonical: "https://www.simplirfp.com/",
    description:
      "Experience excellence through simplified government contracts...",
    "X-UA-Compatible": "ie=edge",
  };

  const keywords = seoData.keywords.join(", ");

  return (
    <Helmet>
      <title>{seoData.title}</title>
      <meta name="author" content={seoData.author} />
      <meta name="charset" content={seoData.charset} />
      <meta name="keywords" content={keywords} />
      <meta name="viewport" content={seoData.viewport} />
      <meta name="description" content={seoData.description} />
      <meta httpEquiv="X-UA-Compatible" content={seoData["X-UA-Compatible"]} />
      <link rel="canonical" href={seoData.canonical} />

      <meta property="og:url" content={seoData.og.url} />
      <meta property="og:type" content={seoData.og.type} />
      <meta property="og:title" content={seoData.og.title} />
      <meta property="og:description" content={seoData.og.description} />
      <meta property="og:image" content={seoData.og.image} />

      <meta name="twitter:card" content={seoData.twitter.card} />
      <meta name="twitter:site" content={seoData.twitter.site} />
      <meta name="twitter:title" content={seoData.twitter.title} />
      <meta name="twitter:description" content={seoData.twitter.description} />
      <meta name="twitter:image" content={seoData.twitter.image} />
    </Helmet>
  );
};

export default SEO;
