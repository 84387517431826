import React, { useRef, useState } from "react";
import img2 from '../assets/image/infocards/summaryshot.png';
import img3 from '../assets/image/infocards/search.png';
import img4 from '../assets/image/infocards/download.png';
import img6 from '../assets/image/heartclick.gif';
import img7 from '../assets/image/navigationdash.png'
import img8 from '../assets/image/docreq.png'
import img9 from '../assets/image/eval.png'
import img10 from '../assets/image/sow.png'
import { toast } from "react-toastify";
import { PopupWidget } from "react-calendly";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import VideoPlayer from "../components/video/VideoPlayer";
import { Helmet } from "react-helmet";
import DynamicText from "../components/DynamicText";
import { Modal } from "react-bootstrap";
import '../style/shared/outer-card.scss';
import '../style/shared/card1.scss';
import '../style/shared/bbox.scss'

const Features = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [index, setIndex] = useState(0);
  const texts = ["Small Business", "Women-Owned Business", "Veteran-Owned Business", "Consultants", "IT Specialists", "Researchers", "General Contractors"];
  const [showDemo, setShowDemo] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleComplete = () => {
    setIndex((prevIndex) => (prevIndex + 1) % texts.length);
  };

  const handleSubscribeClick = async () => {
    try {
      if (email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(email)) {
          toast.error("Please enter a valid email address");
          return;
        }

        const url = `${process.env.REACT_APP_SERVER_URL}/email/sendemail`;
        await axios.post(url, { email });

        setEmail("");
        toast.success("Successfully Subscribed");
      } else {
        toast.warn("Please Enter Email to Subscribe");
      }
    } catch (error) {
      setEmail("");
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message, { toastId: "emailError" });
      } else {
        toast.error(error.message, { toastId: "emailError" });
      }
    }
  };

  const handleSvgClick = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  let offset = 0;
  const durationSeconds = 2.5;
  const increment = 1 / (durationSeconds * 60);

  function updateGradient() {
    offset = offset < 1 ? offset + increment : 0;
    document.querySelectorAll("linearGradient").forEach((el) => {
      el.querySelectorAll("stop").forEach((stopEl) => {
        stopEl.setAttribute("offset", offset);
      });
    });
    requestAnimationFrame(updateGradient);
  }

  updateGradient();

  const slides = [
    { id: 1, image: img2, title: 'Title 1', subtitle: 'Subtitle 1', description: 'Description 1' },
    { id: 2, image: img3, title: 'Title 2', subtitle: 'Subtitle 2', description: 'Description 2' },
    { id: 3, image: img4, title: 'Title 3', subtitle: 'Subtitle 3', description: 'Description 3' },
  ];

  return (
    <div>
      <Helmet>
        <meta property="og:title" content="Government Contracts Simplified" />
        <meta property="og:description" content="Experience excellence through simplified government contracts. We streamline your tasks, so you can focus on what truly matters - your business success" />
        <meta property="og:image" content="https://www.simplirfp.com/static/media/logo.a6091cc24034d116e1a2.png" />
        <meta property="og:url" content="https://www.simplirfp.com/" />
      </Helmet>
      <div className="features-sec" style={{ overflow: "hidden" }}>
        <div className="banner-wrapper">
          <div className="herobg">
            <ul>
              <li></li>
              <li></li> 
              <li></li> 
              <li></li> 
              <li></li> 
            </ul>
          </div>            
          <div className="features-banner">
            <div className="left">
              <h2 className="mb-4">
                The Only <span className="highlight-free">Free</span> AI GovCon Database
              </h2>
              <div className="features-text-container">
                <span className="static-text">Our AI Platform is Made For:</span>
                <br />
                <DynamicText 
                  text={texts[index]} 
                  typeSpeed={90} 
                  deleteSpeed={75} 
                  delay={850} 
                  onComplete={handleComplete}
                />
              </div>
              <button className="button-spacing" onClick={() => navigate("/pricing")}>
                Get Started for Free
              </button>
            </div>
          </div>
        </div>
        <div className="features-second-sec">
          <div className="w-100 w-sm-100">
            <div className="video mt-4 d-flex justify-content-center text-center">
              <div className="top">
                <div className="client-video position-relative">
                  <VideoPlayer
                    ref={videoRef}
                    width="1250"
                    height="700"
                    src="https://simplirfp.s3.us-west-2.amazonaws.com/simplirfpdemo.mp4"
                    playsInline
                  />
                  <svg
                    onClick={handleSvgClick}
                    className={`svgResponsive  position-absolute top-50 start-50 translate-middle ${isPlaying ? "hide-svg-updated" : ""}`}
                    viewBox="0 0 32 32"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 90 90" id="video-play-button">
                      <circle cx="45" cy="45" r="45" fill="#20c4cb"></circle>
                      <path className="padded-svg" fill="#1ba7ad" d="m61.749 50.066-33.4 25.057A2.094 2.094 0 0 1 25 73.448V23.335a2.094 2.094 0 0 1 3.35-1.675l33.4 25.057a2.092 2.092 0 0 1-.001 3.349Z"></path>
                      <path className="padded-svg" fill="#fff" d="M65.532 46.283 32.127 71.34a2.094 2.094 0 0 1-3.35-1.675V19.552a2.094 2.094 0 0 1 3.35-1.675l33.4 25.057a2.094 2.094 0 0 1 .005 3.349Z"></path>
                    </svg>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="content text-center">
            <div className="stats-section">
              <div className="container1">
                <div className="stats-description">
                  <h1>Transform Your Business</h1>
                  <h3>Don't Miss Out on Billions in Contracts Awarded Annually</h3>
                </div>
                <div className="stats-cards">
                  <div className="card-container">
                    <div className="square-card">                    
                      <h1>$172 <span className="text-purple">Billion</span></h1>
                      <h5>Contracts Awarded to Small Businesses </h5>
                    </div>                  
                  </div>
                  <div className="card-container">
                    <div className="square-card1">
                      <h1>$755 <span className="text-purple1">Billion</span></h1>
                      <h5>Government Contracts Awarded in FY23 </h5>
                    </div>
                  </div>  
                  <div className="card-container">
                    <div className="square-card2">
                      <h1>$42 <span className="text-purple">Billion</span></h1>
                      <h5>Awarded to Veteran-Owned Businesses </h5>
                    </div>
                  </div>     
                </div>
              </div> 
            </div>
            <div className="met-button">
              <button className="button-spacing1" onClick={() => navigate("/blog")}>
                Learn More
              </button>
            </div> 
          </div>
        </div>
        <div className="metrics-sec">
          <div className="outer-card">
            <div className="container2">            
                <h1>
                  Get Started For  <span className="text-gradient">FREE!</span>
                </h1>
                <h3>No Credit Card, No Trial Period. Unlimited Access for Free, Forever.</h3>            
            </div>
            <div className="container4">
              <p>Leveling the playing field for all businesses by providing the simplist and easiest way to find 
                government contracts. Even at no cost, our database gives you hours back.
              </p>
              <ul className="free-list">
                <li>Contact Name</li>
                <li>Contact Email</li>
                <li>Submittion Due Date</li>
                <li>Contract Term Length</li>
                <li>Bid Type</li>
                <li>Question Deadline</li>
                <li>NAICS Code</li>
                <li>Issueing Organization</li>
              </ul>
            </div>
            <div className="inner-card-container">
              <div className="left-card">
                <div className="content-block">
                  <div className="content-text">
                    <h4>Unlimited AI Overviews</h4>
                    <div className="content-rich-text w-richtext">
                      <p>Providing clear, concise overviews of all government contracts, 
                        highlighting key requirements and breaking down complex requirements.                         
                        Helping you stay informed and make efficient decisions with ease.</p>
                    </div> 
                  </div> 
                </div>                
              </div>
              <div className="right-card">
                <img src={img2} alt="Overview" />
              </div>          
            </div>
            <div className="inner-card-container2">
              <div className="right-card">
                <img src={img3} alt="Search" />
              </div>
              <div className="left-card">
                <div className="content-block">
                  <div className="content-text">
                    <h4>AI Enhanced Search</h4>
                    <div className="content-rich-text w-richtext">
                      <p>Our AI Enhanced Search feature offers precise
                        results, highlighting key requirements and breaking down complex criteria. 
                        Guiding you to quickly find relevant contracts and 
                        streamline your decision-making process.</p>
                    </div> 
                  </div> 
                </div>                
              </div>
            </div>
            <div className="inner-card-container">
              <div className="left-card">
                <div className="content-block">
                  <div className="content-text">
                    <h4>Unlimited Document Downloads</h4>
                    <div className="content-rich-text w-richtext">
                      <p>Access and download original government contract documents for free. 
                        Unrestricted access to essential contract information, 
                        you'll need all the details to begin your research, Simpli RFP brings you 
                        all the details you need without limitations.</p>
                    </div> 
                  </div> 
                </div>                
              </div>
              <div className="right-card">
                <img src={img4} alt="Download" />
              </div>
            </div>
            <div className="met-button">
              <button className="button-spacing1" onClick={() => navigate("/pricing")}>
                Sign Up for Free
              </button>
            </div>
          </div>
        </div>
        <div className="benefits-sec">
          <div className="outer-card">
          <div className="content-block-c">
            <div className="testimonial">
              "With Simpli RFP Premium, we now go through more contracts in an hour than we used to in a day, making it easier to focus on the right ones."
              <p className="author">-Alberto G, Project Manager</p>
            </div>  
          </div>  
            <div className="container3">
              <div className="stat-row">
                <div className="stat-block is-feature">
                  <div className="stat">1100+</div>
                  <div className="stat-label">
                    Contracts Summarized
                    Daily
                  </div>
                </div>
                <div className="stat-block is-feature">
                  <div className="stat">77%</div>
                  <div className="stat-label">
                    Time Savings with Summaries
                  </div>
                </div>
                <div className="stat-block is-feature">
                  <div className="stat">4X</div>
                  <div className="stat-label">
                    Faster Decision Making 
                  </div>
                </div>
                <div className="stat-block is-feature">
                  <div className="stat">99%</div>
                  <div className="stat-label">
                    User Satisfaction Rate
                  </div>
                </div>
              </div>
            </div>
            <div className="inner-card-container-b">
              <div className="left-card-b">
                <div className="content-block-b">
                  <div className="content-text-b">
                    <h2>Increase Efficiency by 400% with Premium</h2>                     
                  </div>
                </div>
              </div>
              <div className="right-card-b">
                <div className="content-block-b">
                  <div className="content-text-b">
                    <h4>It used to take hours, now it only takes minutes to find your next contract.</h4>
                    <br></br>
                    <h4>With Simpli RFP Premium you have access to all the information you need from a solicitation, all on one page. </h4>
                    <br></br>
                    <h4>From an easy to read overview and scope of work to the evaluation criteria, 
                      insurance needs even FARS details and more, at a glance.</h4>
                    <br></br>
                    <h4> Every single contract is summarized and displayed the minute they become available.
                      No download required, no more endless scrolling, no wait time. </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-body">
              <div className="container5">

                <div className="rowb rowb-1">

                  <div className="colb">
                    <div className="col-box">
                      <div className="list-box">
                        <ul className="free-list1">
                          <li>Scope of Work</li>
                          <li>Description of Service</li>
                          <li>Ship to Address</li>
                          <li>Quantity</li>
                          <li>Supplies/Services Required</li>
                          <li>Delivery Date</li>
                          <li>Documents Required for Submittion</li>
                          <li>Evaluation Criteria</li>
                          <li>Liability insurance Required</li>
                         
                          
                        </ul> 
                        
                      </div>
                      <div className="col-text"> At a Glance</div>                 
                    
                    </div>
                    <div className="col-box">
                      <div className="boxb-box">
                          <div className="boxb-cards">
                            <div className="header"> Scope - Janitorial Maintenance</div>
                            <p>Operations preventative maintenance and emergency and non-emergency 
                              repair services for the WWII Memorial including lighting, plumbing,
                                  mechanical, electrical, water feature and alarm systems.
                                  General masonry cleaning, litter removal and pest management.</p>
                                  
                          </div>
                      </div> 
                      <div className="col-text"> Scope of Work </div>                      
                    </div>                    
                  </div>

                  <div className="colb colb-2 ">
                    <div className="col-boxx">
                      <div className="content-text-b">
                        <h2>What's Included</h2>
                      </div>
                    </div>
                  </div>
                </div>  

                <div className="rowb rowb-2">
                  <div className="boxb">  
                    <div className="boxb-box">
                        <div className="boxb-cards">
                          <div className="header"> </div>
                          <p>Technical Merit (60%) - based on the quality of the Work Plan, Work Schedule, and Risk Assessment; Price (40%) - total estimated cost</p>
                      </div>
                    </div>                                        
                    <div className="col-text"> Evaluation Criteria</div>
                      
                  </div>

                  <div className="boxb">
                    <div className="boxa-box">
                      <img src={img6} alt="favorites-button"/>
                    </div>
                    <div className="col-text1">Set Favorites </div>
                  </div>

                  <div className="boxb">   
                    <div className="boxb-box">
                        <div className="boxb-cards">
                          <div className="header"> </div>
                          <p>SF-1449, SAM repersentations and certifications, insurance certificate, information on previous clients</p>
                      </div>
                    </div>                                   
                    <div className="col-text"> Documents Required </div>
                      
                  </div>
                </div>

                <div className="rowb rowb-3">
                  <div className="boxb">
                  <div className="rowb-box">
                    <div className="sum-cards">
                      <div className="sum-header">CRANE AND HOIST SYSTEMS INSTALLATION
                        <p>WW912NA24B0034</p>
                        <p>NAICS Code: 333923</p>
                      </div>
                      <p className="issue-date">
                        <span className="v-center gap-2">
                          <span className="issue">Issue Date</span>
                          <span className="due">Due Date</span>
                        </span>
                      </p>
                      <p className="v-center h-between">
                        <span>FL</span>  
                        <span className="v-center gap-2">
                          <span>03/06/2024</span>
                          <span>28/06/2024</span>
                        </span>
                      </p>  
                    </div>
                    <div className="sum-cards1">
                      <div className="sum-header">ENHANCED SECURITY MEASURES INSTALLATION
                        <p>W912DS24RFP001</p>
                        <p>NAICS Code: 561621</p>
                      </div>
                      <p className="issue-date">
                        <span className="v-center gap-2">
                          <span className="issue">Issue Date</span>
                          <span className="due">Due Date</span>
                        </span>
                      </p>
                      <p className="v-center h-between">
                        <span>TX</span>  
                        <span className="v-center gap-2">
                          <span>02/06/2024</span>
                          <span>02/07/2024</span>
                        </span>
                      </p>  
                    </div>
                    <div className="sum-cards2">
                      <div className="sum-header">SPILLWAY GATES CHAIN REPLACEMENT
                        <p>W912P524B0005</p>
                        <p>NAICS Code: 332312</p>
                      </div>
                      <p className="issue-date">
                        <span className="v-center gap-2">
                          <span className="issue">Issue Date</span>
                          <span className="due">Due Date</span>
                        </span>
                      </p>
                      <p className="v-center h-between">
                        <span>NY</span>  
                        <span className="v-center gap-2">
                          <span>03/06/2024</span>
                          <span>24/06/2024</span>
                        </span>
                      </p>  
                    </div>
                    <div className="sum-cards">
                      <div className="sum-header">FIRE ALARM DEVICE REPLACEMENT
                        <p>1305M224Q0144</p>
                        <p>NAICS Code: 561621</p>
                      </div>
                      <p className="issue-date">
                        <span className="v-center gap-2">
                          <span className="issue">Issue Date</span>
                          <span className="due">Due Date</span>
                        </span>
                      </p>
                      <p className="v-center h-between">
                        <span>CA</span>  
                        <span className="v-center gap-2">
                          <span>03/06/2024</span>
                          <span>18/06/2024</span>
                        </span>
                      </p>  
                    </div>
                    </div>                                            
                    <div className="col-text"> Navigation</div>   
                  </div>
                </div>
              </div>
            </div>  
              <div className="met-button">
                <button className="button-spacing5" onClick={() => navigate("/pricing")}>
                Upgrade Now
                </button>
              </div>   
          </div>      
        </div>
      </div>
    </div>
  );
};

export default Features;
