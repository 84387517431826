import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./changepassword.scss";
import axios from "axios";

import { toast } from "react-toastify";
import getItemFromLocalStorage from "../../utils/getItemFromLocalStorage";
function Changepassword() {
  const user = getItemFromLocalStorage("user");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  function checkValidation() {
    let error = false;
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{8,20}$/;
    if (
      newPassword === "" ||
      newPassword === null ||
      newPassword === undefined ||
      !passwordRegex.test(newPassword)
    ) {
      toast.error(
        "Invalid Password! Password should be 8-20 length characters and must contain 1 Uppercase letter, 1 number",
        { toastId: "invalidPassword" }
      );
      error = true;
    }
    if (newPassword !== confirmPassword) {
      error = true;
      toast.error("Passwords do not match", {
        toastId: "invalidConfirmPassword",
      });
    }
    return error;
  }
  const updatePasswordHandler = () => {
    if (isLoading) {
      toast.warning("Please Wait!", { toastId: "pleaseWait" });
    } else {
      if (checkValidation() == false && user) {
        console.log(user);
        setIsLoading(true);
        const headers = {
          Authorization: `${user.token}`,
          "Content-Type": "application/json",
        };
        axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/users/updatePassword/`,
            {
              userId: user.userID,
              currentPassword: oldPassword,
              newPassword: newPassword,
            },
            { headers: headers }
          )
          .then((res) => {
            console.log(res);
            setIsLoading(false);
            toast.success("Password Updated Successfully!", {
              toastId: "passwordSuccess",
            });
            navigate("/dashboard");
          })
          .catch((err) => {
            setIsLoading(false);
            toast.error(err.response.data.message, {
              toastId: "passwordError",
            });
            console.log(err);
          });
      }
    }
  };
  return (
    <section className="change-password">
      <div className="content">
        <h4> Change Password </h4>

        <div className="inner">
          <div>
            <label> Old Password</label>
            <input
              type="password"
              value={oldPassword}
              onChange={(e) => {
                setOldPassword(e.target.value);
              }}
            />
          </div>
          <div>
            <label> New Password</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
          </div>
          <div>
            <label> Confirm Password</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
          </div>
          <div className="buttons">
            <button
              className="cancel"
              onClick={(e) => {
                navigate("/dashboard");
              }}
            >
              Cancel
            </button>
            <button
              className="change"
              onClick={(e) => {
                updatePasswordHandler();
              }}
            >
              Change
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Changepassword;
