import React, { useEffect, useState } from "react";
import Dropdown from "../shared/dropdown";
import DropdownSimple from "../shared/dropdown-simple";
import axios from "axios";
import { Icon } from "@iconify/react";

const usa_states = [
  "Location", "All", "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", 
  "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", 
  "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", 
  "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", 
  "WY"
];

const setAside = [
  "Set Aside", "All", "SBA", "SBP", "8A", "8AN", "HZC", "HZS", "SDVOSBC", 
  "SDVOSBS", "WOSB", "WOSBSS", "EDWOSB"
];

const Filter = ({
  searchQuery,
  handleSearchBoxChange,
  handleSearch,
  resetAllFilters,
  onOrderByChange,
  onStatusChange,
  onLocationChange,
  onSetAsideChange,
  onNAICSCodeChange,
  onDepartmentChange,
}) => {
  const [selectedValues, setSelectedValues] = useState({
    naicsCode: [],
    departments: [],
  });

  const [orderByValue, setOrderByValue] = useState("");
  const [naicsCode, setNaicsCode] = useState([]);
  const [departments, setDepartments] = useState("");

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleOrderByChange = (value) => {
    setOrderByValue(value);
    onOrderByChange(value);
  };

  const handleStatusChange = (value) => {
    onStatusChange(value);
  };

  const handleLocationChange = (value) => {
    onLocationChange(value);
  };

  const handleSetAsideChange = (value) => {
    onSetAsideChange(value);
  };

  const handleDropdownChange = (key, value) => {
    if (!selectedValues[key].includes(value)) {
      switch (key) {
        case "naicsCode":
          onNAICSCodeChange([...selectedValues.naicsCode, value]);
          break;

        case "departments":
          onDepartmentChange([...selectedValues.departments, value]);
          break;

        default:
          break;
      }
      setSelectedValues((prev) => ({ ...prev, [key]: [...prev[key], value] }));
    }
  };

  const handleRemoveItem = (key, index) => {
    const updatedValues = { ...selectedValues };
    updatedValues[key].splice(index, 1);
    setSelectedValues(updatedValues);

    switch (key) {
      case "naicsCode":
        onNAICSCodeChange(updatedValues.naicsCode);
        break;

      case "departments":
        onDepartmentChange(updatedValues.departments);
        break;

      default:
        break;
    }
  };

  const camelCaseToWords = (camelCaseString) => {
    const words = camelCaseString.replace(/([a-z])([A-Z])/g, "$1 $2").split(" ");
    return words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
  };

 /* const getNAICSCode = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/filter/getNAICSCode`)
      .then((res) => {
        const codes = res.data.map((code) => code["NAICS Code"]);
        setNaicsCode(["NAICS Code", "All", ...codes]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDepartments = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/filter/getDepartments`)
      .then((res) => {
        setDepartments(["Departments", "All", ...res.data.map((title) => title["Department Name"])]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getNAICSCode();
    getDepartments();
  }, []);*/

  return (
    <div className="summary-filter">
      <div className="left-content">
        <div className="search-box v-center h-between">
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchBoxChange}
            onKeyPress={handleKeyPress}
            placeholder="Search Contracts..."
            className="search-input w-100 h-100"
          />
          <button onClick={handleSearch}>Search</button>
        </div>
      </div>

      <div className="right-content v-center flex-wrap w-100 filter-wrapper">
        {naicsCode.length > 0 && (
          <Dropdown
            options={naicsCode}
            onSelect={(value) => handleDropdownChange("naicsCode", value)}
          />
        )}

        <DropdownSimple
          options={usa_states}
          onSelect={handleLocationChange}
        />

        <DropdownSimple
          options={setAside}
          onSelect={handleSetAsideChange}
        />

        <DropdownSimple
          options={["Status", "All", "Open", "Close"]}
          onSelect={handleStatusChange}
        />

        <DropdownSimple
          className="order-dropdown"
          options={["Order by", "Issue Date (Asc)", "Issue Date (Des)"]}
          onSelect={handleOrderByChange}
        />
      </div>

      <button className="resetfilter" onClick={resetAllFilters}>Reset Filters</button>
    </div>
  );
};

export default Filter;
