import React from "react";
import "../../style/main.scss";
const Button = ({
  text,
  imageSrc,
  className,
  onClick,
  height,
  width,
  disabled,
  imageClassName,
  spinner,
  type = "button"
}) => {
  const buttonClassName = className || "";
  const buttonStyle = {
    height: height,
    width: width,
  };
  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };
  return (
    <button type={type}
      className={buttonClassName}
      style={buttonStyle}
      onClick={handleClick}
      disabled={disabled}
    >
      {imageSrc && (
        <img src={imageSrc} alt="Button Image" className={imageClassName} />
      )}
      {text}
      {spinner}
    </button>
  );
};

export default Button;
