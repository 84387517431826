import React from "react";
import NewsCard from "../shared/newsCard";
import thubmnai1 from "../../assets/image/cards/image1.png";
import thubmnai2 from "../../assets/image/home/news4.jpg";
import thubmnai3 from "../../assets/image/home/news3.jpg";
import thubmnai4 from "../../assets/image/cards/image00020.png";
import thubmnai6 from "../../assets/image/cards/image0011.png";
import thubmnai7 from "../../assets/image/cards/image0017.png";
import thubmnai8 from "../../assets/image/cards/image0010.png";
import thubmnai051 from "../../assets/image/cards/image3.png";
import thubmnai9 from "../../assets/image/cards/image007.png";
import thubmnai10 from "../../assets/image/cards/image4.png";
import thubmnai11 from "../../assets/image/cards/image0013.png";
import thubmnai12 from "../../assets/image/cards/image00025.png";
import thubmnai13 from "../../assets/image/cards/image00022.png";
import thubmnai103 from "../../assets/image/cards/image1.png";
import thubmnai050 from "../../assets/image/cards/Maximize Your Chances- How to Stand Out in Government Contract Bidding.png";
import thubmnai055 from "../../assets/image/cards/Predicting the Future Trends to Watch in 2024 for Government Contracting.png";
import thubmnai056 from "../../assets/image/cards/Decoding Government RFPs Understanding the Evaluation Criteria.png";
import thubmnai057 from "../../assets/image/cards/Building Strong Relationships with Government Agencies for Contracting Success.png";
import thubmnai058 from "../../assets/image/cards/The Advantages of Simplifying Government RFPs- Insights from Business Owners.png";
import thubmnai059 from "../../assets/image/cards/Streamlining Government Contract Management with Technology.png";

const Latestnews = (props) => {
  return (
    <div>
      <div className="latest-news">
        <div className="content-wrapper">
          <div className={`header ${props.hide}`}>
            <h3 className="text-center">
              Dive into Depths of Discovery with our Fresh
              <span className="text-pinkish-dark-red "> Insights</span>
            </h3>
            <p className="body-medium text-center">
              Stay updated with our latest news, where you'll discover fresh
              insights and valuable information.
            </p>
          </div>
          <div className="containers">
            <div className="news-grid ">
              <NewsCard
                id="1"
                addDate="February 20, 2024"
                category="Crafting a Winning Proposal"
                heading="Tips and strategies for government RFPs"
                path="/Tips-and-Strategies-for-Government-RFPs"
                img={thubmnai051}
                className="news-card"
              />
              <NewsCard
                id="2"
                addDate="February 21, 2024"
                category="Top 10 Mistakes In Small Businesses"
                heading="10 Mistakes In Small Businesses "
                path="/Mistakes-Small-Businesses-Make-When-Applying-for-Government-RFPs"
                img={thubmnai103}
                className="news-card"
              />

              <NewsCard
                id="3"
                addDate="March 01, 2024"
                category="Key Terms Explained"
                heading="Understanding the Language of Government RFPs"
                path="/Understanding-the-Language-of-Government-RFPs"
                img={thubmnai10}
                className="news-card"
              />
              <NewsCard
                id="4"
                addDate="March 02, 2024"
                category="How AI is Revolutionizing"
                heading="Process of Government Contract Bidding"
                path="/How-AI-is-Revolutionizing-the-Process-of-Government-Contract-Bidding"
                img={thubmnai9}
                className="news-card"
              />
              <NewsCard
                id="5"
                addDate="March 03, 2024"
                category=" Application to Award"
                heading="The Lifecycle of a Government RFP
              "
                path="/The-Lifecycle-of-a-Government-RFP"
                img={thubmnai8}
                className="news-card"
              />

              <NewsCard
                id="6"
                addDate="March 05, 2024"
                category=" Opportunities and Challenges"
                heading="Small Businesses in Government Contracting"
                path="/Role-of-Small-Businesses-in-Government-Contracting"
                img={thubmnai6}
                className="news-card"
              />
              <NewsCard
                id="7"
                addDate="March 06, 2024"
                category=" A Step-by-Step Guide"
                heading="Complexities of Government Contracts"
                path="/Navigating-the-Complexities-of-Government-Contracts"
                img={thubmnai11}
                className="news-card"
              />
              <NewsCard
                id="8"
                addDate="March 07, 2024"
                category="  What You Need to Know"
                heading="Government Contracting for Beginners"
                path="/Government-Contracting-for-Beginners"
                img={thubmnai7}
                className="news-card"
              />

              <NewsCard
                id="9"
                addDate="March 08, 2024"
                category="RFP Response Process
                "
                heading="7 Innovative Ways to Streamline"
                path="/7-Innovative-Ways-to-Streamline-Your-RFP-Response-Process"
                img={thubmnai4}
                className="news-card"
              />

              <NewsCard
                id="10"
                addDate="March 09, 2024"
                category="Navigating Success 
                "
                heading="Government Contract Regulations"
                path="/Navigating-Success-in-Government-Contract-Regulation"
                img={thubmnai13}
                className="news-card"
              />
              <NewsCard
                id="11"
                addDate="March 10, 2024"
                category="Small Business Growth 
                "
                heading="Government Contracts on Small Business"
                path="/Impact-of-Government-Contracts-on-Small-Business-Growth"
                img={thubmnai12}
                className="news-card"
              />
              <NewsCard
                id="12"
                addDate="March 11, 2024"
                category="Maximize Your Chances 
                "
                heading="Stand Out in Government Contract Bidding"
                path="/How-to-Stand-Out-in-Government-Contract-Bidding"
                img={thubmnai050}
                className="news-card"
              />
              <NewsCard
                id="13"
                addDate="March 12, 2024"
                category="Predicting the Future 
                "
                heading="Watch in 2024 for Government Contracting
                "
                path="/Trends-to-Watch-in-2024-for-Government-Contracting"
                img={thubmnai055}
                className="news-card"
              />

              <NewsCard
                id="14"
                addDate="March 13, 2024"
                category="Decoding Government RFPs 
                "
                heading="Government RFPs Evaluation Criteria
                "
                path="/Decoding-Government-RFPs-Evaluation-Criteria"
                img={thubmnai056}
                className="news-card"
              />

              <NewsCard
                id="15"
                addDate="March 15, 2024"
                category="Building Strong Relationships 
                "
                heading="Government Agencies for Contracting Success"
                path="/Building-Strong-Relationships-with-Government-Agencies-for-Contracting-Success"
                img={thubmnai057}
                className="news-card"
              />

              <NewsCard
                id="16"
                addDate="March 17, 2024"
                category="Advantages of Simplifying Government
                "
                heading="Insights from Business Owners"
                path="/Advantages-of-Simplifying-Government-RFPs"
                img={thubmnai058}
                className="news-card"
              />

              <NewsCard
                id="17"
                addDate="March 18, 2024"
                category="Government Contract Management
                "
                heading="Contract Management with Technology"
                path="/Streamlining-Government-Contract-Management-with-Technology"
                img={thubmnai059}
                className="news-card"
              />

              {/* <NewsCard
              id="2"
              category="Request a Demo"
              heading="The Power of Analytics in RFP Management"
              path="/blog-detail-2"
              img={thubmnai2}
            />
            <NewsCard
              id="3"
              category="Flexibility and Scalability"
              heading="The Digital Revolution in RFP Management"
              path="/blog-detail-3"
              img={thubmnai3}
            /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Latestnews;
