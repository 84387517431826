import React, { useContext, useEffect, useState } from "react";
import logo from "../../assets/image/home/logo3.png";
import accountVec from "../../assets/image/home/accountVec.png";
// import chatBtn from "../../assets/image/home/accountChat.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
// import googleicon from "../../assets/icons/googleicon.svg";
// import githubicon from "../../assets/icons/githubicon.svg";
import { SpinnerCircular } from "spinners-react";
import { STRIPE_SUBSCRIPTION_STATUS } from "../../constants/constant";
import { sleep } from "../../utils/utils";
import SetItemInLocalStorage from "../../utils/SetItemInLocalStorage";
// import getItemFromLocalStorage from "../../utils/getItemFromLocalStorage";
import PusherContext from "../../pusherContext";
import getItemFromLocalStorage from "../../utils/getItemFromLocalStorage";
import { color } from "@mui/system";

const Login = ({ userPropHandler }) => {
  const navigate = useNavigate();
  const { user, unSubscribeChannel, handleSetUser } = useContext(PusherContext);

  // useEffect(() => {
  if (user) {
    navigate("/dashboard");
  }
  // });

  /**
   *
   * Code To Check Updated Secure Localstorage Variable
   */
  const onStorageUpdate = (e) => {
    const { key, newValue } = e;
    if (key === "@secure.j.user") {
      window.location.reload();
    }
  };

  useEffect(() => {
    window.addEventListener("storage", onStorageUpdate);
    return () => {
      window.removeEventListener("storage", onStorageUpdate);
    };
  }, []);
  /**
   *
   *End of Code To Check Updated Secure Localstorage Variable
   */

  useEffect(() => {
    if (user) {
      const headers = {
        Authorization: `${user.token}`,
        "Content-Type": "application/json",
      };
      axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/users/protected`,
          { userId: user.userID },
          { headers: headers }
        )
        .then((res) => {
          // console.log(res);
          userPropHandler();
          if (res.data && res.data?.data?.subscription) {
            const userInLc = getItemFromLocalStorage("user");
            if (userInLc) {
              Object.assign(userInLc, {
                subscription: res.data?.data?.subscription,
              });
            }
            SetItemInLocalStorage("user", userInLc);
            handleSetUser();
          }
          navigate("/dashboard");
        })
        .catch((err) => {
          console.log(err);
          toast.error("Session Expired!", { toastId: "sessionExpired" });
          localStorage.removeItem("user");
        });
    }
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handlePassChange = ({ target }) => {
    setPassword(target.value);
  };

  const handleEmailChange = ({ target }) => {
    setEmail(target.value);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle the state to show/hide password
  };

  const handleCustomerPortalRedirection = async (user) => {
    if (isLoading || !user) return;
    try {
      const headers = {
        Authorization: `${user.token}`,
        "Content-Type": "application/json",
      };
      setIsLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/pay/payments/customer-portal-session/${user.userID}`,
        { headers: headers }
      );
      if (response?.data && response?.data?.portalSessionUrl) {
        toast("Subscription Ended!");
        sleep(3000).then();
        await logoutUser();
        window.open(response?.data?.portalSessionUrl, "_self");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("err", err);
    }
  };

  const logoutUser = async (user) => {
    unSubscribeChannel();
    if (user) {
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}/users/logout`, {
          userId: user.userID,
          token: user.token,
        })
        .then((res) => {
          localStorage.removeItem("user");
          localStorage.clear();
        })
        .catch((err) => {
          console.log(err);
          // if (err?.response?.data.message == "User not found") {
          //   localStorage.removeItem("user");
          //   navigate("/login");
          // }
        });
    } else {
      localStorage.removeItem("user");
      localStorage.clear();
      // toast.warning("Please Login First!", { toastId: "loginFirst" });
      // navigate("/login");
    }
  };

  const handleSubmit = async () => {
    if (user) {
      toast.warning("User already logged in!", { toastId: "alreadyLoggedIn" });
    } else {
      try {
        if (isLoading) {
          toast.warning("Please wait!", { toastId: "pleaseWait" });
        } else {
          if (password === "" || email.trim() === "") {
            toast.error("Please fill in all fields.");
          } else {
            setIsLoading(true);
            const url = `${process.env.REACT_APP_SERVER_URL}/users/login`;
            const res = await axios.post(url, {
              email: email.toLowerCase(),
              password: password,
            });
            if (res.data.isAdmin === true) {
            } else if (
              res.data.subscriptionStatus !==
                STRIPE_SUBSCRIPTION_STATUS.TRIALING &&
              res.data.subscriptionStatus !== STRIPE_SUBSCRIPTION_STATUS.ACTIVE
            ) {
              await handleCustomerPortalRedirection(res.data);
              return;
            }
            await SetItemInLocalStorage("user", res.data);
            setEmail("");
            setPassword("");
            toast.success("Login successfull!");
            userPropHandler();
            navigate("/dashboard");
            setIsLoading(false);
          }
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message, { toastId: "loginError" });
        } else {
          toast.error(error.message, { toastId: "loginError" });
        }
      }
    }
  };
  function checkValidation() {
    let error = false;
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (
      email.trim() === "" ||
      email.trim() === null ||
      email.trim() === undefined ||
      !emailRegex.test(email.trim())
    ) {
      error = true;
      toast.error("Invalid Email", { toastId: "invalidEmail" });
    }

    return error;
  }
  // const forgotPasswordHandler = () => {
  //   if (checkValidation() == false) {
  //     axios
  //       .post(`${process.env.REACT_APP_SERVER_URL}/users/forgotPassword`, {
  //         email: email,
  //       })
  //       .then((res) => {
  //         console.log(res.data);
  //         toast.success(
  //           `Reset password email has been sent to email: ${email}`,
  //           { toastId: "resetPasswordEmailSent" }
  //         );
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         toast.error("Error while sending reset email", {
  //           toastId: "resetPasswordEmailError",
  //         });
  //       });
  //   }
  // };

  return (
    <div className="registerParent">
      {isLoading && (
        <>
          <div className="loader">
            <SpinnerCircular
              color="#ffffff"
              size={"20%"}
              thickness={150}
              secondaryColor="#32C0BE"
            />
            <span className="customLoadingText">Loading...</span>
          </div>
        </>
      )}
      <div className="register-sec">
        <div className="reg-container">
          <div className="left">

            <img
              src={logo}
              alt="Simpli logo"
              className="img-fluid simpli-logo pointer"
              onClick={() => navigate("/")}
              style={{ width: '175px', height: 'auto' }}
            />
            <div className="content">
              <h4>

                Navigate Government Contracts with Ease.
              </h4>
            </div>
          </div>
        

          {/* <div className="right" style={{ paddingTop: "200px" }}> */}
          <div className="right">
            <div className="btnbtn-box">
              <Link to="/login" className="btn1 button-2" id="login">Sign In</Link>
              <Link to="/register?plan=freemium" className="btn1 button-1" id="register">Sign Up</Link>
            </div> 
            <h4 className="text-center1">
              Welcome Back!
            </h4>
            <div className="form-sec">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control1"
                  id="email"
                  name="email"
                  placeholder="Email"
                  onChange={handleEmailChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }}
                  required
                />
              </div>

              <div className="form-group2">
                  <input
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    type={showPassword ? "text" : "password"}
                    // type="password"
                    className="form-control1"
                    id="password"
                    name="password"
                    placeholder="Password"
                    onChange={handlePassChange}
                    onKeyDown={(e) => {
                      // console.log(e);
                      if (e.key === "Enter") {
                        handleSubmit();
                      }
                    }}
                    required
                  />
             
              </div>

              <div className="agree-sec">
                {/* <button onClick={forgotPasswordHandler} className="forget">
                  Forgot Password?
                </button> */}
                <button className="btn-gradient sign-up" onClick={handleSubmit}>
                  LOG IN
                </button>
              </div>
            </div>
            <div className="own-account">
              {/* <p>
                <input type="checkbox" className="me-1" />
                Remember me
              </p> */}
            </div>
            <div className="own-account">
              <p>
                <Link to="/forgot">Forgot Password?</Link>
              </p>
            </div>             

            {/* <div className="chat-option">
              <img src={chatBtn} alt="Chat Button" />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
