import React from "react";
import "../../style/shared/_newsCard.scss";
import user from "../../assets/icons/favicon.png";
import { Modal } from "react-bootstrap";
import Button from "./button";
import loader from "../../assets/gif/loader.gif";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const NewsCard = (props) => {
  const [show, setShow] = useState(false);
  const handleAISummarize = () => {
    setShow(!show);
  };
  let navigate = useNavigate();

  const handleClick = () => {
    // navigate(`/blog-detail/${props.id}`);
    navigate(props.path);
  };
  return (
    <div>
      <div className="s-newsCard">
        <div className="card-header">
          {/* <img src={card1} alt="" /> */}
          <img src={props.img} alt="" />
        </div>
        <div className="card-body">
          <p className="stack">{props.category}</p>
          <p className="blog-title">{props.heading}</p>
        </div>
        <div className="card-footer ">
          <div className="user v-center h-between">
            <div className="left v-center">
              <img src={user} alt="" className="me-2" />
              <p className="body-small">Simpli RFP</p>
            </div>
            <div className="right">
              <p className="body-small">{props.addDate}</p>
            </div>
          </div>
          <div className="text-center mt-4">
            <Button
              text="Read More"
              width="150px"
              height="40px"
              className="br-35 btn-prime text-white btn-gradient"
              // onClick={handleAISummarize}
              onClick={handleClick}
            />
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleAISummarize}
        centered
        className="simpli-loader"
      >
        <Modal.Body>
          <div className="coming-soon-loader">
            <img src={loader} alt="" className="img-fluid" />
            <p className="fw-bold text-center">Coming Soon </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NewsCard;
