import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { SpinnerCircular } from "spinners-react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import localeInfo from "rc-pagination/lib/locale/en_GB";
import Pagination from "rc-pagination";
import * as chrono from "chrono-node";
import moment from "moment";
import ContractBox from "./contractBox";
import SummaryReport from "./summaryReport";
import { Icon } from "@iconify/react";
import getItemFromLocalStorage from "../../utils/getItemFromLocalStorage";
import SetItemInLocalStorage from "../../utils/SetItemInLocalStorage";
import Filter from "../summarize/filter";
import PusherContext from "../../pusherContext";
import Modal from 'react-modal';

const Summary = ({ from, userPropHandler }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { unSubscribeChannel, handleSetUser } = useContext(PusherContext);
  const [allContractData, setAllContract] = useState([]);
  const [summaryData, setSummaryData] = useState();
  const [limit, setLimit] = useState(25);
  const [skip, setSkip] = useState(0);
  const [naicsCode, setNaicsCode] = useState("");
  const [naicstitle, setNaicstitle] = useState("");
  const [department, setDepartment] = useState("");
  const [agencyCode, setAgencyCode] = useState("");
  const [agencyName, setAgencyName] = useState("");
  const [status, setStatus] = useState("");
  const [location, setLocation] = useState("");
  const [setAside, setSetAside] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [searchBoxValue, setSearchBoxValue] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [allContractDataPagination, setAllContractDataPagination] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  const logoutUser = () => {
    const user = getItemFromLocalStorage("user");
    unSubscribeChannel();
    if (user) {
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}/users/logout`, {
          userId: user.userID,
          token: user.token,
        })
        .then((res) => {
          localStorage.removeItem("user");
          userPropHandler();
          navigate("/login");
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.data.message === "User not found") {
            localStorage.removeItem("user");
            navigate("/login");
          }
        });
    } else {
      toast.warning("Please Login First!", { toastId: "loginFirst" });
      navigate("/login");
    }
  };

  const checkPayments = () => {
    const user = getItemFromLocalStorage("user");
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/pay/payments/${user.userID}`)
      .then((res) => {
        if (res.data.userTrialExpired === false) {
          return;
        } else if (res.data?.subscriptionDetails.remainingDays <= 0) {
          toast.warning(
            () => (
              <div className="toast-div">
                <span>Your subscription has ended! Please subscribe again OR </span>
                <a href="#" onClick={logoutUser}>Logout</a>
              </div>
            ),
            {
              toastId: "subscriptionEnded",
              position: "top-right",
              autoClose: false,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          navigate("/pricing");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const user = getItemFromLocalStorage("user");
    if (user) {
      const headers = {
        Authorization: `${user.token}`,
        "Content-Type": "application/json",
      };
      axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/users/protected`,
          { userId: user.userID },
          { headers: headers }
        )
        .then((res) => {
          checkPayments();
          if (res.data?.data?.subscription) {
            const userInLc = getItemFromLocalStorage("user");
            if (userInLc) {
              Object.assign(userInLc, {
                subscription: res.data.data.subscription,
              });
            }
            SetItemInLocalStorage("user", userInLc);
            handleSetUser();
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Session Expired!", { toastId: "sessionExpired" });
          localStorage.clear();
          unSubscribeChannel();
          navigate("/login");
        });
    } else {
      toast.warning("Please Login First!", { toastId: "loginFirst" });
      localStorage.clear();
      unSubscribeChannel();
      navigate("/login");
    }
  }, []);

  const handleSearchBoxChange = (event) => {
    setSearchBoxValue(event.target.value);
  };

  const handleSearch = async () => {
    await searchContracts(searchBoxValue, 1);
  };

  const resetAllFilters = () => {
    setSearchBoxValue("");
    setNaicsCode("");
    setNaicstitle("");
    setDepartment("");
    setAgencyCode("");
    setAgencyName("");
    setStatus("");
    setLocation("");
    setSetAside("");
    setOrderBy("");
    getAllContracts();
  };

  const searchContracts = async (searchQuery, page = 1) => {
    const limit = countPerPage;
    const skip = (page - 1) * limit;

    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/filter/searchContracts?query=${searchQuery}&limit=${limit}&skip=${skip}`
      );
      setAllContract(response.data.summary);
      setTotalPages(response.data.total);
      setCurrentPage(page);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching search results:", error);
      setIsLoading(false);
    }
  };

  const updatePage = async (p) => {
    const to = countPerPage * p;
    const from = to - countPerPage;
    window.scrollTo(0, 0);

    if (searchBoxValue) {
      await searchContracts(searchBoxValue, p);
    } else {
      await getAllContracts({ skip: from });
    }

    setCurrentPage(p);
  };

  async function getAllContracts(data) {
    setIsLoading(true);
    const url = `${process.env.REACT_APP_SERVER_URL}/contracts/getall?limit=${limit}&skip=${data?.skip || skip}`;
    try {
      const res = await axios.get(url);
      if (res.data?.summary?.length > 0) {
        const sortedContracts = res.data.summary.sort((a, b) => {
          let dateA = a.summary?.["Date Issued"] ? moment(a.summary["Date Issued"], "YYYY-MM-DD") : null;
          let dateB = b.summary?.["Date Issued"] ? moment(b.summary["Date Issued"], "YYYY-MM-DD") : null;
          if (!dateA) return 1;
          if (!dateB) return -1;
          return dateB - dateA;
        });
        setAllContract(sortedContracts);
        setTotalPages(res.data.totalDocuments);
        setSummaryData(res.data.summary[0]);
      }
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      toast.error("Network Error!", { toastId: "networkError" });
      setIsLoading(false);
    }
  }

  const handleClick = (index, location) => {
    setActiveIndex(index);
    getSummary(index, location);
    setModalIsOpen(true); // Open the modal
  };

  async function getSummary(index, location = "") {
    setActiveIndex(index.toString());
    let getQuery = location === "USA" ? "sam" : "can";
    let url = `${process.env.REACT_APP_SERVER_URL}/contracts/get_${getQuery}/${index}`;
    axios
      .get(url)
      .then((res) => {
        setSummaryData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getAllContracts();
  }, []);

  const countPerPage = 24;

  function checkClosingDate(closingDate) {
    const date = extractAndParseDates(closingDate);
    const momentDate = moment(date, "DD/MM/YYYY");
    if (status === "Open") {
      return momentDate.isAfter();
    } else if (status === "Close") {
      return !momentDate.isAfter();
    }
    return false;
  }

  function extractAndParseDates(text) {
    const results = chrono.parse(text);
    const extractedDates = results
      .filter((result) => result.start)
      .map((result) => result.start.date());
    if (extractedDates.length > 0) {
      const dateMoment = moment(extractedDates[0]);
      if (dateMoment.isValid()) {
        return dateMoment;
      }
    }
    return null;
  }

  function sortIssueDate(a, b) {
    let dateA = a.summary?.["Date Issued"] ? extractAndParseDates(a.summary["Date Issued"]) : null;
    let dateB = b.summary?.["Date Issued"] ? extractAndParseDates(b.summary["Date Issued"]) : null;

    if (!moment(dateA).isValid()) dateA = null;
    if (!moment(dateB).isValid()) dateB = null;

    if (!dateA && !dateB) return 0;
    if (!dateA) return 1;
    if (!dateB) return -1;

    if (orderBy === "Issue Date (Asc)") {
      return moment(dateA).isBefore(dateB) ? -1 : moment(dateA).isAfter(dateB) ? 1 : 0;
    } else if (orderBy === "Issue Date (Des)") {
      return moment(dateA).isAfter(dateB) ? -1 : moment(dateA).isBefore(dateB) ? 1 : 0;
    }

    return 0;
  }

  const [filteredDataSet, setFilteredDataSet] = useState([]);

  function filterAllData(dataToBeFiltered) {
    const filteredData = dataToBeFiltered
      .filter((item) => {
        const closingDate =
          item.summary?.Location === "Canada"
            ? item.summary?.["Closing Date and Time"]
            : item.summary?.["offer due date"];
        const agencyNameValues = Object.values(item.summary).filter(
          (value) => typeof value === "string"
        );
        return (
          (!searchBoxValue ||
            Object.values(item.summary).some(
              (value) =>
                typeof value === "string" &&
                value.toLowerCase().includes(searchBoxValue.toLowerCase())
            )) &&
          (department === "All" ||
            department.length === 0 ||
            !department ||
            department.some((dept) =>
              Object.values(item.summary).some(
                (value) =>
                  typeof value === "string" &&
                  value.toLowerCase().includes(dept.toLowerCase())
              )
            )) &&
          (naicsCode === "All" ||
            naicsCode.length === 0 ||
            !naicsCode ||
            naicsCode.some((code) =>
              Object.values(item.summary).some(
                (value) =>
                  typeof value === "string" &&
                  value.toLowerCase().includes(code.toString().toLowerCase())
              )
            )) &&
          (agencyName === "All" ||
            agencyName.length === 0 ||
            !agencyName ||
            agencyName.some((filterValue) =>
              agencyNameValues.includes(filterValue.toLowerCase())
            )) &&
          (status === "All" ||
            !status ||
            (closingDate && checkClosingDate(closingDate))) &&
          (location === "All" ||
            !location ||
            (item.summary?.State &&
              item.summary?.State.toLowerCase().includes(
                location.toLowerCase()
              ))) &&
          (setAside === "All" ||
            !setAside ||
            (item.summary?.["typeOfSetAside"] &&
              item.summary?.["typeOfSetAside"]
                .toLowerCase()
                .includes(setAside.toLowerCase())))
        );
      })
      .sort((a, b) => sortIssueDate(a, b));

    setTotalPages(filteredData.length);
    if (filteredData.length > 0) {
      getSummary(filteredData[0]?._id, filteredData[0]?.summary?.Location);
    }
    setFilteredDataSet(filteredData);
    setAllContractDataPagination(filteredData.slice(0, countPerPage));
    setCurrentPage(1);
  }

  useEffect(() => {
    filterAllData(allContractData);
  }, [
    status,
    location,
    orderBy,
    department,
    naicsCode,
    agencyName,
    setAside,
  ]);

  useEffect(() => {
    if (allContractData) {
      setAllContractDataPagination(allContractData.slice(0, countPerPage));
    }
  }, [allContractData]);

  return (
    <div>
      {isLoading && (
        <div className="loader">
          <SpinnerCircular
            color="#ffffff"
            size={"20%"}
            thickness={150}
            secondaryColor="#32C0BE"
          />
          <span className="customLoadingText">Loading...</span>
        </div>
      )}

      <div className={`summary-asidebar`}>
        {allContractDataPagination && allContractDataPagination.length > 0 ? (
          <div>
            <Filter
              searchQuery={searchBoxValue}
              handleSearchBoxChange={handleSearchBoxChange}
              handleSearch={handleSearch}
              resetAllFilters={resetAllFilters}
              onNAICSCodeChange={setNaicsCode}
              onNAICSTitleChange={setNaicstitle}
              onDepartmentChange={setDepartment}
              onAgencyCodeChange={setAgencyCode}
              onAgencyNameChange={setAgencyName}
              onOrderByChange={setOrderBy}
              onStatusChange={setStatus}
              onLocationChange={setLocation}
              onSetAsideChange={setSetAside}
            />
            <label className="body-large text-white mb-3 w-100">All Contracts</label>
          </div>
        ) : (
          <></>
        )}
        {allContractDataPagination && allContractDataPagination.length > 0 ? (
          <div className="contract-grid">
            {allContractDataPagination.map((item, index) => (
              <div
                key={index}
                onClick={(e) => {
                  if (activeIndex !== item._id) {
                    handleClick(item._id, item.summary.Location);
                  }
                }}>
                <ContractBox
                  title={
                    item?.summary?.["RFP Title"] ? item?.summary?.["RFP Title"] : "NA"
                  }
                  RPF={
                    item?.summary?.["Bid Number"] ? item?.summary?.["Bid Number"] : "NA"
                  }
                  state={item?.summary?.State ? item?.summary?.State : ""}
                  country={item?.summary?.Location}
                  issueDate={item?.summary?.["Date Issued"]}
                  closingDate={
                    item.summary?.Location === "Canada"
                      ? item?.summary?.["Closing Date and Time"]
                      : item?.summary?.["offer due date"]
                  }
                  active={activeIndex === item?._id}
                  naicsCode={item?.summary?.NaicsCode}
                />
              </div>
            ))}
          </div>
        ) : (
          <div>
            <label className="body-large text-white mb-3 w-100">No Contracts</label>
            <button className="resetfilter" onClick={resetAllFilters}>Reset</button>
          </div>
        )}

        {allContractDataPagination && allContractDataPagination.length > 0 ? (
          <div className="">
            <Pagination
              locale={localeInfo}
              showLessItems={true}
              jumpNextIcon={() => <button>...</button>}
              jumpPrevIcon={() => <button>...</button>}
              pageSize={countPerPage}
              onChange={updatePage}
              current={currentPage}
              total={totalPages}
              className="pt-5"
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="custom-modal"
        overlayClassName="custom-modal-overlay"
        contentLabel="Contract Summary Modal"
      >
        <div className="modal-header">
          <button className="modal-close-button" onClick={() => setModalIsOpen(false)}>Close</button>
          <h2>Contract Details</h2>
        </div>

        {summaryData && (
          <SummaryReport
            summaryData={summaryData}
            onSearchBoxChange={handleSearchBoxChange}
            onOrderByChange={setOrderBy}
            onStatusChange={setStatus}
            onLocationChange={setLocation}
            onSetAsideChange={setSetAside}
            contractId={activeIndex}
            onNAICSCodeChange={setNaicsCode}
            onNAICSTitleChange={setNaicstitle}
            onDepartmentChange={setDepartment}
            onAgencyCodeChange={setAgencyCode}
            onAgencyNameChange={setAgencyName}
          />
        )}
      </Modal>
    </div>
  );
};

export default Summary;
