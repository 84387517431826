import axios from "axios";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../../assets/image/logo.png";
import accountVec from "../../assets/image/home/accountVec.png";

import "./survey.scss";
import SetItemInLocalStorage from "../../utils/SetItemInLocalStorage";

function Survey({ userPropHandler }) {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location);
  // Initialize state variables for the input values
  const [formData, setFormData] = useState({
    howDidYouHear: "",
    purposeOfVisit: "",
    usedOurWebsite: "",
  });

  const onSkipHandler = () => {
    if (location?.state?.userData) {
      SetItemInLocalStorage("user", location?.state?.userData);
    }
    userPropHandler();
    navigate("/dashboard");
  };

  const onConfirmHandler = () => {
    // console.log(location.state);

    const data = {
      howDidYouHear: formData.howDidYouHear,
      purposeOfVisit: formData.purposeOfVisit,
      usedOurWebsite: formData.usedOurWebsite,
    };
    if (
      !formData.howDidYouHear ||
      !formData.purposeOfVisit ||
      !formData.usedOurWebsite
    ) {
      toast.error("Please fill out all fields", { toastId: "fillOutFields" });
    } else {
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}/users/client/survey`, {
          surveyAnswer: data,
          userId: location.state.userSurveyId,
        })
        .then((res) => {
          if (location?.state?.userData) {
            SetItemInLocalStorage("user", location?.state?.userData);
          }
          toast.success("Thank you!", { toastId: "surveySuccess" });
          userPropHandler();
          navigate("/dashboard");
        })
        .catch((err) => {
          console.log(err);
          // toast.error(err, { toastId: "errorSurvey" })
        });
    }
  };
  // Event handler to update the state when the input fields change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  return (
    <section className="survey-sec">
      <div className="left">
        <img src={logo} alt="Simpli logo" className="img-fluid survey-logo " />
        <img src={accountVec} alt="account vector" className="account-vector" />
        <h4>
          Navigate Government Contracts <br /> with Ease.
        </h4>
      </div>
      <div className="inner">
        <div className="survey-card">
          <div className="top">
            <h2>We'd love your feedback!</h2>
          </div>
          <div className="bottom">
            <h3>How did you hear about us?</h3>
            <input
              type="text"
              name="howDidYouHear"
              value={formData.howDidYouHear}
              onChange={handleInputChange}
            />
            <h3>Have you used our website before?</h3>
            <input
              type="text"
              name="usedOurWebsite"
              value={formData.usedOurWebsite}
              onChange={handleInputChange}
            />
            <h3>What is the purpose of your visit?</h3>
            <textarea
              name="purposeOfVisit"
              value={formData.purposeOfVisit}
              onChange={handleInputChange}
            ></textarea>
            <div className="buttons">
              <button className="cancel-btn" onClick={onSkipHandler}>
                Skip
              </button>
              <button className="save-btn" onClick={onConfirmHandler}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Survey;
