import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from "react";
const LearningCenter = () => {
  const [activeTab, setActiveTab] = useState("Course 1");

  function SampleNextArrow(props) {
    const { className, style, onClick, image } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          backgroundImage: `url(${image})`,
          backgroundSize: "contain",
          height: 50,
          width: 50,
          backgroundRepeat: "no-repeat",
          right: -60,
          top: 100,
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick, image } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          backgroundImage: `url(${image})`,
          backgroundSize: "contain",
          height: 50,
          width: 50,
          backgroundRepeat: "no-repeat",
          marginRight: 30,
          left: -60,
          top: 100,
        }}
        onClick={onClick}
      />
    );
  }

  var settings = {
    infinite: false,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    dots: false,
    infinite: true,
    nextArrow: <SampleNextArrow image="../../assests/images/Next_Arrow.png" />,
    prevArrow: <SamplePrevArrow image="../../assests/images/Back_Arrow.png" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <div className="features-sec">
        <div className="banner-wrapper">
          <video
            className="features-videonormal"
            muted
            autoPlay
            playsInline
            loop
          >
            <source src="../../assests/videos/hero.mp4" type="video/mp4" />
          </video>
          <div className="features-banner">
            <div className="left">
              <h1 className="mb-4">Learning Center</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="whyUs-sec">
        <div className="learning_sec">
          <h1>Coming Soon</h1>

          <div className="tab_section">
            <h2>
              Start your journey today! Explore the realm of government
              contracts, all accessible at your fingertips.
            </h2>

            <div className="tab_btns justify-content-center">
              {courses.map((item) => (
                <button
                  key={item.id}
                  onClick={() => setActiveTab(item.tab)}
                  className={`${
                    activeTab === item.tab ? "active_tab" : "tab_btn"
                  }`}
                >
                  {item.tab}
                </button>
              ))}
            </div>

            <div className="tab_content">
              {courses.map((item) => (
                <div key={item.id}>
                  {activeTab === item.tab && (
                    <div className="slider_container">
                      <h3>{item.heading}</h3>
                      <div className="slider">
                        <Slider {...settings}>
                          {item.content.map((contentItem, index) => (
                            <div className="card_center" key={index}>
                              <div className="slider_card">
                                {contentItem.type === "img" ? (
                                  <img src={contentItem.src} alt="img" />
                                ) : contentItem.type === "video" ? (
                                  <video
                                    muted
                                    autoPlay
                                    playsInline
                                    loop
                                    controls
                                    className="course-video"
                                  >
                                    <source
                                      src={contentItem.src}
                                      type="video/mp4"
                                    />
                                  </video>
                                ) : null}
                                <p>{contentItem.label}</p>
                              </div>
                            </div>
                          ))}
                        </Slider>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const courses = [
  {
    id: 1,
    tab: "Course 1",
    heading: "Introduction to Government Contracting",
    content: [
      {
        type: "img",
        // src: "/assests/videos/hero.mp4",
        src: "../../assests/images/Slider/contract-gov.jpg",
        label: "Basics of Government Contracting",
      },
      {
        type: "img",
        src: "../../assests/images/Slider/contract-type.jpg",
        label: "Types of Government Contracts",
      },
      {
        type: "img",
        src: "../../assests/images/Slider/Slider_3.png",
        label: "Government Contracting Regulations",
      },
      {
        type: "img",
        src: "../../assests/images/Slider/contract-process.jpg",
        label: "Introduction to the Contracting Process",
      },
      {
        type: "img",
        src: "../../assests/images/Slider/contract-dev.jpg",
        label: "Beginning Proposal Development",
      },
    ],
  },
  {
    id: 2,
    tab: "Course 2",
    heading: "Intermediate Government Contracting",
    content: [
      {
        type: "img",
        src: "../../assests/images/Slider/Slider_8.jpg",
        label: "Advanced Proposal Development",
      },
      {
        type: "img",
        src: "../../assests/images/Slider/contract-admin.jpg",
        label: "Contract Formation and Administration",
      },
      {
        type: "img",
        src: "../../assests/images/Slider/law.jpg",
        label: "Compliance, Ethics, and Regulations",
      },
      {
        type: "img",
        src: "../../assests/images/Slider/Slider_11.jpg",
        label: "Pricing, Costs, and Analysis",
      },
      {
        type: "img",
        src: "../../assests/images/Slider/contracting.jpg",
        label: "Intermediate Topics in Subcontracting",
      },
    ],
  },
  {
    id: 3,
    tab: "Course 3",
    heading: "Advance Government Contracting",
    content: [
      {
        type: "img",
        src: "../../assests/images/Slider/Slider_12.jpg",
        label: "Advanced Contract Management",
      },
      {
        type: "img",
        src: "../../assests/images/Slider/Slider_13.jpg",
        label: "Dispute Resolution and Litigation",
      },
      {
        type: "img",
        src: "../../assests/images/Slider/topic.jpg",
        label: "Special Topics in Government Contracting",
      },
      {
        type: "img",
        src: "../../assests/images/Slider/Slider_15.jpg",
        label: "International Aspects and Advanced Compliance",
      },
      {
        type: "img",
        src: "../../assests/images/Slider/tech.jpg",
        label: "Emerging Trends and Advanced Technologies",
      },
      {
        type: "img",
        src: "../../assests/images/Slider/Slider_18.jpg",
        label: "Special Focus and Case Studies",
      },
    ],
  },
];

export default LearningCenter;
